// @flow
import React, { useState, useContext, useEffect } from 'react'
import { useStripe } from 'react-stripe-hooks'
import type { Element } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CardElement } from 'react-stripe-elements'
import type { SignupFormType } from '@Types/Signup'
import type { UserSignupStripeRequestType } from '@Types/User'
import useForm from 'react-hook-form'
import { noop, cardElementStyle } from '@Services/util'
import { PlanTypeContext } from '@Contexts/PlanType'
import { Context as UserSignupContext } from '@Contexts/UserSignup'
import MessageBox from '@Components/MessageBox'
import { get } from 'lodash'
import { isEmailValid } from '@Services/validations'

type Props = {
  onSubmit: Function,
}

const SignupForm = ({ onSubmit }: Props): Element<'form'> => {
  const { solutionType, planType } = useContext(PlanTypeContext)
  const { store: userSignupStore } = useContext(UserSignupContext)
  const stripe = useStripe()
  const [card, setCard] = useState(null)
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm()
  const [isInFocusOnStripe, setIsInFocusOnStripe] = useState(false)
  const [cardIsInvalid, setCardIsInvalid] = useState(false)
  const [showCardError, setShowCardError] = useState(false)
  const [shouldBeIndividual, setShouldBeIndividual] = useState()
  const [cardInvalidMessage, setCardInvalidMessage] = useState(
    t('components.SignupForm.cardError')
  )

  useEffect(() => {
    const isIndividual = planType === 'individual'
    setShouldBeIndividual(isIndividual)
  }, [setShouldBeIndividual, planType])

  function shouldShowMessageBox(): boolean {
    const { pending, error, errorMessage } = userSignupStore
    return !pending && error && errorMessage.length > 0
  }

  function onChangeCardComponent(changes: Object): void {
    const error = get(changes, 'error', undefined)
    const empty = get(changes, 'empty', false)
    if (error) {
      setCardIsInvalid(true)
      setShowCardError(true)
      setCardInvalidMessage(error.message)
    } else if (empty) {
      setCardIsInvalid(true)
      setShowCardError(true)
      setCardInvalidMessage(t('components.SignupForm.cardError'))
    } else {
      setCardIsInvalid(false)
      setShowCardError(false)
      setCardInvalidMessage('')
    }
  }

  function onReadyCardComponent(el: any): void {
    setCardIsInvalid(el._empty)
    setCard(el)
  }

  function handleStripePayments({
    name,
    clientSecret,
  }: UserSignupStripeRequestType) {
    return stripe
      .handleCardSetup(clientSecret, card, {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          throw {
            response: { data: { error: { message: result.error.message } } },
          }
        } else {
          return result.setupIntent.payment_method
        }
      })
  }

  function onFocusOnStripe(): void {
    setIsInFocusOnStripe(true)
  }

  function onBlurOnStripe(): void {
    setIsInFocusOnStripe(false)
  }

  function onFormSubmit1(userData: any): void {
    return onSubmit(userData, handleStripePayments)
  }

  const onSubmitForm1 = (formData: any): void => {
    if (cardIsInvalid) {
      setShowCardError(true)
      return
    }
    setShowCardError(false)
    const isTrueSet = get(formData, 'isBusiness') === 'true'
    const data: SignupFormType = {
      name: get(formData, 'fullName', ''),
      email: get(formData, 'email', ''),
      password: get(formData, 'password', ''),
      isBusiness: isTrueSet,
      planType,
    }

    onFormSubmit1(data)
  }

  return (
    <form
      key={1}
      onSubmit={handleSubmit(onSubmitForm1)}
      noValidate
      id="formStep1"
    >
      <div className="input-container">
        <div
          className={`input-container__item ${errors.fullName && 'has-error'}`}
        >
          <label>{t('components.SignupForm.fullNameLabel')}</label>
          <input
            type="text"
            name="fullName"
            placeholder={t('components.SignupForm.fullNamePlaceholder')}
            ref={register({
              required: t('components.SignupForm.fullNameEmpty'),
              maxLength: {
                value: 50,
                message: t('components.SignupForm.fullNameTooLong'),
              },
              minLength: {
                value: 3,
                message: t('components.SignupForm.fullNameTooShort'),
              },
            })}
          />
          {errors.fullName && <small>{errors.fullName.message}</small>}
        </div>
      </div>
      <div className="input-container">
        <div className={`input-container__item ${errors.email && 'has-error'}`}>
          <label>{t('components.SignupForm.emailLabel')}</label>
          <input
            type="email"
            placeholder={t('components.SignupForm.emailPlaceholder')}
            name="email"
            ref={register({
              required: t('components.SignupForm.emailEmpty'),
              validate: {
                value: (value) =>
                  isEmailValid(value) || t('components.SignupForm.emailError'),
              },
            })}
          />
          {errors.email && <small>{errors.email.message}</small>}
        </div>
      </div>

      <div className="input-container input-container--isBusiness">
        <div className={`input-container__item`}>
          <label>{t('components.SignupForm.isBusinessLabel')}</label>
          <div className="input-wrapper">
            <div className="input-wrapper__radio">
              <input
                id="private"
                type="radio"
                defaultChecked={shouldBeIndividual}
                value={false}
                name="isBusiness"
                ref={register('isBusiness')}
              />

              <label htmlFor="private">
                {t('components.SignupForm.isBusinessLabelPrivate')}
              </label>
            </div>
            <div className="input-wrapper__radio">
              <input
                id="business"
                type="radio"
                name="isBusiness"
                defaultChecked={!shouldBeIndividual}
                ref={register('isBusiness')}
                value={true}
              />

              <label htmlFor="business">
                {t('components.SignupForm.isBusinessLabelCompany')}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="input-container">
        <div
          className={`input-container__item ${errors.password && 'has-error'}`}
        >
          <label>{t('components.SignupForm.passwordLabel')}</label>
          <input
            type="password"
            autoComplete="new-password"
            placeholder={t('components.SignupForm.passwordPlaceholder')}
            name="password"
            ref={register({
              required: t('components.SignupForm.passwordEmpty'),
              minLength: {
                value: 8,
                message: t('components.SignupForm.passwordError'),
              },
            })}
          />
          {errors.password && <small>{errors.password.message}</small>}
        </div>
      </div>
      <div className={'input-container input-container--stripe'}>
        <div className="input-container__item">
          <label>{t('components.SignupForm.creditCard')}</label>
          <div className="u-is-desktop">
            <span>
              <i className="icon icon-lock" />
              {t('components.SignupForm.stripeLabel')}
            </span>
          </div>
          <div
            className={`input-wrapper ${isInFocusOnStripe ? 'is-focus' : ''}`}
          >
            <CardElement
              onFocus={onFocusOnStripe}
              onBlur={onBlurOnStripe}
              onReady={onReadyCardComponent}
              onChange={onChangeCardComponent}
              style={{
                base: cardElementStyle,
              }}
            />
          </div>
          {cardIsInvalid && showCardError && (
            <small>{cardInvalidMessage}</small>
          )}
          <div className="u-is-mobile u-margin-top-spacer-small">
            <span>
              <i className="icon icon-lock" />
              {t('components.SignupForm.stripeLabel')}
            </span>
          </div>
        </div>
      </div>
      <div className="input-container">
        <div
          className={`input-container__item input-container__item--terms ${
            errors.terms && 'has-error'
          }`}
        >
          <label className="checkbox">
            <input
              type="checkbox"
              name="terms"
              ref={register({
                required: t('components.SignupForm.termsError'),
              })}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  `components.SignupForm.termsAndCondition.${solutionType}`,
                  {
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            />
          </label>
          {errors.terms && <small>{errors.terms.message}</small>}
        </div>
      </div>

      <div className="input-container">
        <div className="input-container__item">
          <button
            className={`button button--primary is-purple ${
              userSignupStore.pending ? 'is-loading' : ''
            }`}
          >
            {userSignupStore.pending ? (
              <>
                <span />
                <p>{t(`components.SignupForm.buttonLabel.${solutionType}`)}</p>
              </>
            ) : (
              t(`components.SignupForm.buttonLabel.${solutionType}`)
            )}
          </button>
          {shouldShowMessageBox() && (
            <div className="u-margin-top-spacer-mid">
              <MessageBox>
                <p>{userSignupStore.errorMessage}</p>
              </MessageBox>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

SignupForm.defaultProps = {
  onSubmit: noop,
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
export default SignupForm
