import React, { useEffect, useContext } from 'react'
import Header from '@Components/Header'
import { Helmet } from 'react-helmet'
import SkeletonLoader from '@Components/SkeletonLoader'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { InvoiceContext } from '@Contexts/Invoice'
import { UserContext } from '@Contexts/User'
import ROUTER_PATH from '../routes_paths'
import { getUserIsLoggedIn } from '@Services/localstorage'
import { noop } from '@Services/util'
import MessageBox from '@Components/MessageBox'
import { useStripe } from 'react-stripe-hooks'
import * as moment from 'moment'

const InvoiceView = () => {
  const stripe = useStripe()
  const history = useHistory()
  const {
    store: { pending, invoice, error, errorMessage },
    paymentInvoiceStore,
    getInvoice,
    payInvoice,
  } = useContext(InvoiceContext)

  const {
    store: { user },
  } = useContext(UserContext)

  const { t } = useTranslation()
  const { id } = useParams()

  const onFetchingError = () => {
    history.push(ROUTER_PATH.dashboardBaseUrl)
  }

  useEffect(() => {
    if (getUserIsLoggedIn()) {
      getInvoice(id, noop, onFetchingError)
    }
  }, [])

  const onPayingInvoice = () => {
    payInvoice(id, handleStripeCardConfirmation)
  }

  const handleStripeCardConfirmation = () => {
    return stripe
      .confirmCardPayment(invoice.clientSecret, {
        payment_method: get(user, 'card.paymentMethodId', ''),
      })
      .then(result => {
        if (result.error) {
          throw { response: { data: { error: { message: result.error.message } } } }
        } else {
          return result.paymentIntent
        }
      })
  }

  const invoiceDetailPendingState = () => {
    return (
      <div className="invoice-details__item">
        <SkeletonLoader>
          <p>{t('globals.loadingState')}</p>
        </SkeletonLoader>
        <SkeletonLoader fullHeight>
          <strong>{t('globals.loadingState')}</strong>
        </SkeletonLoader>
      </div>
    )
  }

  const invoiceSummaryPendingState = () => {
    return (
      <SkeletonLoader type="is-yellow-50">
        <p>{t('globals.loadingState')}</p>
      </SkeletonLoader>
    )
  }

  return (
    <div className="layout">
      <Helmet>
        <title>
          {t('pages.InvoiceView.pageTitle') + ' ' + invoice.invoiceNumber}
        </title>
      </Helmet>
      <div className="layout__wrap">
        <div className="layout__item layout__item--header">
          <Header />
        </div>
        <div className="layout__item layout__item--full">
          <div className="layout-container">
            {error ||
              (paymentInvoiceStore.error && (
                <div className="u-margin-bottom-spacer-base">
                  <MessageBox>{errorMessage || paymentInvoiceStore.errorMessage}</MessageBox>
                </div>
              ))}
            <h3 className="u-margin-bottom-spacer-base">{t('pages.InvoiceView.title')}</h3>
            {pending ? (
              <SkeletonLoader>
                <p>{t('globals.loadingState')}</p>
              </SkeletonLoader>
            ) : (
              invoice.invoiceNumber && (
                <p>
                  {t('pages.InvoiceView.invoiceNumberLabel')} {invoice.invoiceNumber}
                </p>
              )
            )}
            <div className="invoice-details u-margin-top-spacer-large">
              <div className="invoice-details__wrap">
                {pending ? (
                  invoiceDetailPendingState()
                ) : (
                  <div className="invoice-details__item">
                    <p>{t('pages.InvoiceView.amountLabel')}</p>
                    <strong>
                      {invoice.currencySymbol}
                      {invoice.amount}
                    </strong>
                  </div>
                )}

                {pending ? (
                  invoiceDetailPendingState()
                ) : (
                  <div className="invoice-details__item">
                    <p>{t('pages.InvoiceView.dateLabel')}</p>
                    <strong>{invoice.date && moment.utc(invoice.date).format('MMMM, DD YYYY')}</strong>
                  </div>
                )}
                {pending ? (
                  invoiceDetailPendingState()
                ) : (
                  <div className="invoice-details__item">
                    <p>{t('pages.InvoiceView.billedAtLabel')}</p>
                    <strong>{invoice.recipientName}</strong>
                  </div>
                )}
              </div>
            </div>
            <div className="u-margin-top-spacer-large">
              {pending || paymentInvoiceStore.pending ? (
                <a className="button button--primary is-full is-purple is-loading">
                  <span/>
                  <p>{t('pages.InvoiceView.buttonPayInvoiceLabel')}</p>
                </a>
              ) : !invoice.isPaid ? (
                <a onClick={onPayingInvoice} className="button button--primary is-full is-purple">
                  <p>{t('pages.InvoiceView.buttonPayInvoiceLabel')}</p>
                </a>
              ) : (
                <span className="button button--primary is-full is-success" style={{ pointerEvents: 'none' }}>
                  <i className="icon icon-check1"/>
                  {t('pages.InvoiceView.buttonPaidInvoiceLabel')}
                </span>
              )}
            </div>
            <div className="u-margin-top-spacer-large">
              <p className="u-margin-bottom-spacer-base">{t('pages.InvoiceView.summaryLabel')}</p>
              <div className="invoice-summary">
                <div className="invoice-summary__wrap">
                  <div className="invoice-summary__item">
                    {pending ? (
                      invoiceSummaryPendingState()
                    ) : (
                      <div className="invoice-summary-row">
                        <p>{invoice.item}</p>
                        <p>
                          {invoice.currencySymbol}
                          {invoice.amountBeforeTax}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="invoice-summary__item">
                    {pending ? (
                      invoiceSummaryPendingState()
                    ) : (
                      <div className="invoice-summary-row">
                        <p>
                          {t('pages.InvoiceView.vatLabel')} ({invoice.vatRate}%)
                        </p>
                        <p>
                          {invoice.currencySymbol}
                          {invoice.amount - invoice.amountBeforeTax}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="invoice-summary__item">
                    {pending ? (
                      invoiceSummaryPendingState()
                    ) : (
                      <div className="invoice-summary-row">
                        <strong>{t('pages.InvoiceView.totalAmountLabel')}</strong>
                        <strong>
                          {invoice.currencySymbol}
                          {invoice.amount}
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceView
