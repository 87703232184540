// @flow
import createContext from './createContext'
import { invoicesReducer } from '@Reducers/Invoices'
import type { InvoicesType } from '@Types/Invoices'
import { getInvoicesByDates, getInvoicePdf } from '@Services/api'
import { INVOICES_FETCH, INVOICES_SUCCESS, INVOICES_FAIL, INVOICES_LIMIT_REACHED } from '@Actions/Invoices'
import i18n from 'i18next'
import { get } from 'lodash'
import { noop } from '@Services/util'
import * as moment from 'moment'

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  hasMore: boolean,
  alreadyFetched: boolean,
  invoices: Array<InvoicesType>,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  hasMore: true,
  alreadyFetched: false,
  errorMessage: '',
  invoices: [],
}

const onGetInvoices = dispatch => (date: Date, onComplete: Function = noop, onError: Function = noop) => {
  dispatch({ type: INVOICES_FETCH })
  const from = moment.utc(date).subtract(1, 'years').format('YYYY-MM-DD')
  const to = moment.utc(date).format('YYYY-MM-DD')
  getInvoicesByDates(from, to)
    .then(response => {
      if (response.length !== 0) {
        dispatch({ type: INVOICES_SUCCESS, invoices: response })
        onComplete()
      } else {
        dispatch({ type: INVOICES_LIMIT_REACHED })
      }
    })
    .catch(error => {
      const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
      dispatch({ type: INVOICES_FAIL, errorMessage })
      onError()
    })
}
const onGetInvoicePdf = dispatch => (id: string, onError: Function = noop) => {
  return getInvoicePdf(id)
    .then(response => {
      return response
    })
    .catch(error => {
      const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
      dispatch({ type: INVOICES_FAIL, errorMessage })
      onError()
    })
}

export const { Context, Provider } = createContext(
  invoicesReducer,
  {
    onGetInvoices,
    onGetInvoicePdf,
  },
  defaultStore
)
