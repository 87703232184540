import React from 'react'
import PropTypes from 'prop-types'

type chipType = 'full' | 'empty'

type Props = {
  children: string,
  type: chipType,
}
const Chip = ({ children, type }: Props) => {
  return (
    <div className={`chip chip--${type}`}>
      <div className="chip__wrap">
        <span>{children}</span>
      </div>
    </div>
  )
}

Chip.defaultProps = {
  children: '',
  type: 'empty',
}

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default Chip
