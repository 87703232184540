// @flow
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import { useTranslation } from 'react-i18next'

type Props = {
  labelText: string,
  onChange: Function,
  languages: Object,
  selectedLanguage: string,
  detectedLanguage: string,
  shouldShowDefaultValue: boolean
}

const TranslatorLanguageSelector = ({
  labelText,
  selectedLanguage,
  detectedLanguage,
  onChange,
  languages,
  shouldShowDefaultValue
}: Props) => {
  const { t } = useTranslation()

  const onLanguageChange = event => {
    onChange(event.target.value)
  }

  const [defaultLabel] = useState(t('components.TranslatorLanguageSelector.defaultLabel'))

  const detectionMap = {
    ...languages,
    zh: "Chinese",
    pt: "Portuguese",
    sr: "Serbian",
    es: "Spanish",
  }

  const foundDetectedLanguage = !selectedLanguage && detectedLanguage && detectionMap[detectedLanguage]
  const foundSelectedLanguage = selectedLanguage !== undefined && languages[selectedLanguage]

  return (
    <div className="translator-language-selector">
      <div className="translator-language-selector__wrap">
        <div className="translator-language-selector__item">
          <span className="translator-language-selector-label">{labelText} </span>
          <div className="translator-language-selector-select">
            <select onChange={onLanguageChange} value={selectedLanguage} tabIndex={0}>
              {shouldShowDefaultValue && (
                <option hidden value="">
                  {t('components.TranslatorLanguageSelector.defaultLabel')}
                </option>
              )}
              {Object.keys(languages).map(key => (
                <option key={key} value={key}>
                  {languages[key]}
                </option>
              ))}
            </select>
            <span>
              {foundDetectedLanguage && <p>{detectionMap[detectedLanguage] + " (auto)"}</p>}
              {foundSelectedLanguage && !foundDetectedLanguage && <p>{languages[selectedLanguage]}</p>}
              {!foundSelectedLanguage && !foundDetectedLanguage && <p>{defaultLabel}</p>}
              <i className="icon icon-chevron-down"/>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

TranslatorLanguageSelector.defaultProps = {
  labelText: '',
  onChange: noop,
  languages: {},
  selectedLanguage: '',
  shouldShowDefaultValue: false,
}

TranslatorLanguageSelector.propTypes = {
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.object.isRequired,
  shouldShowDefaultValue: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  detectedLanguage: PropTypes.string
}

export default TranslatorLanguageSelector
