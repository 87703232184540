import React from 'react'
import type { Element } from 'react'
import PropTypes from 'prop-types'
import SkeletonLoader from '@Components/SkeletonLoader'
type Props = {
  list: Array<string>,
  isBigger: boolean,
  pending?: boolean,
  skeletonColor?: string,
}

const ListWithThick = ({ list, isBigger, pending, skeletonColor }: Props): Element<'ul'> => (
  <ul className={`list-with-thick ${isBigger ? 'is-bigger' : ''}`}>
    {list.map((item, key) =>
      pending ? (
        <SkeletonLoader key={key} type={skeletonColor}>
          <li>{item}</li>
        </SkeletonLoader>
      ) : (
        <li key={key}>{item}</li>
      )
    )}
  </ul>
)

ListWithThick.defaultProps = {
  list: [],
  isBigger: false,
  pending: false,
  skeletonColor: '',
}

ListWithThick.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  isBigger: PropTypes.bool,
  pending: PropTypes.bool,
  skeletonColor: PropTypes.string,
}

export default ListWithThick
