// flow
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import { noop } from '@Services/util'
import useForm from 'react-hook-form'
type Props = {
  onClose: Function,
  onSubmit: Function,
  buttonLabel: string,
  title: string,
  placeholder: string,
  defaultValue: string,
  isLoading: boolean,
}

const TranslatorModalInput = ({
  onSubmit,
  onClose,
  placeholder,
  title,
  buttonLabel,
  isLoading,
  defaultValue,
}: Props): Element<'div'> => {
  const [value, setValue] = useState(defaultValue)
  const { register, errors, handleSubmit } = useForm()
  const onFormSubmit = (dataForm: any) => {
    onSubmit(dataForm.inputField)
  }
  const onFocus = event => event.target.select()
  const onChange = event => setValue(event.target.value)
  return (
    <div className="modal-wrap modal-wrap--center modal-wrap--input">
      <div className="modal-wrap__wrap">
        <div className="modal-wrap__item modal-wrap__item--header">
          <h5></h5>
          <i onClick={onClose} className="icon icon-close"></i>
        </div>
        <div className="modal-wrap__item modal-wrap__item--body">
          <h3>{title}</h3>
          <div className="input-container u-margin-top-spacer-large">
            <div className="input-container__item">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <input
                  type="text"
                  name="inputField"
                  onFocus={onFocus}
                  onChange={onChange}
                  autoFocus
                  defaultValue={value}
                  autoComplete="off"
                  placeholder={placeholder}
                  ref={register({
                    required: 'The field is required',
                    minLength: {
                      value: 1,
                    },
                  })}
                />

                <button
                  disabled={value.length === 0 || errors.inputField}
                  className={`button button--primary u-margin-top-spacer-base is-purple is-full ${
                    isLoading ? 'is-loading' : ''
                  }`}
                >
                  {isLoading ? (
                    <>
                      <span></span>
                      <p>{buttonLabel}</p>
                    </>
                  ) : (
                    buttonLabel
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TranslatorModalInput.defaultProps = {
  onClose: noop,
  onSubmit: noop,
  title: '',
  placeholder: '',
  buttonLabel: '',
  defaultValue: '',
  isLoading: false,
}

TranslatorModalInput.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default TranslatorModalInput
