// @flow
import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import Image from '@Components/Image'
import SkeletonLoader from '@Components/SkeletonLoader'

type ImageType = {
  imageUrl: string,
  imageRetinaUrl: string,
  alt: string,
  isLoading?: boolean,
}

const Avatar = ({ imageUrl, imageRetinaUrl, alt, isLoading }: ImageType): Element<'div'> => {
  return (
    <div className="avatar">
      <div className="avatar__wrap">
        {isLoading ? (
          <SkeletonLoader isRounded fullHeight type="is-yellow-50">
            <Image imageUrl={imageUrl} imageRetinaUrl={imageRetinaUrl} alt={alt} />
          </SkeletonLoader>
        ) : (
          <Image imageUrl={imageUrl} imageRetinaUrl={imageRetinaUrl} alt={alt} />
        )}
      </div>
    </div>
  )
}

Avatar.defaultProps = {
  imageUrl: '',
  imageRetinaUrl: '',
  alt: '',
  isLoading: false,
}

Avatar.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageRetinaUrl: PropTypes.string,
  alt: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default Avatar
