// @flow
import React, { useContext } from 'react'
import ROUTES_PATHS from '../routes_paths'
import { useTranslation } from 'react-i18next'
import LoginForm from '@Components/LoginForm'
const queryString = require('query-string')
import { useHistory } from 'react-router-dom'
import { UserLoginContext } from '@Contexts/UserLogin'
import { UserContext } from '@Contexts/User'
import MessageBox from '@Components/MessageBox'
import { get } from 'lodash'
const LoginFormView = () => {
  const { t } = useTranslation()
  const { onUserLogin, userNewPasswordStore } = useContext(UserLoginContext)
  const { onGetUser } = useContext(UserContext)
  const history = useHistory()
  const goToPageAfterLogin = () => {
    const qs = queryString.parse(location.search)
    const url = get(qs, 'url', '')
    if (url) {
      history.push(decodeURIComponent(url))
    } else {
      history.push(ROUTES_PATHS.dashboardBaseUrl)
    }
  }

  const onGetUserAfterLogin = (token: string): Promise<any> => {
    return onGetUser(token, goToPageAfterLogin)
  }

  const onSubmit = ({ email, password }): void => {
    onUserLogin(email, password, onGetUserAfterLogin)
  }

  return (
    <div className="login-form-view">
      <div className="login-form-view__wrap">
        <div className="login-form-view__item">
          <h2 className="u-margin-bottom-spacer-large">{t('pages.LoginFormView.title')}</h2>
        </div>
        {userNewPasswordStore.passwordHasBeenReset && (
          <div className="login-form-view__item u-margin-bottom-spacer-large">
            <MessageBox type="success">
              <p>{t('pages.LoginFormView.passwordHasBeenResetMessage')}</p>
            </MessageBox>
          </div>
        )}
        <div className="login-form-view__item">
          <LoginForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

export default LoginFormView
