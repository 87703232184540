import React, { useContext, useState, useEffect } from 'react'
import HeaderTranslator from '@Components/HeaderTranslator'
import { TranslatorMemoriesContext } from '@Contexts/TranslatorMemories'
import { Context as TranslatorTextContext } from '@Contexts/TranslatorText'
import { UserContext } from '@Contexts/User'
import TranslatorTextAreaContainer from '@Components/TranslatorTextAreaContainer'
import TranslatorLanguageSelectorContainer from '@Components/TranslatorLanguageSelectorContainer'
import TranslatorList from '@Components/TranslatorList'
import TranslatorListItem from '@Components/TranslatorListItem'
import MessageBox from '@Components/MessageBox'
import Modal from 'react-modal'
import TranslatorModalSignup from '@Components/TranslatorModalSignup'
import TranslatorModalInput from '@Components/TranslatorModalInput'
import TranslatorModalAction from '@Components/TranslatorModalAction'
import { routeToLoginWithEncodedUrl, addInputFile } from '@Services/util'
import ROUTES_PATHS from '../routes_paths'
import { getUserIsLoggedIn } from '@Services/localstorage'
import { range, filter, get } from 'lodash'
import { useTranslation } from 'react-i18next'
import TranslatorListUploader from '@Components/TranslatorListUploader'
import TranslatorListUploaderError from '@Components/TranslatorListUploaderError'
import TranslatorListUploaderSuccess from '@Components/TranslatorListUploaderSuccess'
import { Helmet } from 'react-helmet'
import { getTranslatorSource, getTranslatorTarget, setTranslatorSource, setTranslatorTarget } from '@Services/localstorage'

const TranslatorView = () => {
  const { t } = useTranslation()

  const localStoreSource = getTranslatorSource()
  const localStoreTarget = getTranslatorTarget()
  const initialLanguages = {
    source: localStoreSource != null ? localStoreSource : 'en',
    target: localStoreTarget != null ? localStoreTarget : 'it'
  }

  const [pendingMemories, setPendingMemories] = useState(true)
  const { store: translatorTextStore, getTranslation, cancelTranslationRequest } = useContext(TranslatorTextContext)
  const [selectedLanguages, setSelectedLanguages] = useState(initialLanguages)
  const [sourceText, setSourceText] = useState('')
  const [shouldShowSignUpModal, setShouldShowSignupModal] = useState(false)
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false)
  const [shouldShowCreateModal, setShouldShowCreateModal] = useState(false)
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false)
  const [currentMemoryId, setCurrentMemoryId] = useState(null)
  const [currentMemory, setCurrentMemory] = useState({})
  const [progressPercent, setProgressPercent] = useState(0)
  const [selectedMemoryForTmxFileUpload, setSelectedMemoryForTmxFileUpload] = useState({})
  const [tmxFilename, setTmxFilename] = useState('')
  const [shouldShowUploadError, setShouldShowUploadError] = useState(false)
  const [uploadIsFinished, setUploadIsFinished] = useState(false)
  const { store: userStore } = useContext(UserContext)
  const {
    store: translatorMemoriesStore,
    getMemories,
    createMemories,
    updateMemories,
    deleteMemories,
    addActiveMemories,
    uploadTmxFile,
    removeActiveMemories,
    onCancelUploadTmxFile,
  } = useContext(TranslatorMemoriesContext)

  const hideUploadError = () => {
    setShouldShowUploadError(false)
    resetSelectedMemoryForTmxFileUpload()
  }
  const resetSelectedMemoryForTmxFileUpload = () => {
    setSelectedMemoryForTmxFileUpload({})
    setUploadIsFinished(false)
  }

  const onUploadFinished = () => {
    setUploadIsFinished(true)
    setTimeout(resetSelectedMemoryForTmxFileUpload, 2000)
  }

  const onCloseUploadSuccess = () => {
    setUploadIsFinished(false)
    resetSelectedMemoryForTmxFileUpload()
  }

  const [userIsLoggedIn] = useState(getUserIsLoggedIn())

  const setMemoryById = (id, setCb): void => {
    const memory = filter(translatorMemoriesStore.memories, memory => memory.id === id)
    memory.length > 0 && setCb(memory[0])
  }
  const onLanguageChange = languages => {
    setSelectedLanguages(languages)
  }

  const onCloseSignupModal = () => {
    setShouldShowSignupModal(false)
  }

  const onCloseDeleteModal = () => {
    setShouldShowDeleteModal(false)
  }

  const onCloseEditModal = () => {
    setShouldShowEditModal(false)
    setCurrentMemoryId(null)
  }

  const onSwapLanguages = () => {
    setSelectedLanguages({ source: selectedLanguages.target, target: selectedLanguages.source })
    setSourceText(translatorTextStore.resultText)
  }

  // const onUploadDocument = () => {
  //   if (userIsLoggedIn) {
  //     console.log('user is logged in')
  //   } else {
  //     setShouldShowSignupModal(true)
  //   }
  // }

  const onOpenEditModal = (id: string) => () => {
    setShouldShowEditModal(true)
    setCurrentMemoryId(id)
  }
  const onOpenCreateModal = () => {
    setShouldShowCreateModal(true)
  }

  const onCloseCreateModal = () => {
    setShouldShowCreateModal(false)
  }

  const onOpenDeleteModal = (id: string) => () => {
    setShouldShowDeleteModal(true)
    setCurrentMemoryId(id)
  }

  const onUpdateMemories = (name: string): void => {
    updateMemories(currentMemoryId, name, onCloseEditModal, onCloseEditModal)
  }

  const onCreateMemories = (name: string): void => {
    createMemories(name, onCloseCreateModal, onCloseCreateModal)
  }

  const onDeleteMemories = (): void => {
    deleteMemories(currentMemoryId, onCloseDeleteModal, onCloseDeleteModal)
  }

  const onMemoryToggle = (status: boolean, id: string): void => {
    if (status) {
      addActiveMemories(id)
    } else {
      removeActiveMemories(id)
    }
  }

  const translatorLabelsByState = (): string => {
    if (translatorMemoriesStore.pendingUpload && progressPercent === 50) {
      return t('pages.TranslatorView.uploadingFilePhases.initProcessing')
    } else if (translatorMemoriesStore.pendingUpload && progressPercent < 50) {
      return t('pages.TranslatorView.uploadingFilePhases.uploading', {
        memoryName: selectedMemoryForTmxFileUpload.name || '',
        filename: tmxFilename || '',
      })
    } else {
      return t('pages.TranslatorView.uploadingFilePhases.processing')
    }
  }

  useEffect(() => {
    setShouldShowUploadError(translatorMemoriesStore.errorUpload)
  }, [translatorMemoriesStore.errorUpload])

  useEffect(() => {
    setProgressPercent(translatorMemoriesStore.progressUpload + translatorMemoriesStore.progressFileProcessing)
  }, [translatorMemoriesStore.progressUpload, translatorMemoriesStore.progressFileProcessing])

  useEffect(() => {
    setMemoryById(currentMemoryId, setCurrentMemory)
  }, [currentMemoryId])

  useEffect(() => {
    // remember selected languages
    setTranslatorSource(selectedLanguages.source)
    setTranslatorTarget(selectedLanguages.target)

    // trim source code and check if the content is
    // fullfilled before make the api call
    const trimmedSourceText = sourceText.trim()
    if (trimmedSourceText.length) {
      getTranslation(
        sourceText,
        selectedLanguages.source,
        selectedLanguages.target,
        translatorMemoriesStore.activeMemories
      )
    } else {
      // clean result immediatly 👀
      cancelTranslationRequest()
    }
  }, [sourceText, selectedLanguages])

  const onCompleteGetMemories = (): void => {
    setPendingMemories(false)
  }

  const onFileSelected = id => e => {
    setMemoryById(id, setSelectedMemoryForTmxFileUpload)
    e.preventDefault()
    e.stopPropagation()
    const formData = new FormData()
    const file = get(e, 'srcElement.files[0]', null)
    const filename = get(file, 'name', '')
    setTmxFilename(filename)
    formData.append('tmx', file)
    uploadTmxFile(id, formData, onUploadFinished)
  }
  const onOpenUploadFile = id => () => {
    addInputFile('.tmx', onFileSelected(id))
  }

  const onCancelUploadFile = () => {
    onCancelUploadTmxFile(resetSelectedMemoryForTmxFileUpload)
  }

  useEffect(() => {
    userIsLoggedIn && userStore.user.id && getMemories(onCompleteGetMemories)
  }, [userStore])

  const goToMyDashboard = function() {
    location.href = ROUTES_PATHS.dashboardBaseUrl
  }

  const goToLogin = function() {
    const encodedLoginUrl = routeToLoginWithEncodedUrl(ROUTES_PATHS.translatorBaseUrl)
    location.href = encodedLoginUrl
  }

  const shouldSwapLanguage = selectedLanguages.source.length > 0 && !translatorTextStore.error

  return (
    <>
      <Helmet>
        <title>{t('pages.TranslatorView.pageTitle')}</title>
      </Helmet>
      <div className="layout layout--translator">
        <div className="layout__wrap">
          <div className="layout__item layout__item--header">
            <HeaderTranslator goToLogin={goToLogin} goToMyDashboard={goToMyDashboard} userIsLoggedIn={userIsLoggedIn} />
          </div>
          <div className="layout__item layout__item--full">
            <div className="layout-container">
              {(translatorTextStore.error || translatorMemoriesStore.error) && (
                  <div className="u-margin-bottom-spacer-mid is-yellow-100">
                    <MessageBox size="small">
                      {translatorTextStore.errorMessage || translatorMemoriesStore.errorMessage}
                    </MessageBox>
                  </div>
                )}
              <TranslatorLanguageSelectorContainer
                onLanguageChange={onLanguageChange}
                selectedLanguages={selectedLanguages}
                onSwapLanguages={onSwapLanguages}
                shouldSwapLanguage={shouldSwapLanguage}
              />
              <TranslatorTextAreaContainer
                setSourceText={setSourceText}
                sourceText={sourceText}
                onSwapLanguages={onSwapLanguages}
                shouldSwapLanguage={shouldSwapLanguage}
                sourceLanguage={selectedLanguages.source}
                targetLanguage={selectedLanguages.target}
              />
              <div className="u-margin-top-spacer-large">
                {userIsLoggedIn ? (
                  <TranslatorList
                    title={t('pages.TranslatorView.memoriesList.title')}
                    buttonLabel={t('pages.TranslatorView.memoriesList.buttonLabel')}
                    buttonShouldBeSmall
                    onButtonPress={onOpenCreateModal}
                  >
                    {pendingMemories && translatorMemoriesStore.memories.length === 0
                      ? range(3).map(key => <TranslatorListItem key={key} isLoading />)
                      : translatorMemoriesStore.memories
                          .map((memory, key) =>
                            selectedMemoryForTmxFileUpload.id !== memory.id ? (
                              <TranslatorListItem
                                key={key}
                                id={memory.id}
                                shouldDisableActionBar={translatorMemoriesStore.pendingUpload}
                                title={memory.name}
                                onToggleChange={onMemoryToggle}
                                isActive={translatorMemoriesStore.activeMemories.includes(memory.id)}
                                actions={[
                                  {
                                    icon: 'add-folder',
                                    label: t('pages.TranslatorView.memoriesList.addTmxFileLabel'),
                                    onClick: onOpenUploadFile(memory.id),
                                  },
                                ]}
                                contextMenu={[
                                  {
                                    icon: 'edit',
                                    label: t('pages.TranslatorView.memoriesList.renameLabel'),
                                    onClick: onOpenEditModal(memory.id),
                                  },
                                  {
                                    icon: 'bin',
                                    label: t('pages.TranslatorView.memoriesList.deleteLabel'),
                                    onClick: onOpenDeleteModal(memory.id),
                                  },
                                ]}
                              />
                            ) : (
                              <React.Fragment key={key}>
                                {translatorMemoriesStore.pendingUpload && (
                                  <TranslatorListUploader
                                    label={translatorLabelsByState()}
                                    progressPercent={progressPercent}
                                    onCancel={onCancelUploadFile}
                                  />
                                )}
                                {shouldShowUploadError && (
                                  <TranslatorListUploaderError
                                    onClose={hideUploadError}
                                    errorMessage={translatorMemoriesStore.errorUploadMessage}
                                  />
                                )}
                                {uploadIsFinished && (
                                  <TranslatorListUploaderSuccess
                                    message={t('pages.TranslatorView.translatorListUploaderSuccess.message')}
                                    onClose={onCloseUploadSuccess}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )
                          .reverse()}
                  </TranslatorList>
                ) : (
                  <TranslatorList
                    title={t('pages.TranslatorView.translatorList.title')}
                    description={t('pages.TranslatorView.translatorList.description')}
                    buttonLabel={t('pages.TranslatorView.translatorList.buttonLabel')}
                    buttonType="primary"
                    onButtonPress={() => {
                      setShouldShowSignupModal(true)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <>
          <Modal isOpen={shouldShowSignUpModal} onRequestClose={onCloseSignupModal}>
            <TranslatorModalSignup onClose={onCloseSignupModal} />
          </Modal>
          <Modal isOpen={shouldShowEditModal} onRequestClose={onCloseEditModal}>
            <TranslatorModalInput
              title={t('pages.TranslatorView.translatorModalInputRename.title')}
              buttonLabel={t('pages.TranslatorView.translatorModalInputRename.buttonLabel')}
              onSubmit={onUpdateMemories}
              onClose={onCloseEditModal}
              defaultValue={currentMemory.name}
              isLoading={translatorMemoriesStore.pending}
            />
          </Modal>
          <Modal isOpen={shouldShowDeleteModal} onRequestClose={onCloseDeleteModal}>
            <TranslatorModalAction
              title={t('pages.TranslatorView.translatorModalInputDelete.title')}
              description={t('pages.TranslatorView.translatorModalInputDelete.description', {
                name: currentMemory.name || '',
              })}
              buttonLabel={t('pages.TranslatorView.translatorModalInputDelete.buttonLabel')}
              onButtonPress={onDeleteMemories}
              onClose={onCloseDeleteModal}
              isLoading={translatorMemoriesStore.pending}
            />
          </Modal>
          <Modal isOpen={shouldShowCreateModal} onRequestClose={onCloseCreateModal}>
            <TranslatorModalInput
              title={t('pages.TranslatorView.translatorModalInputAdd.title')}
              buttonLabel={t('pages.TranslatorView.translatorModalInputAdd.buttonLabel')}
              onSubmit={onCreateMemories}
              onClose={onCloseCreateModal}
              isLoading={translatorMemoriesStore.pending}
            />
          </Modal>
        </>
      </div>
    </>
  )
}

export default TranslatorView
