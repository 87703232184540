// @flow
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { noop } from '@Services/util'
import useForm, { FormContext } from 'react-hook-form'
import ROUTES_PATHS from '../routes_paths'
import { UserContext } from '@Contexts/User'
import { useHistory, useLocation } from 'react-router-dom'
import { PlanTypeContext } from '@Contexts/PlanType'
import CountrySelect from '@Components/CountrySelect'
import { get } from 'lodash'
import { BillingInformationContext } from '@Contexts/BillingInformation'
const queryString = require('query-string')
import MessageBox from '@Components/MessageBox'
import { isEmailValid } from '@Services/validations'
import type { Element } from 'react'

type Props = {
  onSubmit: Function,
}

const SignUpBillForm = ({ onSubmit }: Props): Element<'form'> => {
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const { signupData } = useContext(UserContext)
  const history = useHistory()
  const { solutionType } = useContext(PlanTypeContext)
  const { register, errors, handleSubmit, watch } = methods
  const location = useLocation()
  const [selectedCountryCode, setSelectedCountryCode] = useState(false)
  const [selectedCountryIsItaly, setSelectedCountryIsItaly] = useState(false)
  const [selectedCountryName, setSelectedCountryName] = useState(false)

  const [isCompany, setIsCompany] = useState()
  const [isPrivate, setIsPrivate] = useState()
  const { store: userContextStore } = useContext(UserContext)
  const { store } = useContext(BillingInformationContext)

  const taxCodeRgx = new RegExp(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/)

  // const vatRegex = new RegExp(/^(?!0+$)([A-Z]{2})?[0-9A-Z]{2,}$/)

  type onCountrySelectionType = {
    name: string,
    code: string,
  }
  const onCountrySelection = ({ name, code }: onCountrySelectionType): void => {
    setSelectedCountryCode(code)
    setSelectedCountryName(name)
    code === 'IT'
      ? setSelectedCountryIsItaly(true)
      : setSelectedCountryIsItaly(false)
  }

  const goToThankYouPage = (): void => {
    const query = queryString.parse(location.search)
    const plan = get(query, 'plan', '')
    plan
      ? history.push(`${ROUTES_PATHS.signupThankYou}?plan=${plan}`)
      : history.push(ROUTES_PATHS.signupThankYou)
  }

  function showCompany() {
    setIsCompany(true)
    setIsPrivate(false)
  }

  function showPrivate() {
    setIsCompany(false)
    setIsPrivate(true)
  }

  useEffect(() => {
    const isItaly = get(userContextStore, 'user.billingInformations.country') === 'IT'
    setSelectedCountryIsItaly(isItaly)
  }, [userContextStore])

  useEffect(() => {
    setIsCompany(signupData?.isBusiness)
    setIsPrivate(!signupData?.isBusiness)
  }, [signupData.isBusiness])

  const onFormSubmit = (formData: any) => {
    const isTrueSet = get(formData, 'isBusiness') === 'true'
    return onSubmit({ ...formData, isBusiness: isTrueSet })
  }

  const sdiOrPec = watch('sdiCode', '')
  // const [value, setValue] = useState()
  // const changeHandler = ({ target }) => {
  //   setValue(target.value.toUpperCase())
  // }

  function isValidTaxCode(taxCode: string): boolean {
    return taxCodeRgx.test(taxCode)
  }

  return (
    <>
      <div className='skip-adv'>
        {t(`components.SignupBillForm.skipAdv`)}{' '}
        <span className='skip-adv__text' onClick={goToThankYouPage}>
          {t(`components.SignupBillForm.skipNow`)}
        </span>
      </div>
      <div className='input-container input-container--isBusiness'>
        <div className={`input-container__item`}>
          <div className='input-wrapper'>
            <div className='input-wrapper__radio'>
              <input
                id='private'
                type='radio'
                defaultChecked={!signupData?.isBusiness}
                value={false}
                name='isBusiness'
                ref={register('isBusiness')}
                onClick={showPrivate}
              />

              <label htmlFor='private'>
                {t('components.SignupForm.isBusinessLabelPrivate')}
              </label>
            </div>
            <div className='input-wrapper__radio'>
              <input
                id='business'
                type='radio'
                defaultChecked={signupData?.isBusiness}
                name='isBusiness'
                ref={register('isBusiness')}
                value={true}
                onClick={showCompany}
              />

              <label htmlFor='business'>
                {t('components.SignupForm.isBusinessLabelCompany')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <FormContext {...methods}>
        <form key={2} onSubmit={handleSubmit(onFormSubmit)}>
          <div className='input-container'>
            <div
              className={`input-container__item input-container__item--dropdown ${
                errors.businessCountry && 'has-error'
              }`}
            >
              <label>{t('components.BillingInfoForm.countryLabel')}</label>
              <CountrySelect
                defaultValue={get(
                  userContextStore,
                  'user.billingInformations.country',
                  ''
                )}
                onChange={onCountrySelection}
              />
              {errors.businessCountry && (
                <small>{errors.businessCountry.message}</small>
              )}
            </div>

            {(
              <div
                className={`input-container__item ${
                  errors.businessName && 'has-error'
                }`}
              >
                <label>
                  {t('components.BillingInfoForm.nameLabel')}
                </label>
                <input
                  type='text'
                  name='businessName'
                  placeholder={t(
                    'components.BillingInfoForm.namePlaceholder'
                  )}
                  maxLength="50"
                  ref={register({
                    required: t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 50,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessName && (
                  <small>{errors.businessName.message}</small>
                )}
              </div>
            )}

            {(isCompany || (selectedCountryIsItaly && isPrivate)) && (
              <div
                className={`input-container__item ${
                  errors.businessAddress && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.addressLabel')}</label>
                <input
                  type='text'
                  name='businessAddress'
                  placeholder={t(
                    'components.BillingInfoForm.addressPlaceholder'
                  )}
                  maxLength="100"
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 100,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessAddress && (
                  <small>{errors.businessAddress.message}</small>
                )}
              </div>
            )}
          </div>
          {(isCompany || (selectedCountryIsItaly && isPrivate)) && (
            <div className='input-container input-container--multiple-fields'>
              <div
                className={`input-container__item ${
                  errors.businessCity && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.cityLabel')}</label>
                <input
                  type='text'
                  name='businessCity'
                  placeholder={t('components.BillingInfoForm.cityPlaceholder')}
                  maxLength="50"
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 50,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessCity && (
                  <small>{errors.businessCity.message}</small>
                )}
              </div>
              <div
                className={`input-container__item ${
                  errors.businessZipCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.zipCodeLabel')}</label>
                <input
                  type='text'
                  name='businessZipCode'
                  placeholder={t(
                    'components.BillingInfoForm.zipCodePlaceholder'
                  )}
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 30,
                      message: t(
                        'components.BillingInfoForm.zipCodeMessageError'
                      ),
                    },
                    minLength: {
                      value: 3,
                      message: t(
                        'components.BillingInfoForm.zipCodeMessageError'
                      ),
                    },
                  })}
                />
                {errors.businessZipCode && (
                  <small>{errors.businessZipCode.message}</small>
                )}
              </div>
            </div>
          )}
          <div className='input-container'>
            {isCompany && (
              <div
                className={`input-container__item ${
                  errors.vatId && 'has-error'
                }`}
              >
                <label>{`${t('components.BillingInfoForm.vatNumberLabel')} ${
                  !selectedCountryIsItaly
                    ? t('components.BillingInfoForm.ifRequired')
                    : ''
                }`}</label>
                <input
                  type='text'
                  name='vatId'
                  placeholder={t(
                    'components.BillingInfoForm.vatNumberPlaceholder'
                  )}
                  ref={register({
                    required:
                      isCompany && selectedCountryIsItaly
                        ? t('globals.errors.fieldRequired')
                        : false,

                    minLength: {
                      value: 5,
                      message: t(
                        'components.BillingInfoForm.vatNumberMessageError'
                      ),
                    },
                  })}
                />
                {errors.vatId && <small>{errors.vatId.message}</small>}
              </div>
            )}
          </div>
          {selectedCountryIsItaly && isCompany && (
            <div className='input-container'>
              <div
                className={`input-container__item ${
                  errors.sdiCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.sdiLabel')}</label>
                <input
                  type='text'
                  name='sdiCode'
                  placeholder={t('components.BillingInfoForm.sdiPlaceholder')}
                  ref={register({
                    maxLength: {
                      value: sdiOrPec.includes('@') ? 320 : 7,
                      message:
                        !sdiOrPec.includes('@') &&
                        t('components.BillingInfoForm.sdiMessageError'),
                    },
                    minLength: {
                      value: sdiOrPec.includes('@') ? 3 : 7,
                      message:
                        !sdiOrPec.includes('@') &&
                        t('components.BillingInfoForm.sdiMessageError'),
                    },
                    validate: {
                      value: (value) => {
                        if (sdiOrPec.includes('@')) {
                          return (
                            isEmailValid(sdiOrPec) ||
                            t('components.SignupForm.emailError')
                          )
                        }
                      },
                    },
                  })}
                />
                {errors.sdiCode && <small>{errors.sdiCode.message}</small>}
              </div>
            </div>
          )}
          {selectedCountryIsItaly && isPrivate && (
            <div className='input-container'>
              <div
                className={`input-container__item ${
                  errors.taxCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.taxCodeLabel')}</label>

                <input
                  type='text'
                  name='taxCode'
                  placeholder={t(
                    'components.BillingInfoForm.taxCodePlaceholder'
                  )}
                  // defaultValue={value}
                  // defaultValue={}
                  // onChange={changeHandler}
                  ref={register({
                    required:
                      isPrivate && selectedCountryIsItaly
                        ? t('globals.errors.fieldRequired')
                        : false,
                    validate: {
                      value: (value) => {
                        return (
                          isValidTaxCode(value.toUpperCase()) ||
                          t('components.BillingInfoForm.taxCodeMessageError')
                        )
                      },
                    },
                  })}
                />
                {errors.taxCode && <small>{errors.taxCode.message}</small>}
              </div>
            </div>
          )}
          <div className='input-container'>
            <div className='input-container__item'>
              <button
                className={`button button--primary is-purple u-margin-top-spacer-small`}
              >
                {t(`components.SignupBillForm.buttonLabel.${solutionType}`)}
              </button>
            </div>
          </div>
        </form>
        {store.error && (
          <div className='u-margin-top-spacer-base'>
            <MessageBox>{store.errorMessage}</MessageBox>
          </div>
        )}
      </FormContext>
    </>
  )
}

SignUpBillForm.defaultProps = {
  onSubmit: noop,
}

SignUpBillForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SignUpBillForm
