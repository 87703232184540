// @flow
import React, { createContext, useReducer, useContext } from 'react'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import { updateUserInformationsReducer } from '@Reducers/UserUpdate'
import { updateUserInformations } from '@Services/api'
import { noop } from '@Services/util'
import { get } from 'lodash'
import { UserContext } from '@Contexts/User'
import {
  UPDATE_USER_INFORMATIONS_FETCH,
  UPDATE_USER_INFORMATIONS_SUCCESS,
  UPDATE_USER_INFORMATIONS_FAIL,
} from '@Actions/UserUpdate'

type Context = {
  onUpdateUserInformations: Function,
  store: Store,
}

const UpdateUserInformationContext: Object = createContext<Context>({})

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  updatedUserInformations: any,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
  updatedUserInformations: {},
}
const UpdateUserInformationProvider = ({ children }: any) => {
  const [store, dispatch] = useReducer(updateUserInformationsReducer, defaultStore)
  const { onUpdateUserInformationsInUserContext } = useContext(UserContext)
  const onUpdateUserInformations = (
    updatedUserInformation: Object,
    onComplete: Function = noop,
    onError: Function = noop
  ) => {
    dispatch({ type: UPDATE_USER_INFORMATIONS_FETCH })
    updateUserInformations(updatedUserInformation)
      .then(response => {
        dispatch({ type: UPDATE_USER_INFORMATIONS_SUCCESS })
        onUpdateUserInformationsInUserContext(response)
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: UPDATE_USER_INFORMATIONS_FAIL, errorMessage })
        onError()
      })
  }
  return (
    <UpdateUserInformationContext.Provider value={{ store, onUpdateUserInformations }}>
      {children}
    </UpdateUserInformationContext.Provider>
  )
}

UpdateUserInformationProvider.defaultProps = {
  children: {},
}

UpdateUserInformationProvider.propTypes = {
  children: PropTypes.object,
}

export { UpdateUserInformationContext, UpdateUserInformationProvider }
