// flow
import React, { useState, useEffect } from 'react'
import type { Element } from 'react'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'

type Props = {
  onSwitchYear: Function,
}

const UsageMontlyChartYearSwitch = ({ onSwitchYear }: Props): Element<'div'> => {
  const currentYear = Number(moment.utc().format('YYYY'))
  const [shouldDisableNextButton, setShouldDisableNextButton] = useState(false)
  const [shouldDisablePrevButton, setShouldDisablePrevButton] = useState(false)
  const [year, setYear] = useState(currentYear)

  useEffect(() => {
    if (year >= currentYear) {
      setShouldDisableNextButton(true)
      setShouldDisablePrevButton(false)
    } else if (year <= currentYear - 5) {
      setShouldDisableNextButton(false)
      setShouldDisablePrevButton(true)
    } else {
      setShouldDisableNextButton(false)
      setShouldDisablePrevButton(false)
    }
  }, [year])

  useEffect(() => {
    onSwitchYear(year)
  }, [])

  const onClickNextYear = () => {
    const updatedYear = year + 1
    setYear(updatedYear)
    if (!shouldDisableNextButton) {
      onSwitchYear(updatedYear)
    }
  }

  const onClickPrevYear = () => {
    const updatedYear = year - 1
    setYear(updatedYear)
    if (!shouldDisablePrevButton) {
      onSwitchYear(updatedYear)
    }
  }

  return (
    <div className="montly-usage-chart-years-select">
      <i onClick={onClickPrevYear}
         className={`icon icon-chevron-down icon-chevron-down--left ${shouldDisablePrevButton ? 'is-disabled' : ''}`}
      />
      <p>{year}</p>
      <i onClick={onClickNextYear}
         className={`icon icon-chevron-down icon-chevron-down--right ${shouldDisableNextButton ? 'is-disabled' : ''}`}
      />
    </div>
  )
}

UsageMontlyChartYearSwitch.defaultProps = {
  onSwitchYear: noop,
}

UsageMontlyChartYearSwitch.propTypes = {
  onSwitchYear: PropTypes.func.isRequired,
}

export default UsageMontlyChartYearSwitch
