// @flow
import { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getUserIsLoggedIn,
  getUserTokenOnLocalstorage,
} from '@Services/localstorage'
import ROUTES_PATHS from '../routes_paths'
import { UserContext } from '@Contexts/User'
import { get, last } from 'lodash'
import { stripTrailingSlash } from '@Services/util'
import { noop } from '@Services/util'

export default function CheckIfUserIsLogged(): null {
  const { onGetUser, store: userContext } = useContext(UserContext)
  const userToken = getUserTokenOnLocalstorage()
  const userIsLoggedIn = getUserIsLoggedIn()
  const allowedRouteWithoutLogin = [
    ROUTES_PATHS.signupBaseUrl,
    ROUTES_PATHS.loginBaseUrl,
    ROUTES_PATHS.loginResetPasswordUrl,
    ROUTES_PATHS.loginNewPasswordUrl,
    ROUTES_PATHS.translatorBaseUrl,
  ]
  const { pathname } = useLocation()

  const onError = () => {
    window.location.href = ROUTES_PATHS.loginBaseUrl
  }

  const routeToLoginWithInvoiceParams = () => {
    const encodedUrl = encodeURIComponent(
      `${ROUTES_PATHS.invoiceBaseUrl}/${last(pathname.split('/'))}`
    )
    window.location.href = `${ROUTES_PATHS.loginBaseUrl}?url=${encodedUrl}`
  }

  useEffect(() => {
    // 🐷🍟 Junk code here
    if (!allowedRouteWithoutLogin.includes(stripTrailingSlash(pathname))) {
      if (userIsLoggedIn && userToken && !get(userContext, 'user.id', '')) {
        onGetUser(userToken, noop, onError)
      } else if (!userIsLoggedIn || !userToken) {
        if (pathname.includes('invoices')) {
          routeToLoginWithInvoiceParams()
        } else {
          window.location.href = ROUTES_PATHS.loginBaseUrl
        }
      }
    }

    // if the user is on the translate page
    // we make a call to get user details and set if the user is logged in or not
    // we do this because the route is public but we need to get if the user is logged in
    // to trigger the right modal on some actions (eg. updload document)
    if (stripTrailingSlash(pathname).includes('translate')) {
      if (userIsLoggedIn && userToken && !get(userContext, 'user.id', '')) {
        onGetUser(userToken)
      }
    }
  }, [userToken, pathname])

  return null
}
