// @flow
import React, { useReducer, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { get } from 'lodash'
import { noop } from '@Services/util'
import {
    LICENSE_PAUSE_REQUEST_FETCH,
    LICENSE_PAUSE_REQUEST_SUCCESS,
    LICENSE_PAUSE_REQUEST_FAIL,
} from '@Actions/LicensePause'
import { pauseLicense, unpauseLicense } from '@Services/api'
import { licensePauseReducer } from '@Reducers/LicensePause'
import { UserContext } from '@Contexts/User'

type Context = {
    setLicensePause: Function,
    store: Store,
}

type Store = {
    pending: boolean,
    error: boolean,
    errorMessage: string,
}

const defaultStore: Store = {
    pending: false,
    error: false,
    errorMessage: '',
}

const LicensePauseContext = createContext<Context>({})

const LicensePauseProvider = ({ children }: any): any => {
    const [store, dispatch] = useReducer(licensePauseReducer, defaultStore)
    const { onLicenseStatusChange } = useContext(UserContext)

    const setLicensePause = (pause: boolean, onComplete: Function = noop, onError: Function = noop): Promise<any> => {
        dispatch({ type: LICENSE_PAUSE_REQUEST_FETCH })
        return (pause ? pauseLicense() : unpauseLicense())
            .then(() => {
                dispatch({ type: LICENSE_PAUSE_REQUEST_SUCCESS })
                pause ? onLicenseStatusChange('PAUSED') : onLicenseStatusChange ('ACTIVE')
                onComplete()
            })
            .catch(error => {
                const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
                dispatch({ type: LICENSE_PAUSE_REQUEST_FAIL, errorMessage })
                onError()
            })
    }

    return (
        <LicensePauseContext.Provider
            value={{
                setLicensePause,
                store,
            }}
        >
            {children}
        </LicensePauseContext.Provider>
    )
}

LicensePauseProvider.defaultProps = {
    children: {},
}

LicensePauseProvider.propTypes = {
    children: PropTypes.object,
}

export { LicensePauseContext, LicensePauseProvider }
