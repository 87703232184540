import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
type Props = {
  label: string,
  progressPercent: number,
  onCancel: Function,
}

const TranslatorListUploader = ({ label, progressPercent, onCancel }: Props) => {
  return (
    <div className="translator-list-uploader-status">
      <div className="translator-list-uploader-status__wrap">
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--label">
          <p>{label}</p>
        </div>
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--progress">
          <div className="translator-list-uploader-progress">
            <span style={{ width: `${progressPercent}%` }}></span>
          </div>
          <div className="translator-list-uploader-cancel">
            <span onClick={onCancel}>
              <i className="icon icon-cancel"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

TranslatorListUploader.defaultProps = {
  label: '',
  progressPercent: 0,
  onCancel: noop,
}

TranslatorListUploader.propTypes = {
  label: PropTypes.string.isRequired,
  progressPercent: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
}

export default TranslatorListUploader
