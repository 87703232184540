//@flow
import {
  TRANSLATOR_TEXT_TRANSLATION_FETCH,
  TRANSLATOR_TEXT_TRANSLATION_SUCCESS,
  TRANSLATOR_TEXT_TRANSLATION_FAIL,
} from '@Actions/TranslatorText'

export const translatorTextReducer = (state: any, action: any) => {
  switch (action.type) {
    case TRANSLATOR_TEXT_TRANSLATION_FETCH:
      return { ...state, pending: true, error: false }
    case TRANSLATOR_TEXT_TRANSLATION_SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
        resultText: action.resultText,
        detectedLanguage: action.detectedLanguage
      }
    case TRANSLATOR_TEXT_TRANSLATION_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
