// flow
import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import { noop } from '@Services/util'
type Buttons = {
  label: string,
  type: string,
  isLoading?: boolean,
  callback: Function,
}

type Props = {
  children: any,
  type: string,
  firstButton: Buttons,
  secondButton: Buttons,
  isLoading: boolean,
}

const FlatCard = ({ children, type, firstButton, secondButton, isLoading }: Props): Element<'div'> => {
  const shouldShowFirstButton = Object.keys(firstButton).length > 0 && firstButton.label.length > 0
  const shouldShowSecondButton = Object.keys(secondButton).length > 0 && secondButton.label.length > 0
  const shouldShowContainerButton = shouldShowFirstButton || shouldShowSecondButton

  return (
    <div className={`flat-card ${type ? 'is-' + type : ''}`}>
      <div className="flat-card__wrap">
        <div className="flat-card__item">{children}</div>
        {shouldShowContainerButton && (
          <div className={`flat-card__item flat-card__item--cta ${isLoading ? 'is-hidden' : ''}`}>
            {shouldShowSecondButton && (
              <a
                onClick={secondButton.callback}
                className={`button is-purple button--${secondButton.type} ${
                  secondButton.isLoading ? 'is-loading' : ''
                }`}
              >
                {secondButton.isLoading ? <span></span> : secondButton.label}
              </a>
            )}
            {shouldShowFirstButton && (
              <a
                onClick={firstButton.callback}
                className={`button is-purple button--${firstButton.type} ${firstButton.isLoading ? 'is-loading' : ''}`}
              >
                {firstButton.isLoading ? (
                  <>
                    <span></span>
                    <p>{firstButton.label}</p>
                  </>
                ) : (
                  firstButton.label
                )}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

FlatCard.defaultProps = {
  firstButton: {
    label: '',
    type: '',
    isLoading: false,
    callback: noop,
  },
  isLoading: false,
  secondButton: {
    label: '',
    type: '',
    isLoading: false,
    callback: noop,
  },
  children: {},
  type: '',
}

FlatCard.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  firstButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    callback: PropTypes.func,
  }),
  secondButton: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    callback: PropTypes.func,
  }),
}

export default FlatCard
