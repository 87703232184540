import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
type Props = {
  message: string,
  onClose: Function,
}
const TranslatorListUploaderSuccess = ({ message, onClose }: Props) => {
  return (
    <div className="translator-list-uploader-status is-success">
      <div className="translator-list-uploader-status__wrap">
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--label">
          <p>{message}</p>
        </div>
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--progress">
          <div className="translator-list-uploader-cancel">
            <span onClick={onClose}>
              <i className="icon icon-cancel"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TranslatorListUploaderSuccess

TranslatorListUploaderSuccess.defaultProps = {
  message: '',
  onClose: noop,
}

TranslatorListUploaderSuccess.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
}
