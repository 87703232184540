// flow
import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import Illustration from '@Assets/images/sign-up-illustration.svg'
import { noop, routeToLoginWithEncodedUrl } from '@Services/util'
import { Link } from 'react-router-dom'
import ROUTER_PATHS from '../routes_paths'

import { useTranslation } from 'react-i18next'
type Props = {
  onClose: Function,
}

const onLoginButtonPress = () => {
  return routeToLoginWithEncodedUrl(ROUTER_PATHS.translatorBaseUrl)
}

const TranslatorModalSignup = ({ onClose }: Props): Element<'div'> => {
  const { t } = useTranslation()
  return (
    <div className="modal-wrap modal-wrap--center modal-wrap--signup">
      <div className="modal-wrap__wrap">
        <div className="modal-wrap__item modal-wrap__item--header">
          <h5></h5>
          <i onClick={onClose} className="icon icon-close"></i>
        </div>
        <div className="modal-wrap__item modal-wrap__item--body">
          <img src={Illustration} alt="signup-illustration" />
          <h3>{t('components.TranslatorModalSignup.title')}</h3>
          <p>{t('components.TranslatorModalSignup.subTitle')}</p>
          <a
            href={t('components.TranslatorModalSignup.plansRedirectionUrl')}
            className="button button--primary is-purple"
          >
            {t('components.TranslatorModalSignup.buttonBigLabel')}
          </a>
          <p>
            {t('components.TranslatorModalSignup.footerText')}{' '}
            <Link to={onLoginButtonPress()} className="is-purple">
              {t('components.TranslatorModalSignup.footerButtonLabel')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

TranslatorModalSignup.defaultProps = {
  onClose: noop,
}

TranslatorModalSignup.propTypes = {
  onClose: PropTypes.func,
}

export default TranslatorModalSignup
