// @flow
import React, { useReducer, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { updateAccessTokenReducer } from '@Reducers/UpdateAccessToken'
import { noop } from '@Services/util'
import { UserContext } from '@Contexts/User'
import { getUserTokenOnLocalstorage } from '@Services/localstorage'
import {
  UPDATE_ACCESS_TOKEN_REQUEST_FETCH,
  UPDATE_ACCESS_TOKEN_REQUEST_SUCCESS,
  UPDATE_ACCESS_TOKEN_REQUEST_FAIL,
} from '@Actions/UpdateAccessToken'
import { updateAccessToken, updateUserToken } from '@Services/api'
import { setUserTokenOnLocalstorage } from '@Services/localstorage'
import { get } from 'lodash'

type Context = {
  updateUserAccessToken: Function,
  store: Store,
}

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
}

const UpdateAccessTokenContext = createContext<Context>({})

const UpdateAccessTokenProvider = ({ children }: any): any => {
  const [store, dispatch] = useReducer(updateAccessTokenReducer, defaultStore)
  const { onUpdateActivationKeyContext } = useContext(UserContext)

  const updateUserAccessToken = (onComplete: Function = noop, onError: Function = noop): Promise<any> => {
    dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST_FETCH })
    const userToken = getUserTokenOnLocalstorage()
    return updateAccessToken(userToken)
      .then(({ activationKey, token }) => {
        updateUserToken(token)
        dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST_SUCCESS })
        onUpdateActivationKeyContext(activationKey)
        setUserTokenOnLocalstorage(token)
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

  return (
    <UpdateAccessTokenContext.Provider
      value={{
        updateUserAccessToken,
        store,
      }}
    >
      {children}
    </UpdateAccessTokenContext.Provider>
  )
}

UpdateAccessTokenProvider.defaultProps = {
  children: {},
}

UpdateAccessTokenProvider.propTypes = {
  children: PropTypes.object,
}

export { UpdateAccessTokenContext, UpdateAccessTokenProvider }
