// flow
import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from '@Components/Header'
import SignupSidebar from '@Components/SignupSidebar'
import ROUTES_PATHS from '../routes_paths'
import SignupFormView from '@Views/SignupFormView'
import SignUpBillView from '@Views/SignupBillView'
import SignUpThankYouView from '@Views/SignUpThankYouView'
import { Provider as UserSignupProvider } from '@Contexts/UserSignup'
import { BillingInformationProvider } from '@Contexts/BillingInformation'
import {
  HeaderSignupNavigationMobile,
  HeaderSignupNavigationDesktop,
} from '@Components/HeaderSignupNavigation'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const SignupView = () => {
  const { t } = useTranslation()
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false)
  const onShouldShowOverlay = () => {
    setShouldShowOverlay(!shouldShowOverlay)
  }
  return (
    <>
      <Helmet>
        <title>{t('pages.SignupView.pageTitle')}</title>
      </Helmet>
      <UserSignupProvider>
        <div className='layout signup'>
          <div className='layout__wrap signup__wrap'>
            <div className='signup__item layout__item signup__item--header layout__item--header'>
              <Header
                MobileNavigation={() => (
                  <HeaderSignupNavigationMobile
                    onShouldShowOverlay={onShouldShowOverlay}
                  />
                )}
                DesktopNavigation={HeaderSignupNavigationDesktop}
                Overlay={SignupSidebar}
                shouldShowOverlay={shouldShowOverlay}
              />
            </div>
            <div className='layout__item signup__item'>
              <div className='layout-container'>
                <Switch>
                  <Route
                    exact
                    path={ROUTES_PATHS.signupBaseUrl}
                    component={SignupFormView}
                  />
                  <BillingInformationProvider>
                    <Route
                      exact
                      path={ROUTES_PATHS.signupBill}
                      component={SignUpBillView}
                    />
                    <Route
                      exact
                      path={ROUTES_PATHS.signupThankYou}
                      component={SignUpThankYouView}
                    />
                  </BillingInformationProvider>
                </Switch>
              </div>
            </div>
            <div className='layout__item signup__item layout__item--sidebar signup__item--sidebar has-background'>
              <SignupSidebar />
            </div>
          </div>
        </div>
      </UserSignupProvider>
    </>
  )
}

export default SignupView
