// flow
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import SkeletonLoader from '@Components/SkeletonLoader'
import * as moment from 'moment'
import ReactTooltip from 'react-tooltip'

type Props = {
  charsCount: number,
  amount: number,
  percent: number,
  currency: string,
  isLoading: boolean,
}

const UsageMonthlyCurrent = ({ charsCount, amount, percent, currency, isLoading }: Props): Function => {
  const [isPercentPositive, setIsPercentPositive] = useState(false)

  const checkIfPercentIsPositive = (percent: number): void => {
    setIsPercentPositive(percent >= 0)
  }

  useEffect(() => {
    percent && checkIfPercentIsPositive(percent)
  }, [percent])

  const { t } = useTranslation()

  return (
    <>
      {!isLoading && <ReactTooltip effect="solid" className="tooltip" />}
      <div className="usage-monthly-current">
        <div className="usage-monthly-current__wrap">
          <div className="usage-monthly-current__item usage-monthly-current__item--chars">
            {isLoading ? (
              <SkeletonLoader>
                <p>0</p>
              </SkeletonLoader>
            ) : (
              <p>{charsCount.toLocaleString()}</p>
            )}

            {isLoading ? (
              <SkeletonLoader fullHeight>
                <span>{t('components.UsageMonthlyCurrent.charactersTranslated')}</span>
              </SkeletonLoader>
            ) : (
              <span>{t('components.UsageMonthlyCurrent.charactersTranslated')}</span>
            )}
          </div>
          <div className="usage-monthly-current__item usage-monthly-current__item--amount">
            {isLoading ? (
              <SkeletonLoader>
                <p>$0</p>
              </SkeletonLoader>
            ) : (
              <p>
                {currency}
                {amount}
              </p>
            )}

            {isLoading ? (
              <SkeletonLoader fullHeight>
                <span>{t('components.UsageMonthlyCurrent.amount')}</span>
                <i className="icon icon-help"/>
              </SkeletonLoader>
            ) : (
              <span>
                {t('components.UsageMonthlyCurrent.amount')}{' '}
                {/* <i className="icon icon-help" data-tip={t('components.UsageMonthlyCurrent.tooltip')}></i> */}
              </span>
            )}
          </div>
          <div
            className={`usage-monthly-current__item usage-monthly-current__item--gain ${
              isPercentPositive ? 'is-positive' : 'is-negative'
            }`}
          >
            {isLoading ? (
              <SkeletonLoader>
                <p>0</p>
              </SkeletonLoader>
            ) : (
              <p>
                {`${isPercentPositive ? '+' : ''} ${Math.round(percent)}%`}
                <i className={`icon icon-arrow-${isPercentPositive ? 'up' : 'down'}`}/>
              </p>
            )}
            {isLoading ? (
              <SkeletonLoader fullHeight>
                <span>{t('components.UsageMonthlyCurrent.previousPeriod')}</span>
              </SkeletonLoader>
            ) : (
              <>
                <span className="">
                  {t('components.UsageMonthlyCurrent.previousPeriod')}
                  <i className="icon icon-help"
                     data-tip={t('components.UsageMonthlyCurrent.previousPeriodTooltip', {
                       from: moment.utc().subtract(1, 'month').startOf('month').format('D'),
                       to: moment.utc().subtract(1, 'month').format('D MMM')
                     })}/>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

UsageMonthlyCurrent.defaultProps = {
  charsCount: 0,
  amount: 0,
  percent: 0,
  isLoading: false,
  currency: '$',
}

UsageMonthlyCurrent.propTypes = {
  charsCount: PropTypes.number,
  amount: PropTypes.number,
  percent: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string,
}
export default UsageMonthlyCurrent
