// flow
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '@Contexts/User'
import { PlanTypeContext } from '@Contexts/PlanType'
import type { Element } from 'react'
import LicenseKey from '@Components/LicenseKey'
import ThankYouBadgeIcon from '@Images/icons/thankyou-badge.svg'
import { useTranslation } from 'react-i18next'
import { noop } from '@Services/util'
import SkeletonLoader from '@Components/SkeletonLoader'

type Link = {
  url: string,
  label: string,
}

type Props = {
  setShouldHideChangePlanCta: Function,
}

const SignUpThankYouView = ({ setShouldHideChangePlanCta }: Props) => {
  const { t } = useTranslation()
  const { store: user } = useContext(UserContext)
  const { solutionType } = useContext(PlanTypeContext)
  useEffect(() => {
    setShouldHideChangePlanCta(true)
  }, [])

  const listOfLinks = (): Array<Element<'li'>> => {
    const links = t(
      `pages.SignupThankYouView.whatCanIDoNowLinks.${solutionType}`,
      { returnObjects: true }
    )
    return links.map(({ url, label }: Link, key): Element<any> =>
      user.pending ? (
        <SkeletonLoader key={key}>
          <li>{label}</li>
        </SkeletonLoader>
      ) : (
        <li key={key}>
          <a className='is-purple is-bold' href={url}>
            {label}
          </a>
        </li>
      )
    )
  }

  return (
    <div className='signup-thank-you-view'>
      <div className='signup-thank-you-view__wrap'>
        <div className='signup-thank-you-view__item signup-thank-you-view__item--header'>
          <h3 className='u-margin-bottom-spacer-large'>
            <img src={ThankYouBadgeIcon} alt='thank-you-bandge' />
            {t('pages.SignupThankYouView.title')}
          </h3>
          {user.pending ? (
            <SkeletonLoader fullHeight>
              {' '}
              <p>{t(`pages.SignupThankYouView.description.${solutionType}`)}</p>
            </SkeletonLoader>
          ) : (
            <p>{t(`pages.SignupThankYouView.description.${solutionType}`)}</p>
          )}
          {user.pending ? (
            <SkeletonLoader>
              {' '}
              <h5>
                {t(
                  `pages.SignupThankYouView.activationKeyTitle.${solutionType}`
                )}
              </h5>
            </SkeletonLoader>
          ) : (
            <h5>
              {t(`pages.SignupThankYouView.activationKeyTitle.${solutionType}`)}
            </h5>
          )}
        </div>
        <div className='signup-thank-you-view__item signup-thank-you-view__item--body'>
          <div className='u-margin-bottom-spacer-mid'>
            <LicenseKey
              license={user.user.activationKey}
              isLoading={user.pending}
            />
          </div>
          {user.pending ? (
            <SkeletonLoader fullHeight>
              {' '}
              <p>
                {t(
                  `pages.SignupThankYouView.activationKeyDescription.${solutionType}`
                )}
              </p>
            </SkeletonLoader>
          ) : (
            <p>
              {t(
                `pages.SignupThankYouView.activationKeyDescription.${solutionType}`
              )}
            </p>
          )}

          <hr />
        </div>
        <div className='signup-thank-you-view__item'>
          {/* <h4>{t('pages.SignupThankYouView.needAndInvoiceTitle')}</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: t('pages.SignupThankYouView.needAndInvoiceDescription', {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
          <hr /> */}
          {user.pending ? (
            <SkeletonLoader>
              <h4>{t('pages.SignupThankYouView.whatCanIDoNowTitle')}</h4>{' '}
            </SkeletonLoader>
          ) : (
            <h4>{t('pages.SignupThankYouView.whatCanIDoNowTitle')}</h4>
          )}

          <ul>{listOfLinks()}</ul>
        </div>
      </div>
    </div>
  )
}

SignUpThankYouView.defaultProps = {
  setShouldHideChangePlanCta: noop,
}

SignUpThankYouView.propTypes = {
  setShouldHideChangePlanCta: PropTypes.func.isRequired,
}
export default SignUpThankYouView
