import React from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import LogoWhite from '@Components/LogoWhite';
import LogoBlack from '@Components/LogoBlack';

type Props = {
  shouldChange: boolean,
};

const Logo = ({ changeColor, shouldChange }: Props): Element<'svg'> => {
  return shouldChange ? <LogoWhite /> : <LogoBlack />;
};

Logo.defaultProps = {
  shouldChange: false,
};

Logo.propTypes = {
  shouldChange: PropTypes.bool,
};
export default Logo;
