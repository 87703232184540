// @flow
import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
const queryString = require('query-string')
import { planTypeReducer } from '@Reducers/PlanType'
import { isEmpty } from 'lodash'
import type { PlansType } from '@Types/plans'
import type { SolutionsType } from '@Types/solutions'
import { UPDATE_PLAN_TYPE, UPDATE_SOLUTION_TYPE } from '@Actions/PlanType'

type Context = {
  planType: string,
  solutionType: string,
  updatePlanType: Function,
}

const PlanTypeContext: Object = React.createContext<Context>({})

const PLAN_TYPES = {
  free: 'free',
  batch: 'batch',
  adaptive: 'adaptive',
  lsp_teams: 'lsp_teams',
  lsp_individual: 'lsp_individual',
}
const SOLUTION_TYPES = {
  enterprise: 'enterprise',
  translator: 'translator',
}
type Store = {
  planType: string,
  solutionType: string,
}

const DEFAULT_PLAN_TYPE: PlansType = PLAN_TYPES.adaptive
const DEFAULT_SOLUTION_TYPES: SolutionsType = SOLUTION_TYPES.enterprise

const defaultStore: Store = {
  planType: DEFAULT_PLAN_TYPE,
  solutionType: DEFAULT_SOLUTION_TYPES,
}

const PlanTypeProvider = ({ children }: any) => {
  const [{ planType, solutionType }, dispatch] = useReducer(
    planTypeReducer,
    defaultStore
  )

  useEffect((): void => {
    if (!isEmpty(queryString.parse(location.search))) {
      const { plan } = queryString.parse(location.search) || ''
      if (Object.keys(PLAN_TYPES).filter((i) => i === plan).length > 0) {
        dispatch({ type: UPDATE_PLAN_TYPE, planType: plan })
      }
    }
  }, [])
  useEffect(() => {
    if (!Object.keys(PLAN_TYPES).includes(planType)) {
      dispatch({
        type: UPDATE_SOLUTION_TYPE,
        solutionType: SOLUTION_TYPES.enterprise,
      })
    } else if (
      planType === PLAN_TYPES.lsp_teams ||
      planType === PLAN_TYPES.lsp_individual
    ) {
      dispatch({
        type: UPDATE_SOLUTION_TYPE,
        solutionType: SOLUTION_TYPES.translator,
      })
    } else {
      dispatch({
        type: UPDATE_SOLUTION_TYPE,
        solutionType: SOLUTION_TYPES.enterprise,
      })
    }
  }, [planType])
  const updatePlanType = (type: PlansType): void => {
    if (Object.keys(PLAN_TYPES).includes(type)) {
      dispatch({ type: UPDATE_PLAN_TYPE, planType: type })
    } else {
      dispatch({ type: UPDATE_PLAN_TYPE, planType: 'default' })
    }
  }
  return (
    <PlanTypeContext.Provider
      value={{ planType, solutionType, updatePlanType }}
    >
      {children}
    </PlanTypeContext.Provider>
  )
}

PlanTypeProvider.defaultProps = {
  children: {},
}

PlanTypeProvider.propTypes = {
  children: PropTypes.object,
}

export { PlanTypeContext, PlanTypeProvider }
