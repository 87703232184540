// @flow
export const USER_LOGIN_REQUEST_FETCH = 'USER_LOGIN_REQUEST_FETCH'
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS'
export const USER_LOGIN_REQUEST_FAIL = 'USER_LOGIN_REQUEST_FAIL'

export const USER_RESET_PASSWORD_REQUEST_FETCH = 'USER_RESET_PASSWORD_REQUEST_FETCH'
export const USER_RESET_PASSWORD_REQUEST_SUCCESS = 'USER_RESET_PASSWORD_REQUEST_SUCCESS'
export const USER_RESET_PASSWORD_REQUEST_FAIL = 'USER_RESET_PASSWORD_REQUEST_FAIL'

export const USER_NEW_PASSWORD_REQUEST_FETCH = 'USER_NEW_PASSWORD_REQUEST_FETCH'
export const USER_NEW_PASSWORD_REQUEST_SUCCESS = 'USER_NEW_PASSWORD_REQUEST_SUCCESS'
export const USER_NEW_PASSWORD_REQUEST_FAIL = 'USER_NEW_PASSWORD_REQUEST_FAIL'
