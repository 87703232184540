import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
type Props = {
  errorMessage: string,
  onClose: Function,
}
const TranslatorListUploaderError = ({ errorMessage, onClose }: Props) => {
  return (
    <div className="translator-list-uploader-status is-error">
      <div className="translator-list-uploader-status__wrap">
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--label">
          <p>{errorMessage}</p>
        </div>
        <div className="translator-list-uploader-status__item translator-list-uploader-status__item--progress">
          <div className="translator-list-uploader-cancel">
            <span onClick={onClose}>
              <i className="icon icon-cancel"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TranslatorListUploaderError

TranslatorListUploaderError.defaultProps = {
  errorMessage: '',
  onClose: noop,
}

TranslatorListUploaderError.propTypes = {
  errorMessage: PropTypes.string,
  onClose: PropTypes.func,
}
