import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '@Contexts/User'
import { UpdateUserInformationContext } from '@Contexts/UserUpdate'
import useForm from 'react-hook-form'
import { noop } from '@Services/util'
import { useTranslation } from 'react-i18next'
import MessageBox from '@Components/MessageBox'
import LicenseRegeneration from '@Components/LicenseRegeneration'
import LicensePausing from '@Components/LicensePausing'
import { PlanTypeContext } from '@Contexts/PlanType'
import { UpdateAccessTokenContext } from '@Contexts/UpdateAccessToken'
import { LicensePauseContext } from '@Contexts/LicensePause'
import Modal from 'react-modal'
import ModalRegenerateLicense from '@Components/ModalRegenerateLicense'
import ModalPauseLicense from '@Components/ModalPauseLicense'

type Props = {
  onSubmit: Function,
}
const AccountSettingForm = ({ onSubmit }: Props): Function => {
  const { t } = useTranslation()
  const { store } = useContext(UserContext)
  const { store: updateUserInformationsStore } = useContext(
    UpdateUserInformationContext
  )
  const { register, errors, handleSubmit } = useForm()
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm()
  const [shouldShowChangeFullNameForm, setShouldShowChangeFullNameForm] =
    useState(false)
  const [shouldShowChangePasswordForm, setShouldShowChangePasswordForm] =
    useState(false)
  const [shouldShowSuccesfullMessage, setshouldShowSuccesfullMessage] =
    useState(false)
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const [userName, setUserName] = useState(store.user.name ?? '')
  const [licenseRegenerationModalIsOpen, setLicenseRegenerationModalIsOpen] =
    useState(false)
  const [licenseRegeneration, setLicenseRegeneration] = useState(false)
  const [licenseHasBeenRegenerated, setLicenseHasBeenRegenerated] =
    useState(false)
  const [
    shouldShowSuccessMessageAfterNewKey,
    setShouldShowSuccessMessageAfterNewKey,
  ] = useState(false)

  const [licensePauseModalIsOpen, setLicensePauseModalIsOpen] = useState(false)

  const [licensePausing, setLicensePausing] = useState(false)
  const [licenseUnpausing, setLicenseUnpausing] = useState(false)

  const { store: updateAccessTokenStore, updateUserAccessToken } = useContext(
    UpdateAccessTokenContext
  )
  const { store: pauseLicenseStore, setLicensePause } =
    useContext(LicensePauseContext)

  const licenseSuspended = store.user.status === 'SUSPENDED'
  const licensePaused = store.user.status === 'PAUSED'

  const { solutionType } = useContext(PlanTypeContext)
  const onChangePassword = () => {
    setShouldShowChangePasswordForm(true)
    setShouldShowChangeFullNameForm(false)
  }
  const onChangeFullName = () => {
    setShouldShowChangeFullNameForm(true)
    setShouldShowChangePasswordForm(false)
  }
  const onSuccessfulFormSubmission = () => {
    setshouldShowSuccesfullMessage(true)
    setShouldShowChangePasswordForm(false)
    setShouldShowChangeFullNameForm(false)
  }
  const onCancel = () => {
    setShouldShowChangePasswordForm(false)
  }
  const onCancelFullName = () => {
    setShouldShowChangeFullNameForm(false)
  }

  const onSubmitForm = (formData) => {
    setUserName(formData.fullName || store.user.name)

    onSubmit(
      {
        name: formData.fullName || store.user.name,
      },
      onSuccessfulFormSubmission
    )
  }

  const onSubmitForm2 = (formData) => {
    console.log(userName, store.user.name)
    onSubmit(
      {
        name: userName || store.user.name,
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      },
      onSuccessfulFormSubmission
    )
  }

  const openLicenseRegenerationModal = () => {
    setLicenseRegenerationModalIsOpen(true)
  }
  const closeLicenseRegenerationModal = () => {
    setLicenseRegenerationModalIsOpen(false)
    setShouldShowSuccessMessageAfterNewKey(false)
  }
  const onLicenseRegeneration = () => {
    setLicenseRegeneration(true)
    updateUserAccessToken(
      () => {
        setLicenseRegeneration(false)
        setLicenseHasBeenRegenerated(true)
        setShouldShowSuccessMessageAfterNewKey(true)
      },
      () => {
        setLicenseRegeneration(false)
        setShouldShowSuccessMessageAfterNewKey(false)
      }
    )
  }
  const openLicensePauseModal = () => {
    setLicensePauseModalIsOpen(true)
  }
  const closeLicensePauseModal = () => {
    setLicensePauseModalIsOpen(false)
  }
  const onLicensePause = () => {
    closeLicensePauseModal()
    setLicensePausing(true)
    setLicensePause(
      true,
      () => {
        setLicensePausing(false)
      },
      () => {
        setLicensePausing(false)
      }
    )
  }

  const onLicenseUnpause = () => {
    closeLicensePauseModal()
    setLicenseUnpausing(true)
    setLicensePause(
      false,
      () => {
        setLicenseUnpausing(false)
      },
      () => {
        setLicenseUnpausing(false)
      }
    )
  }

  return (
    <>
      {!licensePausing && !licenseUnpausing && pauseLicenseStore.error && (
        <div className='u-margin-top-spacer-base'>
          <MessageBox size='small'>{pauseLicenseStore.errorMessage}</MessageBox>
        </div>
      )}

      {!licenseRegeneration && updateAccessTokenStore.error && (
        <div className='u-margin-top-spacer-base u-margin-bottom-spacer-xlarge'>
          <MessageBox size='small'>
            {updateAccessTokenStore.errorMessage}
          </MessageBox>
        </div>
      )}

      {licenseSuspended && (
        <div className='u-margin-top-spacer-base'>
          <MessageBox size='small'>
            {t(`pages.DashboardMainView.licenseSuspendedWarning`)}
          </MessageBox>
        </div>
      )}

      {shouldShowSuccesfullMessage && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox type='success'>
            <p>
              {t('components.AccountSettingForm.updatedHasBeenUpdatedMessage')}
            </p>
          </MessageBox>
        </div>
      )}
      {passwordMismatch && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox>
            <p>{t('components.AccountSettingForm.errorPasswordMismatch')}</p>
          </MessageBox>
        </div>
      )}
      {updateUserInformationsStore.error && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox>
            <p>{updateUserInformationsStore.errorMessage}</p>
          </MessageBox>
        </div>
      )}

      <h5>{t('components.AccountSettingForm.settings.title')}</h5>

      <form
        key={1}
        className='accountSettingsForm'
        onSubmit={handleSubmit(onSubmitForm)}
        noValidate
      >
        <div className='input-container--accountSettings'>
          {!shouldShowChangeFullNameForm && (
            <div
              className={`input-container__item ${
                errors.fullName && 'has-error'
              } `}
            >
              <div>
                <label>
                  {t('components.AccountSettingForm.fullNameLabel')}
                </label>
                <span className='passwordPlaceholder'>
                  {userName || store.user.name}
                </span>
              </div>
              <span className='is-primary'>
                <a
                  onClick={onChangeFullName}
                  className='button button--secondary is-purple'
                >
                  {t('components.AccountSettingForm.fullNameChangeButtonLabel')}
                </a>
              </span>
            </div>
          )}

          {shouldShowChangeFullNameForm && (
            <div
              className={`input-container__item ${
                errors.fullName && 'has-error'
              } ${shouldShowChangeFullNameForm && 'isInline'}`}
            >
              <div>
                <label>
                  {t('components.AccountSettingForm.fullNameLabel')}
                </label>
                <input
                  className='changeFullName'
                  type='text'
                  name='fullName'
                  defaultValue={userName || store.user.name}
                  placeholder={t(
                    'components.AccountSettingForm.fullNamePlaceholder'
                  )}
                  ref={register({
                    required: t('components.AccountSettingForm.fullNameEmpty'),
                    maxLength: {
                      value: 50,
                      message: t(
                        'components.AccountSettingForm.fullNameTooLong'
                      ),
                    },
                    minLength: {
                      value: 3,
                      message: t(
                        'components.AccountSettingForm.fullNameTooShort'
                      ),
                    },
                  })}
                />
                {errors.fullName && <small>{errors.fullName.message}</small>}
              </div>
              <div className='changeFullNameContainerButtons'>
                <button
                  className={`button button--primary is-purple ${
                    updateUserInformationsStore.pending ? 'is-loading' : ''
                  }`}
                >
                  {updateUserInformationsStore.pending ? (
                    <>
                      <span/>
                      <p>
                        {t(
                          'components.AccountSettingForm.buttonChangeUpdateFullName'
                        )}
                      </p>
                    </>
                  ) : (
                    t(
                      'components.AccountSettingForm.buttonChangeUpdateFullName'
                    )
                  )}
                </button>
                <a
                  onClick={onCancelFullName}
                  className={'button button--tertiary is-purple '}
                >
                  {t(
                    'pages.DashboardBillingView.billingInfo.buttonLabelCancel'
                  )}
                </a>
              </div>
            </div>
          )}

          <div className='input-container__item input-container__item--forgot'>
            <div>
              <label>{t('components.AccountSettingForm.emailLabel')}</label>

              <span className='passwordPlaceholder'>{store.user.email}</span>
            </div>
            <span className='is-primary'>
              <a
                href='mailto:support@modernmt.com'
                className='button button--secondary is-purple'
              >
                {t('components.AccountSettingForm.emailChangeButtonLabel')}
              </a>
            </span>
          </div>
        </div>
      </form>

      <form
        key={2}
        className='accountSettingsForm passwordForm'
        onSubmit={handleSubmit2(onSubmitForm2)}
        noValidate
      >
        <div className='input-container--accountSettings'>
          {!shouldShowChangePasswordForm && (
            <div className='input-container__item'>
              <div>
                <label>
                  {t('components.AccountSettingForm.passwordLabel')}
                </label>
                <span className='passwordPlaceholder'>
                  {t('components.AccountSettingForm.passwordPlaceholder')}
                </span>
              </div>
              <span className='is-primary'>
                <a
                  onClick={onChangePassword}
                  className='button button--secondary is-purple'
                >
                  {t('components.AccountSettingForm.buttonChangePasswordLabel')}
                </a>
              </span>
            </div>
          )}

          {shouldShowChangePasswordForm && (
            <div className='changePasswordContainer'>
              <div className='changePasswordContainer__fields'>
                <div
                  className={`input-container__item ${
                    errors2.oldPassword && 'has-error'
                  }`}
                >
                  <label>
                    {t('components.AccountSettingForm.oldPasswordLabel')}
                  </label>
                  <input
                    className='changePassword'
                    type='password'
                    name='oldPassword'
                    placeholder={t(
                      'components.AccountSettingForm.oldPasswordPlaceholder'
                    )}
                    ref={register2({
                      required: t(
                        'components.AccountSettingForm.oldPasswordEmpty'
                      ),
                      minLength: {
                        value: 8,
                        message: t(
                          'components.AccountSettingForm.oldPasswordError'
                        ),
                      },
                    })}
                  />
                  {errors2.oldPassword && (
                    <small>{errors2.oldPassword.message}</small>
                  )}
                </div>
                <div
                  className={`input-container__item ${
                    passwordMismatch ? 'has-error' : ''
                  } ${errors2.newPassword && 'has-error'}`}
                >
                  <label>
                    {t('components.AccountSettingForm.newPasswordLabel')}
                  </label>
                  <input
                    className='changePassword'
                    type='password'
                    name='newPassword'
                    autoComplete='new-password'
                    placeholder={t(
                      'components.AccountSettingForm.newPasswordPlaceholder'
                    )}
                    ref={register2({
                      required: t(
                        'components.AccountSettingForm.newPasswordEmpty'
                      ),
                      minLength: {
                        value: 8,
                        message: t(
                          'components.AccountSettingForm.newPasswordError'
                        ),
                      },
                    })}
                  />
                  {errors2.newPassword && (
                    <small>{errors2.newPassword.message}</small>
                  )}
                </div>
              </div>
              <div className='changePasswordContainer__buttons'>
                <button
                  className={`button button--primary is-purple ${
                    updateUserInformationsStore.pending ? 'is-loading' : ''
                  }`}
                >
                  {updateUserInformationsStore.pending ? (
                    <>
                      <span/>
                      <p>
                        {t(
                          'components.AccountSettingForm.buttonChangePasswordLabel'
                        )}
                      </p>
                    </>
                  ) : (
                    t('components.AccountSettingForm.buttonChangePasswordLabel')
                  )}
                </button>
                <a
                  onClick={onCancel}
                  className={'button button--tertiary is-purple '}
                >
                  {t(
                    'pages.DashboardBillingView.billingInfo.buttonLabelCancel'
                  )}
                </a>
              </div>
            </div>
          )}
        </div>
      </form>

      <div className='dashbaord-wrapper__item u-margin-top-spacer-mlarge'>
        <h5>{t(`pages.DashboardAccountSettings.dangerZone.title`)}</h5>

        {!licenseSuspended && licensePaused && (
          <div className='u-margin-top-spacer-base u-margin-bottom-spacer-mlarge'>
            <MessageBox size='big' type='flex'>
              <div>
                <h5>
                  {t(`pages.DashboardMainView.licensePausedWarningTitle`)}
                </h5>
                {t(`pages.DashboardMainView.licensePausedWarning`)}
              </div>
              {!licenseUnpausing ? (
                <span className='is-primary'>
                  <a
                    className={`button button--primary is-purple`}
                    style={{
                      display: 'inline-block',
                      width: '192px',
                      color: '#fff',
                      textDecoration: 'none',
                    }}
                    onClick={onLicenseUnpause}
                  >
                    {t(`pages.DashboardMainView.unpauseLicenseButton`)}
                  </a>
                </span>
              ) : (
                <span className='is-primary'>
                  <a
                    className={`button button--primary is-purple`}
                    style={{
                      display: 'inline-block',
                      width: '192px',
                      borderColor: '#fff',
                    }}
                  >
                    <LicensePausing color='white' />
                  </a>
                </span>
              )}
            </MessageBox>
          </div>
        )}
        <div className='danger-zone'>
          <div className='danger-zone__container'>
            <div className='danger-zone__item'>
              <div className='danger-zone__item--description'>
                <h5>
                  {t(
                    `pages.DashboardMainView.regenerateLicense.${solutionType}`
                  )}
                </h5>
                <p>
                  {t(
                    `pages.DashboardMainView.regenerateLicense.regenerateDescription`
                  )}
                </p>
              </div>
              {!licenseRegeneration ? (
                <span className='is-primary'>
                  <a
                    onClick={openLicenseRegenerationModal}
                    className='button button--secondary is-red'
                  >
                    {t(
                      `pages.DashboardMainView.regenerateLicense.regenerateLabelButton`
                    )}
                  </a>
                </span>
              ) : (
                <span className='is-primary'>
                  <a className='button button--secondary is-red'>
                    <LicenseRegeneration color='red' />
                  </a>
                </span>
              )}
            </div>

            {!licensePaused && !licenseSuspended && (
              <div className='danger-zone__item'>
                <div className='danger-zone__item--description'>
                  <h5> {t(`pages.DashboardMainView.pauseLicense`)}</h5>
                  <p> {t(`pages.DashboardMainView.pauseLicenseDescription`)}</p>
                </div>
                {!licenseSuspended && licensePaused ? (
                  !licenseUnpausing ? (
                    <span className='is-primary'>
                      <a
                        onClick={openLicensePauseModal}
                        className='button button--secondary is-red'
                      >
                        {t(`pages.DashboardMainView.unpauseLicense`)}
                      </a>
                    </span>
                  ) : (
                    <span className='is-primary'>
                      <a className='button button--secondary is-red'>
                        <LicensePausing color='red' />
                      </a>
                    </span>
                  )
                ) : (
                  <span className='is-primary'>
                    {!licensePausing ? (
                      <a
                        onClick={openLicensePauseModal}
                        className='button button--secondary is-red'
                      >
                        {t(`pages.DashboardMainView.pauseLicenseBtn`)}
                      </a>
                    ) : (
                      <a className='button button--secondary is-red'>
                        <LicensePausing color='red' />
                      </a>
                    )}
                  </span>
                )}
              </div>
            )}

            <div className='danger-zone__item'>
              <div className='danger-zone__item--description'>
                <h5>
                  {t('pages.DashboardAccountSettings.requestUserDeletionLabel')}
                </h5>
                <p>
                  {t(
                    'pages.DashboardAccountSettings.requestUserDeletionDescription'
                  )}
                </p>
              </div>

              <span className='is-primary'>
                <a
                  href='mailto:support@modernmt.com'
                  className='button button--secondary is-red'
                >
                  {t('pages.DashboardAccountSettings.requestUserDeletion')}
                </a>
              </span>
            </div>
          </div>

          <Modal
            isOpen={licenseRegenerationModalIsOpen}
            onRequestClose={closeLicenseRegenerationModal}
          >
            <ModalRegenerateLicense
              licenseRegeneration={licenseRegeneration}
              shouldShowSuccessMessageAfterNewKey={
                shouldShowSuccessMessageAfterNewKey
              }
              onSubmit={onLicenseRegeneration}
              onCancel={closeLicenseRegenerationModal}
              isInModal={true}
            />
          </Modal>
          <Modal
            isOpen={licensePauseModalIsOpen}
            onRequestClose={closeLicensePauseModal}
          >
            <ModalPauseLicense
              onSubmit={onLicensePause}
              onLicenseUnpause={onLicenseUnpause}
              onCancel={closeLicensePauseModal}
              licensePaused={licensePaused}
              licensePausing={licensePausing}
            />
          </Modal>
        </div>
      </div>
    </>
  )
}

AccountSettingForm.defaultProps = {
  onSubmit: noop,
}

AccountSettingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default AccountSettingForm
