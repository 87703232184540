// flow
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ROUTES_PATHS from '../routes_paths'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SignupForm from '@Components/SignupForm'
import { PlanTypeContext } from '@Contexts/PlanType'
import ListWithThick from '@Components/ListWithThick'
import { Context as UserSignupContext } from '@Contexts/UserSignup'
import { noop } from '@Services/util'
import { UserContext } from '@Contexts/User'
const queryString = require('query-string')
import { get } from 'lodash'

type Props = {
  setShouldHideChangePlanCta: Function,
}

const SignupFormView = ({ setShouldHideChangePlanCta }: Props) => {
  const { onUserSignup } = useContext(UserSignupContext)

  const { onGetUser, setSignupData } = useContext(UserContext)
  const { solutionType } = useContext(PlanTypeContext)
  const { t } = useTranslation()
  const history = useHistory()

  const goToBillInfoPage = ({ country, isBusiness }): void => {
    const query = queryString.parse(location.search)
    const plan = get(query, 'plan', '')

    if (!isBusiness && country !== 'IT') {
      history.push(`${ROUTES_PATHS.signupThankYou}?plan=${plan}`)
    } else {
      plan
        ? history.push(`${ROUTES_PATHS.signupBill}?plan=${plan}`)
        : history.push(ROUTES_PATHS.signupBill)
    }
  }

  const onGetUserAfterSignup = (token: string): void => {
    return onGetUser(token, goToBillInfoPage)
  }

  const onSubmit = (userData, handleStripePayments): void => {
    const { name, isBusiness } = userData
    setSignupData({ name, isBusiness })
    return onUserSignup(userData, handleStripePayments, onGetUserAfterSignup)
  }

  useEffect(() => {
    setShouldHideChangePlanCta(false)
  }, [])
  return (
    <div className='signup-form-view'>
      <div className='signup-form-view__wrap'>
        <div className='signup-form-view__item'>
          <h3 className='u-margin-bottom-spacer-large'>
            {t(`pages.SignupFormView.title.${solutionType}`)}
          </h3>
          <SignupForm onSubmit={onSubmit} />
          <ListWithThick
            isBigger
            list={t(`pages.SignupFormView.listOfFeatures.${solutionType}`, {returnObjects: true})}
          />
        </div>
      </div>
    </div>
  )
}

SignupFormView.defaultProps = {
  setShouldHideChangePlanCta: noop,
}

SignupFormView.propTypes = {
  setShouldHideChangePlanCta: PropTypes.func.isRequired,
}

export default SignupFormView
