// @flow
import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { invoiceReducer, invoicePayingReducer } from '@Reducers/Invoice'
import type { InvoiceType } from '@Types/Invoice'
import { getInvoiceById, updateInvoiceById } from '@Services/api'
import {
  INVOICE_FETCH,
  INVOICE_SUCCESS,
  INVOICE_FAIL,
  INVOICE_PAYING_FETCH,
  INVOICE_PAYING_SUCCESS,
  INVOICE_PAYING_FAIL,
} from '@Actions/Invoice'
import i18n from 'i18next'
import { get } from 'lodash'
import { noop } from '@Services/util'
type Context = {
  getInvoice: Function,
  store: Store,
}

const InvoiceContext: Object = createContext<Context>({})

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  alreadyFetched: boolean,
  invoice: InvoiceType,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  alreadyFetched: false,
  errorMessage: '',
  invoice: {},
}

const InvoiceProvider = ({ children }: any) => {
  const [store, dispatch] = useReducer(invoiceReducer, defaultStore)
  const [paymentInvoiceStore, dispatchPaymentInvoice] = useReducer(invoicePayingReducer, defaultStore)
  const getInvoice = (id: string, onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: INVOICE_FETCH })
    getInvoiceById(id)
      .then(response => {
        dispatch({ type: INVOICE_SUCCESS, invoice: response })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: INVOICE_FAIL, errorMessage })
        onError()
      })
  }
  const payInvoice = (
    id: string,
    // token: string,
    handleStripeCardConfirmation: Function,
    onComplete: Function = noop,
    onError: Function = noop
  ) => {
    dispatchPaymentInvoice({ type: INVOICE_PAYING_FETCH })
    handleStripeCardConfirmation()
      .then(response => {
        return updateInvoiceById(id, response.id).then(res => res)
      })
      .then(() => {
        dispatchPaymentInvoice({ type: INVOICE_PAYING_SUCCESS })
        dispatch({ type: INVOICE_SUCCESS, invoice: { ...store.invoice, isPaid: true } })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatchPaymentInvoice({ type: INVOICE_PAYING_FAIL, errorMessage })
        onError()
      })
  }
  return (
    <InvoiceContext.Provider value={{ store, getInvoice, payInvoice, paymentInvoiceStore }}>
      {children}
    </InvoiceContext.Provider>
  )
}

InvoiceProvider.defaultProps = {
  children: [],
}

InvoiceProvider.propTypes = {
  children: PropTypes.array,
}

export { InvoiceContext, InvoiceProvider }
