// @flow
import React from 'react'
import type { Element } from 'react'
type LicenseRegenerationColor = 'is-red'

type Props = {
  type?: string,
  color?: LicenseRegenerationColor,
}

const LicenseRegeneration = ({ type, color }: Props): Element<'div'> => {
  return (
    <div className={`license-regeneration__item ${type}`}>
      <div
        className={`loader-ring ${type ? 'loader-ring--' + type : ''}  ${
          color ? 'is-' + color : ''
        }`}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default LicenseRegeneration
