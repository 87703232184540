import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'

import { useTranslation } from 'react-i18next'
import CartWithNotifyIcon from '@Images/icons/cart-with-notify.svg'
import HelpIcon from '@Images/icons/help.svg'
import { useRouteMatch } from 'react-router-dom'
import ROUTES_PATHS from '../routes_paths'
import { noop } from '@Services/util'

type HeaderMobileNavigationProps = {
  onShouldShowOverlay: Function,
}

const HeaderSignupNavigationMobile = ({ onShouldShowOverlay }: HeaderMobileNavigationProps): Element<'ul'> => {
  const match = useRouteMatch(ROUTES_PATHS.signupThankYou)

  return (
    <ul>
      <li>
        <img src={HelpIcon} alt="help" />
      </li>
      {!match && (
        <li>
          <img onClick={onShouldShowOverlay} src={CartWithNotifyIcon} alt="cart" />
        </li>
      )}
    </ul>
  )
}

const HeaderSignupNavigationDesktop = () => {
  const { t } = useTranslation()
  return (
    <p>
      {t('pages.SignupView.Header.ctaDescription')}
      <a
        href={'/contact-us'}
        className="u-margin-left-spacer-small button has-regular-height button--secondary is-purple"
      >
        {t('pages.SignupView.Header.ctaLabel')}
      </a>
    </p>
  )
}

HeaderSignupNavigationMobile.defaultProps = {
  onHeaderCtaClick: noop,
}

HeaderSignupNavigationMobile.propTypes = {
  onHeaderCtaClick: PropTypes.func,
}

HeaderSignupNavigationDesktop.defaultProps = {
  onHeaderCtaClick: noop,
}

HeaderSignupNavigationDesktop.propTypes = {
  onHeaderCtaClick: PropTypes.func,
}

export { HeaderSignupNavigationMobile, HeaderSignupNavigationDesktop }
