// @flow
import React, { useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import { copyStringToClipboard } from '@Services/util'
import { useTranslation } from 'react-i18next'
import { PlanTypeContext } from '@Contexts/PlanType'
import SkeletonLoader from '@Components/SkeletonLoader'

type Props = {
  license: string,
  isLoading: boolean,
  isInModal: boolean,
}

const LicenseKey = ({
  isInModal,
  license,
  isLoading,
}: Props): Element<'div'> => {
  const { t } = useTranslation()
  let licenseWrapper = useRef()
  const { solutionType } = useContext(PlanTypeContext)

  const [isCopied, setIsCopied] = useState(false)
  function copyToClipboard(): void {
    setIsCopied(true)
    copyStringToClipboard(license)
    const el: ?HTMLDivElement = licenseWrapper.current
    if (el) {
      const copied = setTimeout(() => {
        setIsCopied(false)
        clearTimeout(copied)
      }, 1000)
      el.classList.add('is-copied')
      const timeout = setTimeout(() => {
        el.classList.remove('is-copied')
        clearTimeout(timeout)
      }, 200)
    }
  }
  return (
    <div
      ref={licenseWrapper}
      className={`license-key ${isInModal && 'u-margin-bottom-spacer-mid'}`}
    >
      <div className='license-key__wrap'>
        <div className='license-key__item license-key__item--license'>
          {!isLoading ? (
            <p>
              {license
                ? license
                : t(`components.LicenseKey.noLicenseAvailable.${solutionType}`)}
            </p>
          ) : (
            <SkeletonLoader type='is-white'>
              <p>000000-0000-0000-0000-0000000</p>
            </SkeletonLoader>
          )}
        </div>
        {!isLoading && (
          <div className='license-key__item license-key__item--copy '>
            {!isCopied ? (
              <div onClick={copyToClipboard}>
                {t('components.LicenseKey.copyLabel')}
              </div>
            ) : (
              <div className='copied '>
                {t('components.LicenseKey.copiedLabel')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

LicenseKey.defaultProps = {
  license: '',
  isLoading: false,
}

LicenseKey.propTypes = {
  license: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default LicenseKey
