// @flow
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoginResetPasswordForm from '@Components/LoginResetPasswordForm'
import { UserLoginContext } from '@Contexts/UserLogin'
import MessageBox from '@Components/MessageBox'
import ROUTER_PATHS from '../routes_paths'

const LoginResetPasswordView = () => {
  const { t } = useTranslation()
  const { onUserResetPassword } = useContext(UserLoginContext)
  const [showMessage, setShowMessage] = useState(false)
  const onSubmit = function({ email }: Object): void {
    onUserResetPassword(email, onCompleteSubmitForm)
  }
  const onCompleteSubmitForm = (): void => {
    setShowMessage(true)
  }

  return (
    <div className="login-form-view">
      <div className="login-form-view__wrap">
        <div className="login-form-view__item">
          <h2 className="u-margin-bottom-spacer-large">{t('pages.LoginResetPasswordView.title')}</h2>
        </div>
        {showMessage && (
          <div className="login-form-view__item u-margin-bottom-spacer-large">
            <MessageBox isSuccess>
              <p>{t('pages.LoginResetPasswordView.message')}</p>
            </MessageBox>
          </div>
        )}
        {!showMessage ? (
          <div className="login-form-view__item">
            <LoginResetPasswordForm onSubmit={onSubmit} />
          </div>
        ) : (
          <div className="login-form-view__item">
            <Link to={ROUTER_PATHS.loginBaseUrl} className="button button--secondary is-full is-purple">
              {t('pages.LoginResetPasswordView.buttonBackToSignIn')}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginResetPasswordView
