//@flow
import {
  USER_LOGIN_REQUEST_FETCH,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_FAIL,
  USER_RESET_PASSWORD_REQUEST_FETCH,
  USER_RESET_PASSWORD_REQUEST_SUCCESS,
  USER_RESET_PASSWORD_REQUEST_FAIL,
  USER_NEW_PASSWORD_REQUEST_FETCH,
  USER_NEW_PASSWORD_REQUEST_SUCCESS,
  USER_NEW_PASSWORD_REQUEST_FAIL,
} from '@Actions/UserLogin'

export const userLoginReducer = (state: any, action: any) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST_FETCH:
      return { ...state, pending: true, error: false }
    case USER_LOGIN_REQUEST_SUCCESS:
      return { ...state, pending: false, error: false }
    case USER_LOGIN_REQUEST_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}

export const userResetPasswordReducer = (state: any, action: any) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST_FETCH:
      return { ...state, pending: true, error: false }
    case USER_RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...state, pending: false, error: false }
    case USER_RESET_PASSWORD_REQUEST_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}

export const userNewPasswordReducer = (state: any, action: any) => {
  switch (action.type) {
    case USER_NEW_PASSWORD_REQUEST_FETCH:
      return { ...state, pending: true, error: false }
    case USER_NEW_PASSWORD_REQUEST_SUCCESS:
      return { ...state, pending: false, error: false, passwordHasBeenReset: true }
    case USER_NEW_PASSWORD_REQUEST_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage, passwordHasBeenReset: false }
    default:
      return state
  }
}
