// @flow
import React from 'react'

const PageNotFoundView = () => {
  return (
    <div>
      <h3>404 Page not found</h3>
      <a href="/">Back to home</a>
    </div>
  )
}

export default PageNotFoundView
