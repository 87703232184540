// @flow
import React, { useReducer, createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { getUserReducer } from '@Reducers/User'
import { noop, calculateUsagePercentCurrentMonth } from '@Services/util'
import {
  USER_REQUEST_FETCH,
  USER_REQUEST_SUCCESS,
  USER_REQUEST_FAIL,
  USER_REQUEST_LOGOUT,
} from '@Actions/User'
import { getUser, getBillingPeriodsByDates } from '@Services/api'
import * as moment from 'moment'
import type { UserData } from '@Types/User'
import type { BillingInformationsType } from '@Types/BillingInformations'
import {
  setUserIsLoggedIn,
  getUserLoggedOut,
  setLicenseKeyOnLocalstorage,
} from '@Services/localstorage'
import { get } from 'lodash'
import { PlanTypeContext } from '@Contexts/PlanType'

type Context = {
  onGetUser: Function,
  onUpdateUserPaymentMethodInUserContext: Function,
  onUpdateBillingInformationsInUserContext: Function,
  onUpdateUserInformationsInUserContext: Function,
  onUpdateActivationKeyContext: Function,
  onUserLoggingOut: Function,
  onLicenseStatusChange: Function,
  store: Store,
}

type Store = {
  pending: boolean,
  fetchNotStarted: boolean,
  error: boolean,
  errorMessage: string,
  user: UserData,
}

const defaultStore: Store = {
  pending: false,
  fetchNotStarted: true,
  error: false,
  errorMessage: '',
  user: {
    id: '',
    name: '',
    email: '',
    registrationDate: null,
    country: '',
    activationKey: '',
    billingInformations: {
      vatId: '',
      country: '',
      businessName: '',
      businessAddress: '',
      businessZipCode: '',
      businessCity: '',
      businessCountry: '',
      sdiCode: '',
      pecEmail: '',
      taxCode: '',
    },
    card: {
      brand: '',
      paymentIntent: '',
      lastDigits: '',
      expMonth: '',
      expYear: '',
    },
    billingPeriod: {
      start: null,
      end: null,
      currencySymbol: '$',
      amount: 0,
      charsCount: 0,
      planType: null,
      planDescription: '',
      usagePercentFromLastMonth: 0,
    },
    status: '',
  },
}

const UserContext = createContext<Context>({})

const UserProvider = ({ children }: any): any => {
  const [store, dispatch] = useReducer(getUserReducer, defaultStore)
  const [signupData, setSignupData] = useState({})

  const { updatePlanType } = useContext(PlanTypeContext)
  const onGetUser = (
    token: string,
    onComplete: Function = noop,
    onError: Function = noop
  ): Promise<any> => {
    dispatch({ type: USER_REQUEST_FETCH })
    return getUser(token)
      .then((response) => response)
      .then((response) => {
        const { billingPeriod } = response
        const date = get(billingPeriod, 'start', '')
        const from = moment.utc(date).subtract(1, 'months').format('YYYY-MM-DD')
        const to = moment.utc(date).format('YYYY-MM-DD')

        return getBillingPeriodsByDates(from, to).then((lastMonthBillingPeriod) => {
          return {
            ...response,
            billingPeriod: {
              ...response.billingPeriod,
              usagePercentFromLastMonth: calculateUsagePercentCurrentMonth(
                get(lastMonthBillingPeriod, '[0].chars', 0),
                response.billingPeriod.charsCount
              ),
            },
          }
        })
      })
      .then((response) => {
        const planType = get(response, 'billingPeriod.planType', '')
        dispatch({ type: USER_REQUEST_SUCCESS, user: response })
        setUserIsLoggedIn()
        onComplete({
          country: response?.billingInformations?.country,
          isBusiness: response?.billingInformations?.isBusiness,
        })
        setLicenseKeyOnLocalstorage(response.activationKey)
        planType && updatePlanType(planType)
      })
      .catch((error) => {
        getUserLoggedOut()
        const errorMessage = get(
          error,
          'response.data.error.message',
          i18n.t('globals.errors.generalError')
        )
        dispatch({ type: USER_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

  const onUpdateUserPaymentMethodInUserContext = (
    updatedPaymentMethods: any
  ) => {
    dispatch({
      type: USER_REQUEST_SUCCESS,
      user: { ...store.user, card: updatedPaymentMethods },
    })
  }
  const onUserLoggingOut = (): void => {
    dispatch({ type: USER_REQUEST_LOGOUT })
  }
  const onUpdateBillingInformationsInUserContext = (
    billingInformations: BillingInformationsType
  ) => {
    dispatch({
      type: USER_REQUEST_SUCCESS,
      user: { ...store.user, billingInformations: billingInformations },
    })
  }

  const onUpdateUserInformationsInUserContext = (
    updatedUserInformations: any
  ) => {
    dispatch({
      type: USER_REQUEST_SUCCESS,
      user: { ...store.user, user: updatedUserInformations },
    })
  }

  const onUpdateActivationKeyContext = (activationKey: string) => {
    dispatch({
      type: USER_REQUEST_SUCCESS,
      user: { ...store.user, activationKey },
    })
    setLicenseKeyOnLocalstorage(activationKey)
  }

  const onLicenseStatusChange = (status: string) => {
    dispatch({ type: USER_REQUEST_SUCCESS, user: { ...store.user, status } })
  }

  return (
    <UserContext.Provider
      value={{
        onGetUser,
        onUpdateUserPaymentMethodInUserContext,
        onUpdateBillingInformationsInUserContext,
        onUpdateUserInformationsInUserContext,
        onUpdateActivationKeyContext,
        onUserLoggingOut,
        onLicenseStatusChange,
        setSignupData,
        signupData,
        store,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.defaultProps = {
  children: {},
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export { UserContext, UserProvider }
