// flow
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import { useTranslation } from 'react-i18next'
import { importCountriesByLang, noop } from '@Services/util'
import { useFormContext } from 'react-hook-form'
import countries from 'i18n-iso-countries'
const currentLang = 'en'
countries.registerLocale(importCountriesByLang(currentLang))
type Props = {
  onChange: Function,
  defaultValue: string,
}

function normalizeCountryName(countries: Object): Array<Object> {
  return Object.keys(countries)
    .map(function (key) {
      return {
        name: countries[key],
        code: key,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}
const CountrySelect = ({
  onChange,
  defaultValue,
}: Props): Element<'select'> => {
  const { t } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState(defaultValue)
  const { register } = useFormContext()
  const countriesToOptions = (
    country: Object,
    key: number
  ): Element<'option'> => {
    return (
      <option key={key} value={country.code}>
        {country.name}
      </option>
    )
  }
  const countriesNamesRaw: Object = countries.getNames(currentLang)
  const countriesNames: Array<Object> = normalizeCountryName(countriesNamesRaw)
  const onChangeCountry = (event: any) => {
    const country = event.target.value
    setSelectedCountry(country)
    onChange({ name: countriesNamesRaw[country], code: country })
  }

  return (
    <select
      name='businessCountry'
      id='country-select'
      defaultValue={selectedCountry}
      onBlur={onChangeCountry} // iOS fix
      onChange={onChangeCountry}
      ref={register({
        required: t('globals.errors.fieldRequired'),
      })}
    >
      <option hidden value=''>
        {t('components.CountrySelect.defaultValue')}
      </option>
      {countriesNames.map(countriesToOptions)}
    </select>
  )
}

CountrySelect.defaultProps = {
  onChange: noop,
  defaultValue: '',
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
}

export default CountrySelect
