// flow
import React, { useContext, useEffect, useState } from 'react'
import { Context as InvoicesContext } from '@Contexts/Invoices'
import { useTranslation } from 'react-i18next'
import SkeletonLoader from '@Components/SkeletonLoader'
import type { Element } from 'react'
import InvoiceTableRow from '@Components/InvoiceTableRow'
import InvoiceTableHeader from '@Components/InvoiceTableHeader'
import { range } from 'lodash'
import * as moment from 'moment'

const InvoiceTable = (): Element<'div'> => {
  const { t } = useTranslation()
  const [initialDate, setInitialDate] = useState(moment.utc().toDate())
  const { store, onGetInvoices, onGetInvoicePdf } = useContext(InvoicesContext)
  const [isEmpty, setIsEmpty] = useState(true)

  useEffect(() => {
    store.invoices.length > 0 && !store.pending && setIsEmpty(false)
  }, [store])

  useEffect(() => {
    if (store.invoices.length === 0) {
      onGetInvoices(initialDate)
    }
  }, [])

  const onViewMore = () => {
    const updatedDate = moment.utc(initialDate).subtract(1, 'years').toDate()
    onGetInvoices(updatedDate, () => setInitialDate(updatedDate))
  }

  return (
    <div className="invoice-table">
      <div className="invoice-table__wrap">
        <div className="invoice-table__item invoice-table__item--header">{!isEmpty && <InvoiceTableHeader />}</div>
        <div className="invoice-table__item invoice-table__item--body">
          {store.pending && !store.alreadyFetched && (
            <>
              {range(5).map(key => (
                <div className="invoice-table-row" key={key}>
                  <div className="invoice-table-row__wrap">
                    <div className="invoice-table-row__item is-full">
                      <SkeletonLoader>
                        <p>{t('globals.loadingState')}</p>
                      </SkeletonLoader>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {!store.pending && isEmpty ? (
            <div className="invoice-table-row">
              <div className="invoice-table-row__wrap">
                <div className="invoice-table-row__item is-full">
                  <p>{t('components.InvoicesTable.emptyState')}</p>
                </div>
              </div>
            </div>
          ) : (
            store.invoices.map((invoice, key) => (
              <InvoiceTableRow
                key={key}
                id={invoice.id}
                stripePayment={invoice.stripePayment}
                date={invoice.date}
                chars={invoice.chars}
                currencySymbol={invoice.currencySymbol}
                amount={invoice.amount}
                isReversed={invoice.isReversed}
                isExternal={invoice.isExternal}
                isPaid={invoice.isPaid}
                url={invoice.url}
                onGetInvoicePdf={onGetInvoicePdf}
              />
            ))
          )}
        </div>
        {!isEmpty && (
          <div className="invoice-table__item invoice-table__item--footer">
            <a
              onClick={onViewMore}
              className={`button  ${store.hasMore ? 'is-purple' : 'is-disabled'} ${store.pending ? 'is-loading' : ''}`}
            >
              {store.pending ? (
                <>
                  <span/>
                  <p>{t('components.InvoicesTable.buttonLabel')}</p>
                </>
              ) : (
                <p>{t('components.InvoicesTable.buttonLabel')}</p>
              )}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default InvoiceTable
