// @flow
import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import PropTypes from 'prop-types'
import { noop, copyStringToClipboard } from '@Services/util'
import SUPPORTED_LANGUAGES from '@Services/supportedLanguages'

type Props = {
  isInFocus?: Function,
  onChangeTextValue?: Function,
  onCleanTextValue: Function,
  textValue: string,
  textValuePlaceholder: string,
  shouldShowChartCount?: boolean,
  shouldShowCopyInClipboard?: boolean,
  onButtonPress?: Function,
  onButtonPressLabel?: string,
  isLoading?: boolean,
  shouldShowLoadingState?: boolean,
  selectedLanguage: string,
}

const TranslatorTextArea = ({
  isInFocus,
  onChangeTextValue,
  onCleanTextValue,
  textValue,
  textValuePlaceholder,
  shouldShowChartCount,
  shouldShowCopyInClipboard,
  onButtonPress,
  onButtonPressLabel,
  isLoading,
  shouldShowLoadingState,
  selectedLanguage,
}: Props) => {
  const [isCopying, setIsCopying] = useState(false)

  const onBlur = (): void => {
    isInFocus && isInFocus(false)
  }

  const onFocus = (): void => {
    isInFocus && isInFocus(true)
  }

  const onChange = event => {
    const { value } = event.target
    onChangeTextValue && onChangeTextValue(value)
  }

  const onCopyToClipboard = () => {
    setIsCopying(true)
    copyStringToClipboard(textValue, false) // false avoid uppercase
    setTimeout(() => {
      setIsCopying(false)
    }, 1000)
  }

  const shouldShowValueOrLoadingState = () => {
    if (shouldShowLoadingState && isLoading) {
      if (textValue.length > 0) {
        return `${textValue}...`
      } else {
        return ''
      }
    } else {
      return textValue
    }
  }

  return (
    <div className="translator-textarea">
      <div className="translator-textarea__wrap">
        <div
          className={`
          translator-textarea__item 
          translator-textarea__item--textarea 
          ${textValue ? 'has-text' : ''}
          ${!onChangeTextValue ? 'is-read-only' : ''}
          `}
        >
          <div className="translator-textarea-selected-language">
            {selectedLanguage.length > 0 ? <p>{SUPPORTED_LANGUAGES[selectedLanguage]}</p> : <p>Unknown</p>}
          </div>
          <TextareaAutosize
            readOnly={!onChangeTextValue}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            className={`${!textValue.length && isLoading ? 'translator-textarea-blink' : ''}`}
            value={shouldShowValueOrLoadingState()}
            placeholder={textValuePlaceholder}
          />
          {onCleanTextValue && (
            <span className="translator-textarea-cleaner" onClick={onCleanTextValue}>
              <i className="icon icon-close"/>
            </span>
          )}
        </div>
        <div className="translator-textarea__item translator-textarea__item--footer">
          <div className="translator-textarea-footer">
            {onButtonPressLabel && onButtonPress ? (
              <a onClick={onButtonPress} className="button button--secondary is-purple is-small">
                {onButtonPressLabel}
              </a>
            ) : (
              <div/>
            )}
            {shouldShowChartCount && !shouldShowCopyInClipboard && <p>{textValue.length} / 5000</p>}
            {!shouldShowChartCount && shouldShowCopyInClipboard && (
              <i onClick={onCopyToClipboard} className={`icon icon-copy ${isCopying ? 'is-copying' : ''}`}/>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

TranslatorTextArea.defaultProps = {
  isInFocus: noop,
  onChangeTextValue: null,
  textValue: '',
  onCleanTextValue: null,
  textValuePlaceholder: '',
  shouldShowChartCount: false,
  shouldShowCopyInClipboard: false,
  onButtonPress: noop,
  onButtonPressLabel: '',
  isLoading: false,
  shouldShowLoadingState: false,
  selectedLanguage: '',
}

TranslatorTextArea.propTypes = {
  isInFocus: PropTypes.func.isRequired,
  onChangeTextValue: PropTypes.func,
  textValue: PropTypes.string.isRequired,
  onCleanTextValue: PropTypes.func,
  textValuePlaceholder: PropTypes.string,
  shouldShowChartCount: PropTypes.bool,
  shouldShowCopyInClipboard: PropTypes.bool,
  onButtonPress: PropTypes.func,
  onButtonPressLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  shouldShowLoadingState: PropTypes.bool,
  selectedLanguage: PropTypes.string.isRequired,
}
export default TranslatorTextArea
