// flow
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from '@Components/Logo';
import type { Element } from 'react';
import { noop } from '@Services/util';

type Props = {
  children: Object,
  onShouldShowOverlay: Function,
  shouldShowOverlay: boolean,
};
const HeaderDashboard = ({
  children,
  shouldShowOverlay,
  onShouldShowOverlay,
}: Props): Element<'header'> => {
  const [shouldChangeLogoColor, setShouldChangeLogoColor] = useState(false);

  function detectIfShouldChangeLogoColor(): void {
    const { matches } = window.matchMedia('(max-width: 768px)');
    setShouldChangeLogoColor(matches);
  }

  useEffect(() => {
    detectIfShouldChangeLogoColor();
    window.addEventListener('resize', detectIfShouldChangeLogoColor);
    return () =>
      window.removeEventListener('resize', detectIfShouldChangeLogoColor);
  }, []);

  return (
    <header
      className={`header header--dashboard ${
        shouldShowOverlay ? 'is-open' : ''
      }`}
    >
      <div className="header__wrap">
        <div className="header__item header__item--brand">
          <a href="/">
            <Logo shouldChange={shouldChangeLogoColor && shouldShowOverlay} />
          </a>
        </div>
        <div className="header__item u-is-mobile">
          <div className="header-navigation">
            <div className="header-navigation__wrap">
              <div className="header-navigation__item header-navigation__item--nav">
                <nav>
                  <ul>
                    <li>
                      {shouldShowOverlay ? (
                        <i
                          className="icon-close"
                          onClick={onShouldShowOverlay}
                        ></i>
                      ) : (
                        <i
                          className="icon-menu"
                          onClick={onShouldShowOverlay}
                        ></i>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-navigation__item header-navigation__item--overlay">
                <div className="header-overlay">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderDashboard.defaultProps = {
  children: {},
  onShouldShowOverlay: noop,
  shouldShowOverlay: false,
};

HeaderDashboard.propTypes = {
  children: PropTypes.object,
  onShouldShowOverlay: PropTypes.func,
  shouldShowOverlay: PropTypes.bool,
};

export default HeaderDashboard;
