// flow
import React from 'react'
import type { Element } from 'react'

// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
const InvoiceTableHeader = (): Element<'div'> => {
  const { t } = useTranslation()
  return (
    <div className="invoice-table-header">
      <div className="invoice-table-header__wrap">
        <div className="invoice-table-header__item">
          <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnDate')}</strong>
        </div>
        <div className="invoice-table-header__item">
          <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnCharacters')}</strong>
        </div>
        <div className="invoice-table-header__item">
          <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnAmount')}</strong>
        </div>
        <div className="invoice-table-header__item">
          <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnStatus')}</strong>
        </div>
        <div className="invoice-table-header__item"></div>
      </div>
    </div>
  )
}

// InvoiceTableHeader.defaultProps = {}

// InvoiceTableHeader.propTypes = {}

export default InvoiceTableHeader
