// @flow
import { USER_SIGNUP_REQUEST_FETCH, USER_SIGNUP_REQUEST_SUCCESS, USER_SIGNUP_REQUEST_FAIL } from '@Actions/UserSignup'

export const userSignupReducer = (state: any, action: any) => {
  switch (action.type) {
    case USER_SIGNUP_REQUEST_FETCH:
      return { ...state, pending: true, error: false }
    case USER_SIGNUP_REQUEST_SUCCESS:
      return { ...state, activationKey: action.activationKey, pending: false, error: false }
    case USER_SIGNUP_REQUEST_FAIL:
      return { ...state, activationKey: '', pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
