// @flow
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import DashboardSidebar from '@Components/DashboardSidebar'
import HeaderDashboard from '@Components/HeaderDashboard'
import DashboardMainView from '@Views/DashboardMainView'
import DashboardManagePlanView from '@Views/DashboardBillingView'
import DashboardAccountSettingsView from '@Views/DashboardAccountSettingsView'
import ROUTES_PATHS from '../routes_paths'
import { PlanTypeProvider } from '@Contexts/PlanType'
import { UpdateUserInformationProvider } from '@Contexts/UserUpdate'
import { BillingInformationProvider } from '@Contexts/BillingInformation'
import { PaymentMethodsProvider } from '@Contexts/PaymentMethods'
import { UpdateAccessTokenProvider } from '@Contexts/UpdateAccessToken'
import { LicensePauseProvider } from '@Contexts/LicensePause'
import { Provider as InvoicesProvider } from '@Contexts/Invoices'
import { useTranslation } from 'react-i18next'

const DashboardView = () => {
  const { t } = useTranslation()
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false)
  const onShouldShowOverlay = (): void => {
    setShouldShowOverlay(!shouldShowOverlay)
  }

  const hiseOverlayOnRouteChange = (): void => {
    setShouldShowOverlay(false)
  }
  return (
    <>
      <Helmet>
        <title>{t('pages.DashboardView.pageTitle')}</title>
      </Helmet>
      <PlanTypeProvider>
        <PaymentMethodsProvider>
          <BillingInformationProvider>
            <InvoicesProvider>
              <div className="dashboard">
                <div className="dashboard__wrap">
                  <div className="dashboard__item dashboard__item--header ">
                    <HeaderDashboard onShouldShowOverlay={onShouldShowOverlay} shouldShowOverlay={shouldShowOverlay}>
                      <DashboardSidebar shouldShowOverlay={onShouldShowOverlay} />
                    </HeaderDashboard>
                  </div>
                  <div className="dashboard__item dashboard__item--sidebar">
                    <DashboardSidebar shouldShowOverlay={hiseOverlayOnRouteChange} />
                  </div>
                  <div className="dashboard__item dashboard__item--body">
                    <div className="dashboard-container">
                      <UpdateAccessTokenProvider>
                        <LicensePauseProvider>
                          <UpdateUserInformationProvider>
                            <Switch>
                              <Route exact path={ROUTES_PATHS.dashboardBaseUrl} component={DashboardMainView} />
                              <Route
                                exact
                                path={ROUTES_PATHS.dashboardBillingUrl}
                                component={DashboardManagePlanView}
                              />
                              <Route
                                exact
                                path={ROUTES_PATHS.dashboardAccountSettingsUrl}
                                component={DashboardAccountSettingsView}
                              />
                            </Switch>
                          </UpdateUserInformationProvider>
                        </LicensePauseProvider>
                      </UpdateAccessTokenProvider>
                    </div>
                  </div>
                </div>
              </div>
            </InvoicesProvider>
          </BillingInformationProvider>
        </PaymentMethodsProvider>
      </PlanTypeProvider>
    </>
  )
}

export default DashboardView
