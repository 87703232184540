//@flow
import {
  TRANSLATOR_MEMORIES_FETCH,
  TRANSLATOR_MEMORIES_SUCCESS,
  TRANSLATOR_MEMORIES_FAIL,
  TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES,
  TRANSLATOR_MEMORIES_UPLOAD_START,
  TRANSLATOR_MEMORIES_UPLOAD_UPDATING,
  TRANSLATOR_MEMORIES_UPLOAD_END,
  TRANSLATOR_MEMORIES_PROCESS_UPDATING,
  TRANSLATOR_MEMORIES_UPLOAD_CANCEL,
  TRANSLATOR_MEMORIES_UPLOAD_FAIL,
} from '@Actions/TranslatorMemories'

export const TranslatorMemoriesReducer = (state: any, action: any) => {
  switch (action.type) {
    case TRANSLATOR_MEMORIES_FETCH:
      return { ...state, pending: true, error: false }
    case TRANSLATOR_MEMORIES_SUCCESS:
      return { ...state, pending: false, error: false, memories: action.memories }
    case TRANSLATOR_MEMORIES_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    case TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES: {
      return { ...state, activeMemories: action.activeMemories }
    }
    case TRANSLATOR_MEMORIES_UPLOAD_START: {
      return {
        ...state,
        pendingUpload: true,
        progressUpload: 0,
        progressFileProcessing: 0,
        errorUpload: false,
        errorUploadMessage: '',
      }
    }
    case TRANSLATOR_MEMORIES_UPLOAD_UPDATING: {
      return { ...state, progressUpload: action.progressUpload }
    }
    case TRANSLATOR_MEMORIES_PROCESS_UPDATING: {
      return { ...state, progressFileProcessing: action.progressFileProcessing }
    }
    case TRANSLATOR_MEMORIES_UPLOAD_END: {
      return { ...state, pendingUpload: false }
    }
    case TRANSLATOR_MEMORIES_UPLOAD_FAIL: {
      return {
        ...state,
        pendingUpload: false,
        progressUpload: 0,
        progressFileProcessing: 0,
        errorUpload: true,
        errorUploadMessage: action.errorUploadMessage,
      }
    }
    case TRANSLATOR_MEMORIES_UPLOAD_CANCEL: {
      return { ...state, pendingUpload: false, progressUpload: 0, progressFileProcessing: 0 }
    }

    default:
      return state
  }
}
