//@flow
import { BILLING_PERIODS_FETCH, BILLING_PERIODS_SUCCESS, BILLING_PERIODS_FAIL } from '@Actions/BillingPeriods'

export const billingPeriodsReducer = (state: any, action: any) => {
  switch (action.type) {
    case BILLING_PERIODS_FETCH:
      return { ...state, pending: true, error: false, billingPeriods: [] }
    case BILLING_PERIODS_SUCCESS:
      return { ...state, pending: false, error: false, billingPeriods: action.billingPeriods }
    case BILLING_PERIODS_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
