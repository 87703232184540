// @flow
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
const queryString = require('query-string')
import LoginNewPasswordForm from '@Components/LoginNewPasswordForm'
import { UserLoginContext } from '@Contexts/UserLogin'
import { ObjectIsNotEmpty } from '@Services/validations'
import ROUTER_PATHS from '../routes_paths'

const LoginNewPasswordView = () => {
  const { t } = useTranslation()
  const { onUserRequestNewPassword } = useContext(UserLoginContext)
  const [token, setToken] = useState('')

  useEffect((): void => {
    if (ObjectIsNotEmpty(queryString.parse(location.search))) {
      const { token: tkn } = queryString.parse(location.search) || ''
      setToken(tkn)
    }
  }, [])
  const onSubmit = function(password): void {
    onUserRequestNewPassword(token, password, onSubmitFormSuccess)
  }

  const onSubmitFormSuccess = (): void => {
    location.href = ROUTER_PATHS.loginBaseUrl
  }

  return (
    <div className="login-form-view">
      <div className="login-form-view__wrap">
        <div className="login-form-view__item">
          <h2 className="u-margin-bottom-spacer-large">{t('pages.LoginResetPasswordView.title')}</h2>
        </div>
        <div className="login-form-view__item">
          <LoginNewPasswordForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

export default LoginNewPasswordView
