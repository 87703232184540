// @flow
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import MessageBox from '@Components/MessageBox'
import { useTranslation } from 'react-i18next'
import { PlanTypeContext } from '@Contexts/PlanType'
import LicenseKey from '@Components/LicenseKey'
import { UserContext } from '@Contexts/User'
import LicenseRegeneration from '@Components/LicenseRegeneration'
import { UpdateAccessTokenContext } from '@Contexts/UpdateAccessToken'
import type { Element } from 'react'

type Props = {
  onSubmit: Function,
  onCancel: Function,
  isInModal?: Boolean,
  licenseRegeneration?: Boolean,
  shouldShowSuccessMessageAfterNewKey?: Boolean,
}

const ModalRegenerateLicense = ({
  isInModal,
  onSubmit,
  licenseRegeneration,
  shouldShowSuccessMessageAfterNewKey,
  onCancel,
}: Props): Element<'div'> => {
  const { t } = useTranslation()
  const { solutionType } = useContext(PlanTypeContext)
  const { store } = useContext(UserContext)

  const { store: updateAccessTokenStore } = useContext(UpdateAccessTokenContext)

  return (
    <div className='modal-wrap'>
      <div className='modal-wrap__wrap'>
        <div className='modal-wrap__item modal-wrap__item--header'>
          <h5>
            {t(`components.ModalRegenerateLicense.title.${solutionType}`)}
          </h5>
          <i onClick={onCancel} className='icon icon-close' />
        </div>
        <div
          className={
            !shouldShowSuccessMessageAfterNewKey
              ? 'modal-wrap__item modal-wrap__item--body'
              : 'modal-wrap__item modal-wrap__item--body u-margin-top-spacer-base'
          }
        >
          {!shouldShowSuccessMessageAfterNewKey && (
            <p>{t('components.ModalRegenerateLicense.subTitle')}</p>
          )}

          <LicenseKey
            isInModal={isInModal}
            license={store.user.activationKey}
            isLoading={
              store.pending ||
              updateAccessTokenStore.pending ||
              store.fetchNotStarted
            }
          />

          {shouldShowSuccessMessageAfterNewKey ? (
            <MessageBox type='blank'>
              {t(
                `pages.DashboardMainView.messageAfterRegeneration.${solutionType}`
              )}
            </MessageBox>
          ) : !licenseRegeneration ? (
            <MessageBox>
              {t(
                `components.ModalRegenerateLicense.messageBox.${solutionType}`
              )}
            </MessageBox>
          ) : (
            <LicenseRegeneration type='big' />
          )}
        </div>
        <div className='modal-wrap__item modal-wrap__item--footer'>
          {!licenseRegeneration && shouldShowSuccessMessageAfterNewKey && (
              <a onClick={onCancel} className='button button--tertiary is-purple'>
                {t('components.ModalRegenerateLicense.buttonCloseLabel')}
              </a>
          )}
          {!licenseRegeneration && !shouldShowSuccessMessageAfterNewKey && (
            <>
              <a
                onClick={onSubmit}
                className='button button--primary is-purple'
              >
                {t('components.ModalRegenerateLicense.buttonPrimaryLabel')}
              </a>
              <a
                onClick={onCancel}
                className='button button--tertiary is-purple'
              >
                {t('components.ModalRegenerateLicense.buttonSecondaryLabel')}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ModalRegenerateLicense.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
}

ModalRegenerateLicense.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ModalRegenerateLicense
