// @flow
import { translatorTextReducer } from '@Reducers/TranslatorText'
import debounce from 'debounce-promise'
import { translate } from '@Services/api'
import createContext from './createContext'
import {
  TRANSLATOR_TEXT_TRANSLATION_FETCH,
  TRANSLATOR_TEXT_TRANSLATION_SUCCESS,
  TRANSLATOR_TEXT_TRANSLATION_FAIL,
} from '@Actions/TranslatorText'

import i18n from 'i18next'
import { get } from 'lodash'
import { noop } from '@Services/util'

const debouncedTranslate = debounce(translate, 800)

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  alreadyFetched: boolean,
  resultText: string,
  detectedLanguage?: string
}

const defaultStore: Store = {
  pending: false,
  error: false,
  alreadyFetched: false,
  errorMessage: '',
  resultText: '',
}

let shouldIgnoreResponse = false

const getTranslation = dispatch => (
  sourceText: string,
  sourceLanguage: string,
  targetLanguage: string,
  activeMemories: Array<string>,
  onComplete: Function = noop,
  onError: Function = noop
) => {
  shouldIgnoreResponse = false
  dispatch({ type: TRANSLATOR_TEXT_TRANSLATION_FETCH })
  debouncedTranslate(sourceText, sourceLanguage, targetLanguage, String(activeMemories))
    .then(response => {
      if (shouldIgnoreResponse) return
      dispatch({
        type: TRANSLATOR_TEXT_TRANSLATION_SUCCESS,
        resultText: response.translation || '',
        detectedLanguage: response.detectedLanguage
      })
      onComplete()
    })
    .catch(error => {
      const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
      dispatch({ type: TRANSLATOR_TEXT_TRANSLATION_FAIL, errorMessage })
      onError()
    })
}

const cancelTranslationRequest = dispatch => {
  return () => {
    shouldIgnoreResponse = true
    dispatch({ type: TRANSLATOR_TEXT_TRANSLATION_SUCCESS, resultText: '' })
  }
}

export const { Context, Provider } = createContext(
  translatorTextReducer,
  {
    getTranslation,
    cancelTranslationRequest,
  },
  defaultStore
)
