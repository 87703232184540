// @flow
import * as moment from 'moment'
import ROUTER_PATHS from '../routes_paths'

export function copyStringToClipboard(str: string = '', toUpperCase: boolean = true): void {
  var el = document.createElement('textarea')
  el.value = toUpperCase ? str.toUpperCase() : str
  el.setAttribute('readonly', '')
  //$FlowFixMe
  el.style = { position: 'absolute', left: '-9999px' }
  //$FlowFixMe
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  //$FlowFixMe
  el.setSelectionRange(0, 0)
  //$FlowFixMe
  document.body.removeChild(el)
}

export function addInputFile(acceptedFormat: string, onChange: Function): void {
  var el = document.createElement('input')
  const removeElementFromTheDom = () => {
    setTimeout(() => {
      if (el) {
        const element = document.querySelector('#addInputFile')
        //$FlowFixMe
        element && document.body.removeChild(element)
      }
    }, 0)
  }

  el.type = 'file'
  el.id = 'addInputFile'
  el.accept = acceptedFormat
  //$FlowFixMe
  el.classList = 'u-out'
  el.onchange = onChange
  //$FlowFixMe
  document.body.onfocus = removeElementFromTheDom // this remove element if user click cancel
  //$FlowFixMe
  document.body.appendChild(el)
  setTimeout(() => {
    el.click()
  }, 0)
}

export const delay = (time: number): Promise<any> => new Promise(resolve => setTimeout(resolve, time))
export const noop = () => {}

export function scrollToTop() {
  window.scrollTo(0, 0)
}

export function stripTrailingSlash(pathname: string): string {
  return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
}

export function importCountriesByLang(lang: string): any {
  try {
    // $FlowFixMe
    return require(`i18n-iso-countries/langs/${lang}.json`)
  } catch (error) {
    console.log('Language is invalid', error)
  }
}

export const cardElementStyle = {
  fontSize: '16px',
  fontFamily: 'Poppins, Helvetica, sans-serif',
  fontSmoothing: 'antialiased',
  color: '#000011',
  '::placeholder': {
    color: '#96919E',
  },
}

export const getPercent = (chars: number, maxValue: number): number => Math.round((chars / maxValue) * 100)

export const interpolate = (
  minInput: number,
  maxInput: number,
  minInterpolated: number,
  maxInterpolated: number,
  input: number
): number => {
  const deltaInput = maxInput - minInput
  const deltaInterpolated = maxInterpolated - minInterpolated
  return (deltaInterpolated / deltaInput) * input + minInterpolated
}

export const calculateUsagePercentCurrentMonth = (totalOfPastMonth: number, totalCountUntilToday: number) => {
  const thisMonth = moment.utc().startOf('month');

  const elapsed = moment.utc().diff(thisMonth, 'seconds');
  const total = moment.utc().endOf('month').diff(thisMonth, 'seconds');
  const expectedMonthlyChars = totalCountUntilToday * total / elapsed;

  const result = (expectedMonthlyChars - totalOfPastMonth) / totalOfPastMonth;

  return isFinite(result) ? (Math.round(result * 100) / 100) * 100 : 100
}

export const mapInputToOpacity = (
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number,
  input: number
): number => {
  return inputMin !== inputMax ? mapInputToRange(inputMin, inputMax, outputMin, outputMax, input) : 100
}
export const mapInputToRange = (
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number,
  input: number
): number => {
  return outputMin + ((outputMax - outputMin) / (inputMax - inputMin)) * (input - inputMin)
}

export const numberFormatter = (input: any): string => {
  const SI_POSTFIXES = ['', 'K', 'M', 'G', 'T', 'P', 'E']
  // const normalizedInput = input.replace(/,/g, '')
  const number = parseFloat(input)
  // what tier? (determines SI prefix)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0

  // if zero, we don't need a prefix
  if (tier === 0) {
    return `${number}`
  }
  // get postfix and determine scale
  var postfix = SI_POSTFIXES[tier]
  var scale = Math.pow(10, tier * 3)

  // scale the number
  var scaled = number / scale

  // format number and add postfix as suffix
  var formatted = scaled.toFixed(1) + ''

  // remove '.0' case
  if (/\.0$/.test(formatted)) formatted = formatted.substr(0, formatted.length - 2)

  return formatted + postfix
}

export const routeToLoginWithEncodedUrl = (url: string): string => {
  const encodedUrl = encodeURIComponent(url)
  return `${ROUTER_PATHS.loginBaseUrl}?url=${encodedUrl}`
}
