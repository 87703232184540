//@flow
import {
  UPDATE_USER_INFORMATIONS_FETCH,
  UPDATE_USER_INFORMATIONS_SUCCESS,
  UPDATE_USER_INFORMATIONS_FAIL,
} from '@Actions/UserUpdate'

export const updateUserInformationsReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_USER_INFORMATIONS_FETCH:
      return { ...state, pending: true, error: false, billingPeriods: [] }
    case UPDATE_USER_INFORMATIONS_SUCCESS:
      return { ...state, pending: false, error: false, updatedUserInformations: action.updatedUserInformations }
    case UPDATE_USER_INFORMATIONS_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
