// flow
import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { noop } from '@Services/util'

type NavigationItems = {
  label: string,
  icon: string,
  path: string,
  onClick?: Function,
}
type Props = {
  items: Array<NavigationItems>,
}
const DashboardNavigation = ({ items }: Props): Function => {
  return (
    items.length && (
      <div className="dashboard-navigation">
        <ul>
          {items.map((item, key) => {
            return (
              <li key={key}>
                <NavLink exact to={item.path} onClick={item.onClick}>
                  <i className={`icon-${item.icon}`}></i>
                  <span>{item.label}</span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    )
  )
}

DashboardNavigation.defaultProps = {
  items: [
    {
      label: '',
      icon: '',
      path: '',
      onClick: noop,
    },
  ],
}

DashboardNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      path: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
}

export default DashboardNavigation
