//@flow
import { USER_REQUEST_FETCH, USER_REQUEST_SUCCESS, USER_REQUEST_FAIL, USER_REQUEST_LOGOUT } from '@Actions/User'

export const getUserReducer = (state: any, action: any) => {
  switch (action.type) {
    case USER_REQUEST_FETCH:
      return { ...state, pending: true, error: false, fetchNotStarted: true }
    case USER_REQUEST_SUCCESS:
      return { ...state, user: action.user, pending: false, error: false, fetchNotStarted: false }
    case USER_REQUEST_FAIL:
      return { ...state, user: {}, pending: false, error: true, errorMessage: action.errorMessage }
    case USER_REQUEST_LOGOUT:
      return { ...state, user: {}, pending: false, error: false, errorMessage: '' }

    default:
      return state
  }
}
