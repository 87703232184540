// @flow
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import TranslatorTextArea from '@Components/TranslatorTextArea'
import { Context as TranslatorTextContext } from '@Contexts/TranslatorText'
import { useTranslation } from 'react-i18next'

type Props = {
  setSourceText: Function,
  onSwapLanguages: Function,
  onLeftBoxButtonPress?: Function,
  onRightBoxButtonPress?: Function,
  onLeftBoxButtonPressLabel?: string,
  onRightBoxButtonPressLabel?: string,
  sourceLanguage: string,
  targetLanguage: string,
  sourceText: string,
  shouldSwapLanguage: boolean,
}
const TranslatorTextAreaContainer = ({
  setSourceText,
  sourceText,
  onSwapLanguages,
  sourceLanguage,
  targetLanguage,
  onLeftBoxButtonPress,
  onRightBoxButtonPress,
  onLeftBoxButtonPressLabel,
  onRightBoxButtonPressLabel,
  shouldSwapLanguage,
}: Props) => {
  const { t } = useTranslation()
  const { store: translatorTextStore } = useContext(TranslatorTextContext)
  const [sourceTextIsInFocus, setSourceTextIsInFocus] = useState(false)
  const [swapping, setSwapping] = useState(false)
  const [resultTextIsInFocus] = useState(false)

  const onChangeSourceTextValue = (sourceText: string): void => {
    if (sourceText.length <= 5000) {
      setSourceText(sourceText)
    }
  }
  const onCleanTextValue = (): void => {
    setSourceText('')
  }
  const onSwappingLanguage = () => {
    onSwapLanguages()
    setSwapping(!swapping)
  }

  const onButtonPressOnLeftBox = (): void => {
    onLeftBoxButtonPress && onLeftBoxButtonPress()
  }
  const onButtonPressOnRightBox = (): void => {
    onRightBoxButtonPress && onRightBoxButtonPress()
  }

  return (
    <div className="translator-text-area-container">
      <div className="translator-text-area-container__wrap">
        <div
          className={`translator-text-area-container__item translator-text-area-container__item--source ${
            sourceTextIsInFocus ? 'is-in-focus' : ''
          }`}
        >
          <TranslatorTextArea
            isInFocus={setSourceTextIsInFocus}
            onChangeTextValue={onChangeSourceTextValue}
            onCleanTextValue={onCleanTextValue}
            textValue={sourceText}
            textValuePlaceholder={t('components.TranslatorTextAreaContainer.placeholderSourceText')}
            shouldShowChartCount
            onButtonPress={onButtonPressOnLeftBox}
            onButtonPressLabel={onLeftBoxButtonPressLabel}
            selectedLanguage={sourceLanguage}
          />
        </div>
        <div
          className={`translator-text-area-container__item translator-text-area-container__item--result ${
            resultTextIsInFocus ? 'is-in-focus' : ''
          }`}
        >
          <TranslatorTextArea
            textValuePlaceholder={
              translatorTextStore.pending && !translatorTextStore.resultText.length
                ? t('components.TranslatorTextAreaContainer.placeholderResultTextPending')
                : t('components.TranslatorTextAreaContainer.placeholderResultText')
            }
            textValue={translatorTextStore.resultText}
            shouldShowCopyInClipboard
            shouldShowLoadingState
            onButtonPress={onButtonPressOnRightBox}
            onButtonPressLabel={onRightBoxButtonPressLabel}
            isLoading={translatorTextStore.pending}
            selectedLanguage={targetLanguage}
          />
        </div>
        <div
          onClick={onSwappingLanguage}
          className={`translator-text-area-container__item translator-text-area-container__item--icon ${
            swapping ? 'is-swapping' : ''
          } ${!shouldSwapLanguage ? 'is-disabled' : ''}`}
        >
          <span>
            <i className="icon icon-swap"/>
          </span>
        </div>
      </div>
    </div>
  )
}

TranslatorTextAreaContainer.defaultProps = {
  onSwapLanguages: noop,
  setSourceText: noop,
  sourceText: '',
  onUploadDocument: noop,
  sourceLanguage: '',
  targetLanguage: '',
  shouldSwapLanguage: false,
  onLeftBoxButtonPress: noop,
  onRightBoxButtonPress: noop,
}

TranslatorTextAreaContainer.propTypes = {
  onSwapLanguages: PropTypes.func,
  setSourceText: PropTypes.func,
  sourceText: PropTypes.string,
  sourceLanguage: PropTypes.string,
  targetLanguage: PropTypes.string,
  shouldSwapLanguage: PropTypes.bool,
  onLeftBoxButtonPress: PropTypes.func,
  onRightBoxButtonPress: PropTypes.func,
}

export default TranslatorTextAreaContainer
