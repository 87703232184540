// flow
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import { useTranslation } from 'react-i18next';
import LogoWhite from '@Components/LogoWhite';
import ROUTES_PATHS from '../routes_paths';
import { getUserLoggedOut } from '@Services/localstorage';
import DashboardNavigation from '@Components/DashboardNavigation';
import { noop } from '@Services/util';
import { UserContext } from '@Contexts/User';

type Props = {
  shouldShowOverlay: Function,
};

const DashboardSidebar = ({ shouldShowOverlay }: Props): Element<'div'> => {
  const { t } = useTranslation();
  const { onUserLoggingOut } = useContext(UserContext);
  const onUserLogOut = () => {
    onUserLoggingOut();
    getUserLoggedOut();
  };

  const buttons = [
    {
      label: t('components.DashboardNavigation.dashboardMainLabel'),
      path: ROUTES_PATHS.dashboardBaseUrl,
      icon: 'chart',
      onClick: shouldShowOverlay,
    },
    {
      label: t('components.DashboardNavigation.dashboardManagePlanLabel'),
      path: ROUTES_PATHS.dashboardBillingUrl,
      icon: 'credit-card',
      onClick: shouldShowOverlay,
    },
    {
      label: t('components.DashboardNavigation.dashboardAccountSettingsLabel'),
      path: ROUTES_PATHS.dashboardAccountSettingsUrl,
      icon: 'settings',
      onClick: shouldShowOverlay,
    },
  ];

  return (
    <div className="dashboard-sidebar">
      <div className="dashboard-sidebar__wrap">
        <div className="dashboard-sidebar__item">
          <div className="dashboard-content">
            <div className="dashboard-content__item dashboard-content__item--logo">
              <a href="/">
                <LogoWhite />
              </a>
            </div>
            <div className="dashbord-content__item">
              <DashboardNavigation items={buttons} />
            </div>
          </div>
        </div>
        <div className="dashboard-sidebar__item">
          <DashboardNavigation
            items={[
              {
                label: 'Translate',
                path: ROUTES_PATHS.translatorBaseUrl,
                icon: 'translate',
              },
              // { label: 'Documentation', path: '/api', icon: 'documentation' },
              {
                label: t('components.DashboardNavigation.dashboardLogoutLabel'),
                path: ROUTES_PATHS.loginBaseUrl,
                icon: 'logout',
                onClick: onUserLogOut,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

DashboardSidebar.defaultProps = {
  shouldShowOverlay: noop,
};

DashboardSidebar.propTypes = {
  shouldShowOverlay: PropTypes.func,
};

export default DashboardSidebar;
