//@flow
import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { userLoginReducer, userResetPasswordReducer, userNewPasswordReducer } from '@Reducers/UserLogin'
import { noop } from '@Services/util'
import {
  USER_LOGIN_REQUEST_FETCH,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_FAIL,
  USER_RESET_PASSWORD_REQUEST_FETCH,
  USER_RESET_PASSWORD_REQUEST_SUCCESS,
  USER_RESET_PASSWORD_REQUEST_FAIL,
  USER_NEW_PASSWORD_REQUEST_FETCH,
  USER_NEW_PASSWORD_REQUEST_SUCCESS,
  USER_NEW_PASSWORD_REQUEST_FAIL,
} from '@Actions/UserLogin'
import { userLogin, userRequestResetPassword, userRequestNewPassword } from '@Services/api'
import { setUserTokenOnLocalstorage } from '@Services/localstorage'
import { get } from 'lodash'
type Context = {
  onUserLogin: Function,
  onUserResetPassword: Function,
  onUserRequestNewPassword: Function,
  userLoginStore: Store,
  userResetPasswordStore: Store,
  userNewPasswordStore: Store,
}

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  passwordHasBeenReset?: boolean,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
}

const UserLoginContext = createContext<Context>({})

const UserLoginProvider = ({ children }: any): any => {
  const [userLoginStore, dispatchUserLogin] = useReducer(userLoginReducer, defaultStore)
  const [userResetPasswordStore, dispatchUserResetPassword] = useReducer(userResetPasswordReducer, defaultStore)
  const [userNewPasswordStore, dispatchUserNewPasswordStore] = useReducer(userNewPasswordReducer, {
    ...defaultStore,
    passwordHasBeenReset: false,
  })

  const onUserLogin = (
    email: string,
    password: string,
    onComplete: Function = noop,
    onError: Function = noop
  ): Promise<any> => {
    dispatchUserLogin({ type: USER_LOGIN_REQUEST_FETCH })
    return userLogin({ email, password })
      .then(({ token }) => {
        dispatchUserLogin({ type: USER_LOGIN_REQUEST_SUCCESS })
        setUserTokenOnLocalstorage(token)
        return onComplete(token)
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatchUserLogin({ type: USER_LOGIN_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

  const onUserResetPassword = function(email: string, onComplete: Function = noop, onError: Function = noop): any {
    dispatchUserResetPassword({ type: USER_RESET_PASSWORD_REQUEST_FETCH })
    userRequestResetPassword(email)
      .then(() => {
        dispatchUserResetPassword({ type: USER_RESET_PASSWORD_REQUEST_SUCCESS })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatchUserResetPassword({ type: USER_RESET_PASSWORD_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

  const onUserRequestNewPassword = function(
    token: string,
    password: string,
    onComplete: Function = noop,
    onError: Function = noop
  ): any {
    dispatchUserNewPasswordStore({ type: USER_NEW_PASSWORD_REQUEST_FETCH })
    userRequestNewPassword(token, password)
      .then(() => {
        dispatchUserNewPasswordStore({ type: USER_NEW_PASSWORD_REQUEST_SUCCESS })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatchUserNewPasswordStore({ type: USER_NEW_PASSWORD_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

  return (
    <UserLoginContext.Provider
      value={{
        onUserLogin,
        onUserResetPassword,
        userLoginStore,
        userResetPasswordStore,
        onUserRequestNewPassword,
        userNewPasswordStore,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  )
}

UserLoginProvider.defaultProps = {
  children: {},
}

UserLoginProvider.propTypes = {
  children: PropTypes.object,
}

export { UserLoginContext, UserLoginProvider }
