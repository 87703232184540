// @flow
import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'

type ImageType = {
  imageUrl: string,
  imageRetinaUrl: string,
  alt: string,
}

const Image = ({ imageUrl, imageRetinaUrl, alt }: ImageType): Element<'img'> => {
  return imageRetinaUrl ? (
    <img src={imageUrl} srcSet={`${imageUrl} 1x, ${imageRetinaUrl} 2x`} alt={alt} />
  ) : (
    <img src={imageUrl} />
  )
}

Image.defaultProps = {
  imageUrl: '',
  imageRetinaUrl: '',
  alt: '',
}

Image.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageRetinaUrl: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
