// flow
import React, { useContext } from 'react'
import type { Element } from 'react'
import Avatar from '@Components/Avatar'
import ListWithThick from '@Components/ListWithThick'
import { useTranslation } from 'react-i18next'
import { PlanTypeContext } from '@Contexts/PlanType'
import { useRouteMatch } from 'react-router-dom'
import ROUTES_PATHS from '../routes_paths'
import { UserContext } from '@Contexts/User'
import SkeletonLoader from '@Components/SkeletonLoader'
const SignupSidebar = (): Element<'div'> => {
  const { t } = useTranslation()
  const { planType, solutionType } = useContext(PlanTypeContext)
  const match = useRouteMatch(ROUTES_PATHS.signupThankYou)
  const match_bill = useRouteMatch(ROUTES_PATHS.signupBill)
  const {
    store: { pending },
  } = useContext(UserContext)

  return (
    <div className="signup-sidebar">
      <div className="signup-sidebar__wrap">
        <div className="signup-sidebar__item signup-sidebar__item--header">
          <p>{t('components.SignupSidebar.headerPlanLabel')}</p>
          <div className="signup-sidebar-content-row">
            {pending ? (
              <SkeletonLoader type="is-yellow-50">
                <h5>{t(`components.SignupSidebar.plansLabels.${planType}`)}</h5>
              </SkeletonLoader>
            ) : (
              <h5>{t(`components.SignupSidebar.plansLabels.${planType}`)}</h5>
            )}
            {!match && !match_bill && (
              <a href="/pricing" className="is-bold is-purple">
                {t('components.SignupSidebar.ctaPlanLabel')}
              </a>
            )}
          </div>
        </div>
        <div className="signup-sidebar__item signup-sidebar__item--body">
          <div className="signup-sidebar-list-thik">
            <ListWithThick
              pending={pending}
              skeletonColor="is-yellow-50"
              list={t(
                `components.SignupSidebar.listOfPlanFeature.${planType}`,
                {
                  returnObjects: true,
                }
              )}
            />
          </div>
          <div className="signup-sidebar-content-row signup-sidebar-content-row--total">
            {pending ? (
              <SkeletonLoader type="is-yellow-50">
                <p>
                  {t(`components.SignupSidebar.totalLabel.${solutionType}`)}
                </p>
              </SkeletonLoader>
            ) : (
              <p>{t(`components.SignupSidebar.totalLabel.${solutionType}`)}</p>
            )}
            {pending ? (
              <SkeletonLoader type="is-yellow-50">
                <p>
                  {t(`components.SignupSidebar.priceLabel.${planType}`)}{' '}
                  <span>
                    {t(`components.SignupSidebar.totalValueLabel.${planType}`)}
                  </span>
                </p>
              </SkeletonLoader>
            ) : (
              <p>
                {t(`components.SignupSidebar.priceLabel.${planType}`)}{' '}
                <span>
                  {t(`components.SignupSidebar.totalValueLabel.${planType}`)}
                </span>
              </p>
            )}
          </div>
          <div className="signup-sidebar-content-row signup-sidebar-content-row--price">
            <strong>{t('components.SignupSidebar.dueLabel')}</strong>
            <strong>{t('components.SignupSidebar.dueValueLabel')}</strong>
          </div>
        </div>
        <div className="signup-sidebar__item signup-sidebar__item--footer">
          <div className="signup-sidebar-quote">
            <Avatar
              isLoading={pending}
              imageUrl={t(
                `components.SignupSidebar.feedback.${solutionType}.feedbackUserPicture`
              )}
              imageRetinaUrl={t(
                `components.SignupSidebar.feedback.${solutionType}.feedbackUserPictureRetina`
              )}
            />
            <blockquote className="signup-sidebar-quote-content">
              <div className="signup-sidebar-quote-content__wrap">
                <div className="signup-sidebar-quote-content__item">
                  {pending ? (
                    <SkeletonLoader type="is-yellow-50" fullHeight>
                      <p>
                        {t(
                          `components.SignupSidebar.feedback.${solutionType}.feedbackText`
                        )}
                      </p>
                    </SkeletonLoader>
                  ) : (
                    <p>
                      {t(
                        `components.SignupSidebar.feedback.${solutionType}.feedbackText`
                      )}
                    </p>
                  )}
                </div>
                <div className="signup-sidebar-quote-content__item">
                  {pending ? (
                    <SkeletonLoader type="is-yellow-50">
                      <strong>
                        {t(
                          `components.SignupSidebar.feedback.${solutionType}.feedbackUserName`
                        )}
                      </strong>
                    </SkeletonLoader>
                  ) : (
                    <strong>
                      {t(
                        `components.SignupSidebar.feedback.${solutionType}.feedbackUserName`
                      )}
                    </strong>
                  )}
                  {pending ? (
                    <SkeletonLoader type="is-yellow-50">
                      <small>
                        {t(
                          `components.SignupSidebar.feedback.${solutionType}.feedbackUserRole`
                        )}
                      </small>
                    </SkeletonLoader>
                  ) : (
                    <small>
                      {t(
                        `components.SignupSidebar.feedback.${solutionType}.feedbackUserRole`
                      )}
                    </small>
                  )}
                </div>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupSidebar
