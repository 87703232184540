// @flow
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import { noop } from '@Services/util'
import { useTranslation } from 'react-i18next'
import MessageBox from '@Components/MessageBox'
import { UserLoginContext } from '@Contexts/UserLogin'

type Props = {
  onSubmit: Function,
}

const LoginNewPasswordForm = ({ onSubmit }: Props): Function => {
  const { register, errors, handleSubmit } = useForm()
  const { t } = useTranslation()
  const { userNewPasswordStore: store } = useContext(UserLoginContext)
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const onSubmitForm = function (formData: Object): void {
    const { newPassword, repeatPassword } = formData
    if (newPassword === repeatPassword) {
      setPasswordMismatch(false)
      onSubmit(repeatPassword)
    } else {
      setPasswordMismatch(true)
    }
  }

  const shouldShowMessageBox = function (): boolean {
    const { pending, error, errorMessage } = store
    return !pending && error && errorMessage.length > 0
  }

  return (
    <>
      {shouldShowMessageBox() && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox>
            <p>{store.errorMessage}</p>
          </MessageBox>
        </div>
      )}
      {passwordMismatch && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox>
            <p>{t('components.LoginNewPasswordForm.errorPasswordMismatch')}</p>
          </MessageBox>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className='input-container'>
          <div
            className={`input-container__item input-container__item--forgot ${
              errors.newPassword && 'has-error'
            }`}
          >
            <label>
              {t('components.LoginNewPasswordForm.newPasswordLabel')}
            </label>
            <input
              type='password'
              autoComplete='new-password'
              placeholder={t(
                'components.LoginNewPasswordForm.newPasswordPlaceholder'
              )}
              name='newPassword'
              ref={register({
                required: t('components.LoginNewPasswordForm.newPasswordEmpty'),
                minLength: {
                  value: 8,
                  message: t(
                    'components.LoginNewPasswordForm.newPasswordError'
                  ),
                },
              })}
            />
            {errors.newPassword && <small>{errors.newPassword.message}</small>}
          </div>
        </div>
        <div className='input-container'>
          <div
            className={`input-container__item input-container__item--forgot ${
              errors.repeatPassword && 'has-error'
            }`}
          >
            <label>
              {t('components.LoginNewPasswordForm.repeatNewPasswordLabel')}
            </label>
            <input
              type='password'
              autoComplete='new-password'
              placeholder={t(
                'components.LoginNewPasswordForm.repeatNewPasswordPlaceholder'
              )}
              name='repeatPassword'
              ref={register({
                required: t(
                  'components.LoginNewPasswordForm.repeatNewPasswordEmpty'
                ),
                minLength: {
                  value: 8,
                  message: t(
                    'components.LoginNewPasswordForm.repeatNewPasswordError'
                  ),
                },
              })}
            />

            {errors.repeatPassword && (
              <small>{errors.repeatPassword.message}</small>
            )}
          </div>
        </div>
        <div className='input-container is-full'>
          <div className='input-container__item'>
            <button
              className={`button button--primary is-purple ${
                store.pending ? 'is-loading' : ''
              }`}
            >
              {store.pending ? (
                <span/>
              ) : (
                t('components.LoginNewPasswordForm.buttonLabel')
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

LoginNewPasswordForm.defaultProps = {
  onSubmit: noop,
}

LoginNewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginNewPasswordForm
