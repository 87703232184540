// @flow
import React from 'react'
import Header from '@Components/Header'
import ROUTES_PATHS from '../routes_paths'
import { Switch, Route } from 'react-router-dom'
import LoginFormView from '@Views/LoginFormView'
import LoginResetPasswordView from '@Views/LoginResetPasswordView'
import LoginNewPasswordView from '@Views/LoginNewPasswordView'
import HeaderSimpleNavigation from '@Components/HeaderSimpleNavigation'
import { UserLoginProvider } from '@Contexts/UserLogin'
import { UserProvider } from '@Contexts/User'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const LoginView = () => {
  const { t } = useTranslation()
  const onHeaderCtaClick = function() {
    window.location.href = '/pricing'
  }
  return (
    <>
      <Helmet>
        <title>{t('pages.LoginView.pageTitle')}</title>
      </Helmet>
      <UserProvider>
        <UserLoginProvider>
          <div className="layout">
            <div className="layout__wrap">
              <div className="layout__item layout__item--header">
                <Header
                  DesktopNavigation={() => (
                    <HeaderSimpleNavigation
                      label={t('pages.LoginView.Header.ctaDescription')}
                      onPressPrimaryButton={onHeaderCtaClick}
                      primaryButtonLabel={t('pages.LoginView.Header.ctaLabel')}
                    />
                  )}
                />
              </div>
              <div className="layout__item layout__item--full">
                <div className="layout-container">
                  <Switch>
                    <Route exact path={ROUTES_PATHS.loginBaseUrl} component={LoginFormView} />
                    <Route exact path={ROUTES_PATHS.loginResetPasswordUrl} component={LoginResetPasswordView} />
                    <Route exact path={ROUTES_PATHS.loginNewPasswordUrl} component={LoginNewPasswordView} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </UserLoginProvider>
      </UserProvider>
    </>
  )
}

export default LoginView
