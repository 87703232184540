// flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './lang';
import LoginView from '@Views/LoginView';
import SignupView from '@Views/SignupView';
import DashboardView from '@Views/DashboardView';
import InvoiceView from '@Views/InvoiceView';
import TranslatorView from '@Views/TranslatorView';
import ScrollToTop from '@Components/ScrollToTop';
import CheckIfUserIsLogged from '@Components/CheckIfUserIsLogged';
import ROUTES_PATHS from './routes_paths';
import PageNotFoundView from '@Views/PageNotFoundView';
const rootElement = document.getElementById('root');
import { UserProvider } from '@Contexts/User';
import { PlanTypeProvider } from '@Contexts/PlanType';
import { InvoiceProvider } from '@Contexts/Invoice';
import { TranslatorMemoriesProvider } from '@Contexts/TranslatorMemories';
import { Provider as TranslatorTextProvider } from '@Contexts/TranslatorText';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
Modal.setAppElement('#modalWrapper');
import { StripeProvider } from 'react-stripe-hooks';

const tagManagerArgs = {
  gtmId: process.env.GTM_KEY,
};

TagManager.initialize(tagManagerArgs);
const App = () => {
  return (
    <Router>
      <StripeProvider apiKey={process.env.STRIPE_KEY}>
        <PlanTypeProvider>
          <UserProvider>
            <TranslatorMemoriesProvider>
              <TranslatorTextProvider>
                <InvoiceProvider>
                  <ScrollToTop />
                  <CheckIfUserIsLogged />
                  <Switch>
                    <Route
                      path={ROUTES_PATHS.loginBaseUrl}
                      component={LoginView}
                    />
                    <Route
                      path={ROUTES_PATHS.signupBaseUrl}
                      component={SignupView}
                    />
                    <Route
                      path={ROUTES_PATHS.dashboardBaseUrl}
                      component={DashboardView}
                    />
                    <Route
                      path={ROUTES_PATHS.invoiceUrl}
                      component={InvoiceView}
                    />
                    <Route
                      path={ROUTES_PATHS.translatorBaseUrl}
                      component={TranslatorView}
                    />
                    <Route component={PageNotFoundView} />
                  </Switch>
                </InvoiceProvider>
              </TranslatorTextProvider>
            </TranslatorMemoriesProvider>
          </UserProvider>
        </PlanTypeProvider>
      </StripeProvider>
    </Router>
  );
};

if (rootElement) {
  ReactDOM.render(<App />, rootElement);
}
