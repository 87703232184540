// @flow
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import { noop } from '@Services/util'
import { isEmailValid } from '@Services/validations'
import { useTranslation } from 'react-i18next'
import MessageBox from '@Components/MessageBox'
import { UserLoginContext } from '@Contexts/UserLogin'

type Props = {
  onSubmit: Function,
}

const LoginResetPasswordForm = ({ onSubmit }: Props): Function => {
  const { register, errors, handleSubmit } = useForm()
  const { t } = useTranslation()
  const { userResetPasswordStore: store } = useContext(UserLoginContext)

  const onSubmitForm = function(formData: Object): void {
    onSubmit(formData)
  }

  const shouldShowMessageBox = function(): boolean {
    const { pending, error, errorMessage } = store
    return !pending && error && errorMessage.length > 0
  }

  return (
    <>
      {shouldShowMessageBox() && (
        <div className="u-margin-top-spacer-mid u-margin-bottom-spacer-mid">
          <MessageBox>
            <p>{store.errorMessage}</p>
          </MessageBox>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className="input-container">
          <div className={`input-container__item ${errors.email && 'has-error'}`}>
            <label>{t('components.LoginResetPasswordForm.emailLabel')}</label>
            <input
              type="email"
              placeholder={t('components.LoginResetPasswordForm.emailPlaceholder')}
              name="email"
              autoComplete="username"
              ref={register({
                required: t('components.LoginResetPasswordForm.emailEmpty'),
                validate: {
                  value: value => isEmailValid(value) || t('components.LoginResetPasswordForm.emailError'),
                },
              })}
            />
            {errors.email && <small>{errors.email.message}</small>}
          </div>
        </div>
        <div className="input-container is-full">
          <div className="input-container__item">
            <button className={`button button--primary is-purple ${store.pending ? 'is-loading' : ''}`}>
              {store.pending ? (
                <>
                  <span></span>
                  <p>{t('components.LoginResetPasswordForm.buttonLabel')}</p>
                </>
              ) : (
                t('components.LoginResetPasswordForm.buttonLabel')
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

LoginResetPasswordForm.defaultProps = {
  onSubmit: noop,
}

LoginResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginResetPasswordForm
