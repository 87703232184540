// @flow
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ROUTES_PATHS from '../routes_paths'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SignupBillForm from '@Components/SignupBillForm'
import { PlanTypeContext } from '@Contexts/PlanType'
import { BillingInformationContext } from '@Contexts/BillingInformation'
import { noop } from '@Services/util'
import { UserContext } from '@Contexts/User'
const queryString = require('query-string')
import { get } from 'lodash'
type Props = {
  setShouldHideChangePlanCta: Function,
}

const SignUpBillView = ({ setShouldHideChangePlanCta }: Props) => {
  const { onUpdateBillingInformation } = useContext(BillingInformationContext)
  const { signupData } = useContext(UserContext)
  const { solutionType } = useContext(PlanTypeContext)
  const { t } = useTranslation()
  const history = useHistory()

  const goToThankYouPage = (): void => {
    const query = queryString.parse(location.search)
    const plan = get(query, 'plan', '')
    plan
      ? history.push(`${ROUTES_PATHS.signupThankYou}?plan=${plan}`)
      : history.push(ROUTES_PATHS.signupThankYou)
  }

  const onSubmit = (billingInformations: Object): void => {
    onUpdateBillingInformation(billingInformations, goToThankYouPage)
  }

  useEffect(() => {
    setShouldHideChangePlanCta(true)
  }, [])
  return (
    <div className='signup-form-view'>
      <div className='signup-form-view__wrap'>
        <div className='signup-form-view__item'>
          <h3 className='u-margin-bottom-spacer-small'>
            {t(`pages.SignupBillView.title.${solutionType}`, {
              value:
                signupData &&
                signupData?.name &&
                signupData?.name.split(' ')[0],
            })}
          </h3>
          <SignupBillForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

SignUpBillView.defaultProps = {
  setShouldHideChangePlanCta: noop,
}

SignUpBillView.propTypes = {
  setShouldHideChangePlanCta: PropTypes.func.isRequired,
}

export default SignUpBillView
