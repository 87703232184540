import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { noop } from '@Services/util'
import SkeletonLoader from '@Components/SkeletonLoader'
import TranslatorActionsBar from '@Components/TranslatorActionsBar'
import type { TranslatorActionType } from '@Types/Translator'
import { useTranslation } from 'react-i18next'

type Props = {
  id: string,
  title: string,
  isLoading: boolean,
  shouldShowAction: boolean,
  shouldDisableActionBar: boolean,
  onToggleChange: Function,
  isActive: boolean,
  actions: Array<TranslatorActionType>,
  contextMenu: Array<TranslatorActionType>,
}
const TranslatorListItem = ({
  id,
  title,
  isLoading,
  shouldShowAction,
  shouldDisableActionBar,
  onToggleChange,
  actions,
  isActive,
  contextMenu,
}: Props) => {
  const onChange = e => {
    onToggleChange(e.target.checked, id)
  }
  const { t } = useTranslation()

  return (
    <div className="translator-list-item">
      <div className="translator-list-item__wrap">
        {!isLoading ? (
          <>
            <div className="translator-list-item__item translator-list-item__item--title ">
              {!shouldShowAction && (
                <div className="u-is-desktop">
                  <div className="translator-list-item-toggle">
                    <Toggle id={id} checked={isActive} aria-labelledby="memory-status" onChange={onChange} />
                  </div>
                </div>
              )}
              <p title={"ID: " + id}>{title}</p>
            </div>
            <div className="translator-list-item__item translator-list-item__item--actions">
              {!shouldShowAction && (
                <>
                  <div className="u-is-mobile">
                    <div className="translator-list-item-toggle ">
                      <Toggle id={id} checked={isActive} aria-labelledby="memory-status" onChange={onChange} />
                    </div>
                  </div>
                  <div className={`translator-list-item-actions ${shouldDisableActionBar ? 'is-disabled' : ''}`}>
                    <TranslatorActionsBar actions={actions} contextMenu={contextMenu} />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="translator-list-item__item translator-list-item__item--full">
            <SkeletonLoader>
              <p>{t('globals.loadingState')}</p>
            </SkeletonLoader>
          </div>
        )}
      </div>
    </div>
  )
}

TranslatorListItem.defaultProps = {
  id: '',
  isLoading: false,
  shouldShowAction: false,
  actions: [],
  contextMenu: [],
  shouldDisableActionBar: false,
  title: '',
  onToggleChange: noop,
  isActive: false,
}

TranslatorListItem.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  shouldShowAction: PropTypes.bool,
  shouldDisableActionBar: PropTypes.bool,
  actions: PropTypes.array,
  contextMenu: PropTypes.array,
  title: PropTypes.string,
  onToggleChange: PropTypes.func,
  isActive: PropTypes.bool,
}

export default TranslatorListItem
