// @flow
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import TranslatorLanguageSelector from '@Components/TranslatorLanguageSelector'
import SUPPORTED_LANGUAGES from '@Services/supportedLanguages'
import { useTranslation } from 'react-i18next'
import { Context as TranslatorTextContext } from '@Contexts/TranslatorText'

type Props = {
  onLanguageChange: Function,
  selectedLanguages: Object,
  onSwapLanguages: Function,
  shouldSwapLanguage: boolean,
}

const TranslatorLanguageSelectorContainer = ({
  onLanguageChange,
  selectedLanguages,
  onSwapLanguages,
  shouldSwapLanguage,
}: Props) => {
  const { t } = useTranslation()
  const [swapping, setSwapping] = useState(false)

  const { store: translatorTextStore } = useContext(TranslatorTextContext)

  const onSwappingLanguage = () => {
    onSwapLanguages()
    setSwapping(!swapping)
  }

  const onSourceLanguageChange = (source: string): void => {
    onLanguageChange({ source, target: selectedLanguages.target })
  }

  const onTargetLanguageChange = (target: string): void => {
    onLanguageChange({ source: selectedLanguages.source, target })
  }

  return (
    <div className="translator-language-selector-container">
      <div className="translator-language-selector-container__wrap">
        <div className="translator-language-selector-container__item">
          <TranslatorLanguageSelector
            onChange={onSourceLanguageChange}
            // shouldShowDefaultValue
            selectedLanguage={selectedLanguages.source}
            detectedLanguage={translatorTextStore.detectedLanguage}
            labelText={t('components.TranslatorLanguageSelectorContainer.sourceLanguageLabel')}
            languages={Object.assign({"": "Auto Detect"}, SUPPORTED_LANGUAGES)}
          />
        </div>

        <div className="translator-language-selector-container__item">
          <TranslatorLanguageSelector
            onChange={onTargetLanguageChange}
            labelText={t('components.TranslatorLanguageSelectorContainer.targetLanguageLabel')}
            selectedLanguage={selectedLanguages.target}
            languages={SUPPORTED_LANGUAGES}
          />
        </div>

        <div
          onClick={onSwappingLanguage}
          className={`translator-language-selector-container__item translator-language-selector-container__item--icon ${
            swapping ? 'is-swapping' : ''
          } ${!shouldSwapLanguage ? 'is-disabled' : ''}`}
        >
          <span>
            <i className="icon icon-swap"/>
          </span>
        </div>
      </div>
    </div>
  )
}

TranslatorLanguageSelectorContainer.defaultProps = {
  onLanguageChange: noop,
  onSwapLanguages: noop,
  selectedLanguages: {},
  shouldSwapLanguage: false,
}

TranslatorLanguageSelectorContainer.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
  onSwapLanguages: PropTypes.func.isRequired,
  selectedLanguages: PropTypes.object.isRequired,
  shouldSwapLanguage: PropTypes.bool,
}

export default TranslatorLanguageSelectorContainer
