// flow
import React from 'react'
import PropTypes from 'prop-types'
import type { Element } from 'react'
import { noop } from '@Services/util'

type Props = {
  onClose: Function,
  onButtonPress: Function,
  buttonLabel: string,
  title: string,
  description: string,
  isLoading: boolean,
}

const TranslatorModalAction = ({
  onButtonPress,
  onClose,
  title,
  description,
  buttonLabel,
  isLoading,
}: Props): Element<'div'> => {
  return (
    <div className="modal-wrap modal-wrap--center modal-wrap--input">
      <div className="modal-wrap__wrap">
        <div className="modal-wrap__item modal-wrap__item--header">
          <h5></h5>
          <i onClick={onClose} className="icon icon-close"></i>
        </div>
        <div className="modal-wrap__item modal-wrap__item--body">
          <h3>{title}</h3>
          <p>{description}</p>
          <a
            onClick={onButtonPress}
            className={`button button--primary u-margin-top-spacer-base is-red is-full ${
              isLoading ? 'is-loading' : ''
            }`}
          >
            {isLoading ? (
              <>
                <span></span>
                <p>{buttonLabel}</p>
              </>
            ) : (
              buttonLabel
            )}
          </a>
        </div>
      </div>
    </div>
  )
}

TranslatorModalAction.defaultProps = {
  onClose: noop,
  onButtonPress: noop,
  title: '',
  description: '',
  buttonLabel: '',
  isLoading: false,
}

TranslatorModalAction.propTypes = {
  onClose: PropTypes.func,
  onButtonPress: PropTypes.func,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default TranslatorModalAction
