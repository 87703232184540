// flow
import React from 'react';
import PropTypes from 'prop-types';
import LogoBlack from '@Components/LogoBlack';
import type { Element } from 'react';

type Props = {
  MobileNavigation: Function,
  DesktopNavigation: Function,
  Overlay: Function,
  shouldShowOverlay: boolean,
};

const Header = ({
  MobileNavigation,
  DesktopNavigation,
  Overlay,
  shouldShowOverlay,
}: Props): Element<'header'> => {
  return (
    <header className="header header--funnel">
      <div className="header__wrap">
        <div className="header__item header__item--brand">
          <a href="/">
            <LogoBlack />
          </a>
        </div>
        <div className="header__item u-is-mobile">
          <div className="header-navigation">
            <div className="header-navigation__wrap">
              <div className="header-navigation__item header-navigation__item--nav">
                <nav>
                  {MobileNavigation() ? (
                    <MobileNavigation />
                  ) : (
                    <DesktopNavigation />
                  )}
                </nav>
              </div>
              {shouldShowOverlay && (
                <div className="header-navigation__item header-navigation__item--overlay">
                  <div className="header-overlay">
                    <span></span>
                    <Overlay />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="header__item u-is-desktop">
          <DesktopNavigation />
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  MobileNavigation: () => null,
  DesktopNavigation: () => null,
  Overlay: () => null,
  shouldShowOverlay: false,
};

Header.propTypes = {
  MobileNavigation: PropTypes.func,
  DesktopNavigation: PropTypes.func,
  Overlay: PropTypes.func,
  shouldShowOverlay: PropTypes.bool,
};

export default Header;
