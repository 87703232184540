// @flow
import React from 'react'
import type { Element } from 'react'

const LicensePausing = ({ color }): Element<'div'> => {
  return (
    <div className={`loader-ring ${color ? 'is-' + color : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default LicensePausing
