// @flow
import createContext from './createContext'
import { billingPeriodsReducer } from '@Reducers/BillingPeriods'
import type { BillingPeriodsType } from '@Types/BillingPeriods'
import { getBillingPeriodsByDates } from '@Services/api'
import { BILLING_PERIODS_FETCH, BILLING_PERIODS_SUCCESS, BILLING_PERIODS_FAIL } from '@Actions/BillingPeriods'

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  billingPeriods: BillingPeriodsType,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
  billingPeriods: [],
}

const getBillingPeriods = dispatch => (from: string, to: string) => {
  dispatch({ type: BILLING_PERIODS_FETCH })
  getBillingPeriodsByDates(from, to)
    .then(response => {
      dispatch({ type: BILLING_PERIODS_SUCCESS, billingPeriods: response })
    })
    .catch(error => {
      dispatch({ type: BILLING_PERIODS_FAIL })
    })
}

export const { Context, Provider } = createContext(
  billingPeriodsReducer,
  {
    getBillingPeriods,
  },
  defaultStore
)
