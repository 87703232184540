import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LogoBlack from '@Components/LogoBlack';
import { useTranslation } from 'react-i18next';
import { noop } from '@Services/util';
type Props = {
  userIsLoggedIn: boolean,
  goToLogin: Function,
  goToMyDashboard: Function,
};
const HeaderTranslator = ({
  userIsLoggedIn,
  goToLogin,
  goToMyDashboard,
}: Props) => {
  const { t } = useTranslation();
  const [headerIsOpen, setHeaderIsOpen] = useState(false);
  const onChange = (event) => {
    setHeaderIsOpen(event.target.checked);
  };
  return (
    <header
      className={`header header--static header--translate ${
        headerIsOpen ? 'header--menu-open' : ''
      }`}
    >
      <div className="header__wrap">
        <a href="/" className="header__item header__item--brand">
          <LogoBlack />
        </a>
        <div className="header__item">
          <input onChange={onChange} id="input-hamburger" type="checkbox" />
          <label htmlFor="input-hamburger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="open-hamburger"
                strokeWidth="1"
                d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
              ></path>
              <path
                strokeWidth="1"
                id="close-hamburger"
                d="M18.364 4.222l1.414 1.414L13.414 12l6.364 6.364-1.414 1.414L12 13.414l-6.364 6.364-1.414-1.414L10.586 12 4.222 5.636l1.414-1.414L12 10.586l6.364-6.364z"
              ></path>
            </svg>
          </label>
          <nav>
            <label htmlFor="input-hamburger"></label>
            <ul>
              <li>
                <a href="/">
                  {t('components.HeaderTranslator.forEnterprisesLabel')}
                </a>
              </li>
              <li>
                <a href="/translators">
                  {t('components.HeaderTranslator.forTranslatorsLabel')}
                </a>
              </li>
              <li>
                <a href="/pricing">
                  {t('components.HeaderTranslator.pricingLabel')}
                </a>
              </li>
              <li>
                <a className="current">
                  {t('components.HeaderTranslator.translateLabel')}
                </a>
              </li>
              {userIsLoggedIn ? (
                <li>
                  <a
                    onClick={goToMyDashboard}
                    className="button button--secondary is-purple"
                  >
                    {t('components.HeaderTranslator.myDashboardLabel')}
                  </a>
                </li>
              ) : (
                <>
                  <li>
                    <a onClick={goToLogin}>
                      {t('components.HeaderTranslator.loginLabel')}
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

HeaderTranslator.defaultProps = {
  userIsLoggedIn: false,
  goToLogin: noop,
  goToMyDashboard: noop,
};

HeaderTranslator.propTypes = {
  userIsLoggedIn: PropTypes.bool,
  goToLogin: PropTypes.func,
  goToMyDashboard: PropTypes.func,
};

export default HeaderTranslator;
