import React from 'react'
import type { Element } from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
type Props = {
  label: string,
  primaryButtonLabel: string,
  onPressPrimaryButton: Function,
  secondaryButtonLabel: string,
  onPressSecondaryButton: Function,
}

const HeaderSimpleNavigation = ({
  label,
  primaryButtonLabel,
  onPressPrimaryButton,
  secondaryButtonLabel,
  onPressSecondaryButton,
}: Props): Element<'p'> => {
  return (
    <p className="header-simple-navigation">
      <span>{label}</span>
      {secondaryButtonLabel.length ? (
        <a
          onClick={onPressSecondaryButton}
          className="u-margin-left-spacer-small button button--tertiary has-regular-height"
        >
          {secondaryButtonLabel}
        </a>
      ) : (
        ''
      )}
      <a
        onClick={onPressPrimaryButton}
        className="u-margin-left-spacer-small button button--secondary has-regular-height is-purple"
      >
        {primaryButtonLabel}
      </a>
    </p>
  )
}

HeaderSimpleNavigation.defaultProps = {
  label: '',
  primaryButtonLabel: '',
  onPressPrimaryButton: noop,
  secondaryButtonLabel: '',
  onPressSecondaryButton: noop,
}

HeaderSimpleNavigation.propTypes = {
  label: PropTypes.string,
  primaryButtonLabel: PropTypes.string.isRequired,
  onPressPrimaryButton: PropTypes.func.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  onPressSecondaryButton: PropTypes.func.isRequired,
}
export default HeaderSimpleNavigation
