import React, { useContext } from 'react'
import { UserContext } from '@Contexts/User'
import LicenseKey from '@Components/LicenseKey'
import { PlanTypeContext } from '@Contexts/PlanType'
import { UpdateAccessTokenContext } from '@Contexts/UpdateAccessToken'
import { useTranslation } from 'react-i18next'
import MessageBox from '@Components/MessageBox'
import UsageMonthlyCurrent from '@Components/UsageMonthlyCurrent'
import UsageMontlyChart from '@Components/UsageMontlyChart'
import { Provider as BillingPeriodsProvider } from '@Contexts/BillingPeriods'
import { get } from 'lodash'

const DashboardMainView = () => {
  const { store } = useContext(UserContext)
  const { store: updateAccessTokenStore, updateUserAccessToken } = useContext(
    UpdateAccessTokenContext
  )

  const licenseSuspended = store.user.status === 'SUSPENDED'

  const { solutionType } = useContext(PlanTypeContext)
  const { t } = useTranslation()

  return (
    <div className='dashbaord-wrapper'>
      <div className='dashbaord-wrapper__wrap'>
        <div className='dashbaord-wrapper__item'>
          <h3>{t('pages.DashboardMainView.title')}</h3>
        </div>
        <div className='dashbaord-wrapper__item'>
          <div className='dashboard-wrapper-row'>
            <h5>
              {t(`pages.DashboardMainView.yourLicense.title.${solutionType}`)}
            </h5>
          </div>

          <LicenseKey
            license={store.user.activationKey}
            isLoading={
              store.pending ||
              updateAccessTokenStore.pending ||
              store.fetchNotStarted
            }
          />

          {licenseSuspended && (
            <div className='u-margin-top-spacer-base'>
              <MessageBox size='small'>
                {t(`pages.DashboardMainView.licenseSuspendedWarning`)}
              </MessageBox>
            </div>
          )}
        </div>
      </div>

      <div className='dashbaord-wrapper__item'>
        <h5>{t('pages.DashboardMainView.currentMonthlyUsage.title')}</h5>
        {!store.error && (
          <UsageMonthlyCurrent
            isLoading={store.pending || store.fetchNotStarted}
            charsCount={get(store.user.billingPeriod, 'charsCount', 0)}
            amount={get(store.user.billingPeriod, 'amount', 0)}
            currency={get(store.user.billingPeriod, 'currencySymbol', '')}
            percent={get(
              store.user.billingPeriod,
              'usagePercentFromLastMonth',
              0
            )}
          />
        )}
      </div>

      <div className='dashbaord-wrapper__item'>
        <BillingPeriodsProvider>
          <UsageMontlyChart />
        </BillingPeriodsProvider>
      </div>
    </div>
  )
}

export default DashboardMainView
