// @flow
import React, { createContext, useReducer, useContext } from 'react'
import { updateUserPaymentMethodsReducer } from '@Reducers/PaymentMethods'
import { UserContext } from '@Contexts/User'
import i18n from 'i18next'
import { get } from 'lodash'
import {
  PAYMENT_METHODS_UPDATE_FETCH,
  PAYMENT_METHODS_UPDATE_SUCCESS,
  PAYMENT_METHODS_UPDATE_FAIL,
} from '@Actions/PaymentMethods'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import { requestUpdateUserPaymentMethod, requestStripeClientSecret } from '@Services/api'

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
}

type Context = {
  onGetUser: Function,
  store: Store,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
}

const PaymentMethodsContext: Object = createContext<Context>({})

const PaymentMethodsProvider = ({ children }: any) => {
  const { onUpdateUserPaymentMethodInUserContext } = useContext(UserContext)
  const [store, dispatch] = useReducer(updateUserPaymentMethodsReducer, defaultStore)
  const onUpdatePaymentMethods = (
    userData: any,
    stripePayment: Function = noop,
    onComplete: Function = noop,
    onError: Function = noop
  ): Promise<any> => {
    dispatch({ type: PAYMENT_METHODS_UPDATE_FETCH })
    return requestStripeClientSecret()
      .then(response => {
        const userDataWithClientSecret = {
          name: userData.name,
          email: userData.email,
          clientSecret: response,
        }
        return stripePayment(userDataWithClientSecret).then(response => {
          return { stripePaymentMethod: response }
        })
      })
      .then(({ stripePaymentMethod }) => {
        return requestUpdateUserPaymentMethod(stripePaymentMethod).then(data => {
          dispatch({ type: PAYMENT_METHODS_UPDATE_SUCCESS })
          onUpdateUserPaymentMethodInUserContext(data)
          return onComplete()
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: PAYMENT_METHODS_UPDATE_FAIL, errorMessage })
        onError()
      })
  }
  return (
    <PaymentMethodsContext.Provider value={{ onUpdatePaymentMethods, store }}>
      {children}
    </PaymentMethodsContext.Provider>
  )
}

PaymentMethodsProvider.defaultProps = {
  children: {},
}

PaymentMethodsProvider.propTypes = {
  children: PropTypes.object,
}

export { PaymentMethodsContext, PaymentMethodsProvider }
