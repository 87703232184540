// flow
import React, { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import type { Element } from 'react'
import { range } from 'lodash'
import * as moment from 'moment'

import { useTranslation } from 'react-i18next'
import SkeletonLoader from '@Components/SkeletonLoader'
import { Context as BillingPeriodsContext } from '@Contexts/BillingPeriods'
import { UserContext } from '@Contexts/User'
import { normalizeMonthsUsageDataForChart } from '@Services/normalize'
import UsageMontlyChartYearSwitch from '@Components/UsageMontlyChartYearSwitch'
import { numberFormatter } from '@Services/util'

const UsageMontlyChart = (): Element<'div'> => {
  const { t } = useTranslation()

  const { store, getBillingPeriods } = useContext(BillingPeriodsContext)
  const { store: UserContextStore } = useContext(UserContext)

  useEffect(() => {
    if (!store.pending) {
      setNormalizedMonthsData(normalizeMonthsUsageDataForChart(store.billingPeriods, currentYear))
    }
  }, [store])

  const [currentYear, setCurrentYear] = useState(moment.utc().format('YYYY'))
  const [normalizedMonthsData, setNormalizedMonthsData] = useState([])

  const onSwitchYear = (year: string): void => {
    setCurrentYear(year)
    getBillingPeriods(`${year}-01-01`, `${year}-12-31`)
  }

  return (
    <div className="montly-usage-chart">
      <div className="montly-usage-chart__wrap">
        <div className="montly-usage-chart__item montly-usage-chart__item--title">
          <h5>{t('components.UsageMonthlyChart.title')}</h5>
          <UsageMontlyChartYearSwitch onSwitchYear={onSwitchYear} />
        </div>
        <div className="montly-usage-chart__item montly-usage-chart__item--chart">
          <div className="montly-chart">
            <div className="montly-chart__wrap">
              {store.pending || UserContextStore.fetchNotStarted ? (
                <>
                  {range(12).map((fakeRow, key) => {
                    return (
                      <div className="montly-chart__item" key={key}>
                        <div className="montly-name " style={{ flex: '1 1 100%' }}>
                          <SkeletonLoader isRounded>
                            <p>Placeholder</p>
                          </SkeletonLoader>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                normalizedMonthsData.map(({ label, data, isDisabled }, key) => {
                  return (
                    <div key={key} className={`montly-chart__item ${isDisabled ? 'is-disabled' : ''} `}>
                      <div className="montly-name">
                        <p>
                          {label} {currentYear}
                        </p>
                      </div>
                      {!isDisabled && (
                        <div className="montly-bar">
                          <span
                              data-tip=""
                              data-for={`chart-tooltip-${key}`}
                              style={{width: `${data.percent}%`}}
                          />
                          <ReactTooltip id={`chart-tooltip-${key}`} effect="float" className="tooltip tooltip--chart">
                            <div className="tooltip__wrap">
                              <div className="tooltip__item">
                                <strong>{data.chars.toLocaleString()}</strong>
                                <p>{t('components.UsageMonthlyChart.chars')}</p>
                              </div>
                            </div>
                          </ReactTooltip>
                          <div className="montly-number">
                            <strong>{numberFormatter(data.chars)}</strong>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsageMontlyChart
