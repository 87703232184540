//@flow
import { INVOICES_FETCH, INVOICES_SUCCESS, INVOICES_FAIL, INVOICES_LIMIT_REACHED } from '@Actions/Invoices'

export const invoicesReducer = (state: any, action: any) => {
  switch (action.type) {
    case INVOICES_FETCH:
      return { ...state, pending: true, error: false }
    case INVOICES_SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
        invoices: [...state.invoices, ...action.invoices],
        alreadyFetched: true,
      }
    case INVOICES_LIMIT_REACHED:
      return { ...state, pending: false, error: false, hasMore: false }
    case INVOICES_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
