//@flow
import {
  PAYMENT_METHODS_UPDATE_FETCH,
  PAYMENT_METHODS_UPDATE_SUCCESS,
  PAYMENT_METHODS_UPDATE_FAIL,
} from '@Actions/PaymentMethods'

export const updateUserPaymentMethodsReducer = (state: any, action: any) => {
  switch (action.type) {
    case PAYMENT_METHODS_UPDATE_FETCH:
      return { ...state, pending: true, error: false }
    case PAYMENT_METHODS_UPDATE_SUCCESS:
      return { ...state, pending: false, error: false }
    case PAYMENT_METHODS_UPDATE_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
