import React from 'react'
import PropTypes from 'prop-types'
import type { TranslatorActionType } from '@Types/Translator'
import Dropdown from 'rc-dropdown'
import ReactTooltip from 'react-tooltip'
type Props = {
  actions: Array<TranslatorActionType>,
  contextMenu: Array<TranslatorActionType>,
}

const TranslatorActionsBar = ({ actions, contextMenu }: Props) => {
  const renderMenuItem = (menuItem, key) => {
    return (
      <li key={key} onClick={menuItem.onClick}>
        <i className={`icon icon-${menuItem.icon}`}></i>
        <span>{menuItem.label}</span>
      </li>
    )
  }
  const renderMenu = () => {
    return <ul>{contextMenu.map(renderMenuItem)}</ul>
  }

  return (
    <div className="translator-actions-bar">
      <ReactTooltip effect="solid" data-delay-show="500" />
      <div className="translator-actions-bar__wrap">
        {actions.map((action, key) => {
          return (
            <div onClick={action.onClick} data-tip={action.label} key={key} className="translator-actions-bar__item">
              <i className={`icon icon-${action.icon}`}></i>
            </div>
          )
        })}
        {contextMenu.length > 0 && (
          <div className="translator-actions-bar__item">
            <Dropdown overlay={renderMenu} placement={'bottomRight'}>
              <i className={'icon icon-more'}></i>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  )
}

TranslatorActionsBar.defaultProps = {
  actions: [],
  contextMenu: [],
}

TranslatorActionsBar.propTypes = {
  actions: PropTypes.array,
  contextMenu: PropTypes.array,
}

export default TranslatorActionsBar
