// @flow
import React, { createContext, useReducer } from 'react'
import axios from 'axios'

import PropTypes from 'prop-types'
import debounce from 'debounce-promise'
import { TranslatorMemoriesReducer } from '@Reducers/TranslatorMemories'
import {
  onGetMemories,
  onCreateMemories,
  onDeleteMemories,
  onUpdateMemories,
  onUploadTmxFile,
  getImportJob,
  cancelUploadRequest,
  cancelProcessRequest,
} from '@Services/api'
import {
  TRANSLATOR_MEMORIES_FETCH,
  TRANSLATOR_MEMORIES_SUCCESS,
  TRANSLATOR_MEMORIES_FAIL,
  TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES,
  TRANSLATOR_MEMORIES_UPLOAD_START,
  TRANSLATOR_MEMORIES_UPLOAD_UPDATING,
  TRANSLATOR_MEMORIES_UPLOAD_END,
  TRANSLATOR_MEMORIES_PROCESS_UPDATING,
  TRANSLATOR_MEMORIES_UPLOAD_CANCEL,
  TRANSLATOR_MEMORIES_UPLOAD_FAIL,
} from '@Actions/TranslatorMemories'
import { getActiveMemoriesOnLocalstorage, setActiveMemoriesOnLocalstorage } from '@Services/localstorage'
import type { MemoryType } from '@Types/Translator'
import i18n from 'i18next'
import { get } from 'lodash'
import { noop } from '@Services/util'

type Store = {
  pending: boolean,
  error: boolean,
  pendingUpload: boolean,
  errorMessage: string,
  progressUpload: number,
  progressFileProcessing: number,
  memories: Array<MemoryType>,
  activeMemories: Array<string>,
  errorUpload: boolean,
  errorUploadMessage: string,
}

type Context = {
  getMemories: Function,
  createMemories: Function,
  store: Store,
}

const TranslatorMemoriesContext: Object = createContext<Context>({})

const defaultStore: Store = {
  pending: false,
  pendingUpload: false,
  error: false,
  errorMessage: '',
  memories: [],
  activeMemories: [],
  progressUpload: 0,
  progressFileProcessing: 0,
  errorUpload: false,
  errorUploadMessage: '',
}

const TranslatorMemoriesProvider = ({ children }: any) => {
  const [store, dispatch] = useReducer(TranslatorMemoriesReducer, defaultStore)
  const getMemories = (onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: TRANSLATOR_MEMORIES_FETCH })
    dispatch({
      type: TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES,
      activeMemories: [...getActiveMemoriesOnLocalstorage()],
    })
    onGetMemories()
      .then(response => {
        dispatch({ type: TRANSLATOR_MEMORIES_SUCCESS, memories: response })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: TRANSLATOR_MEMORIES_FAIL, errorMessage })
        onError()
      })
  }

  const createMemories = (name: string, onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: TRANSLATOR_MEMORIES_FETCH })
    onCreateMemories(name)
      .then(response => {
        dispatch({ type: TRANSLATOR_MEMORIES_SUCCESS, memories: [...store.memories, response] })
        onComplete()
        addActiveMemories(response.id)
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: TRANSLATOR_MEMORIES_FAIL, errorMessage })
        onError()
      })
  }

  const updateMemories = (id: string, name: string, onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: TRANSLATOR_MEMORIES_FETCH })
    onUpdateMemories(id, name)
      .then(response => {
        dispatch({
          type: TRANSLATOR_MEMORIES_SUCCESS,
          memories: [...store.memories.map(memory => (memory.id === id ? response : memory))],
        })
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: TRANSLATOR_MEMORIES_FAIL, errorMessage })
        onError()
      })
  }

  const deleteMemories = (id: string, onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: TRANSLATOR_MEMORIES_FETCH })
    onDeleteMemories(id)
      .then(() => {
        dispatch({
          type: TRANSLATOR_MEMORIES_SUCCESS,
          memories: store.memories.filter(memory => memory.id !== id),
        })
        removeActiveMemories(id)
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: TRANSLATOR_MEMORIES_FAIL, errorMessage })
        onError()
      })
  }

  const addActiveMemories = (id: string) => {
    const activeMemories = [...store.activeMemories, id]
    dispatch({ type: TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES, activeMemories })
    setActiveMemoriesOnLocalstorage(activeMemories)
  }

  const removeActiveMemories = (id: string) => {
    const activeMemories = store.activeMemories.filter(memoryId => memoryId !== id)
    dispatch({ type: TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES, activeMemories })
    setActiveMemoriesOnLocalstorage(activeMemories)
  }
  const onGetImportJobProgress = (id: string): Promise<?number> => {
    return getImportJob(id).then((response: number): ?number | ?Promise<?number> => {
      const progressFileProcessing = Math.floor(Number(response) * 100) / 2
      dispatch({
        type: TRANSLATOR_MEMORIES_PROCESS_UPDATING,
        progressFileProcessing,
      })
      if (response < 1) {
        return debouncedOnGetImportJobProgress(id)
      } else {
        return response
      }
    })
  }
  const debouncedOnGetImportJobProgress = debounce(onGetImportJobProgress, 600)
  const onUploadProgress = (progressEvent: any): void => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
    if (totalLength !== null) {
      const progressUpload = Math.round((progressEvent.loaded * 100) / totalLength) / 2
      dispatch({
        type: TRANSLATOR_MEMORIES_UPLOAD_UPDATING,
        progressUpload,
      })
    }
  }

  const uploadTmxFile = (id: string, file: any, onComplete: Function = noop, onError: Function = noop) => {
    dispatch({ type: TRANSLATOR_MEMORIES_UPLOAD_START })
    onUploadTmxFile(id, file, onUploadProgress)
      .then((response = '') => {
        if (response.length > 0) {
          return onGetImportJobProgress(response)
        }
      })
      .then(() => {
        dispatch({ type: TRANSLATOR_MEMORIES_UPLOAD_END })
        onComplete()
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
          dispatch({ type: TRANSLATOR_MEMORIES_UPLOAD_FAIL, errorUploadMessage: errorMessage })
          onError()
        }
      })
  }
  const onCancelUploadTmxFile = (onComplete: Function = noop) => {
    cancelUploadRequest()
    cancelProcessRequest()
    onComplete()
    dispatch({ type: TRANSLATOR_MEMORIES_UPLOAD_CANCEL })
  }
  return (
    <TranslatorMemoriesContext.Provider
      value={{
        store,
        getMemories,
        createMemories,
        uploadTmxFile,
        deleteMemories,
        updateMemories,
        onCancelUploadTmxFile,
        addActiveMemories,
        removeActiveMemories,
      }}
    >
      {children}
    </TranslatorMemoriesContext.Provider>
  )
}

TranslatorMemoriesProvider.defaultProps = {
  children: {},
}

TranslatorMemoriesProvider.propTypes = {
  children: PropTypes.object,
}

export { TranslatorMemoriesContext, TranslatorMemoriesProvider }
