// flow
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { noop } from '@Services/util'
import useForm, { FormContext } from 'react-hook-form'
import CountrySelect from '@Components/CountrySelect'
import { get } from 'lodash'
import { isEmailValid } from '@Services/validations'
import { BillingInformationContext } from '@Contexts/BillingInformation'
import MessageBox from '@Components/MessageBox'

type Props = {
  onSubmit: Function,
  onCancel: Function,
  billingInformations: Object,
}

const BillingInfoForm = ({
  onSubmit,
  onCancel,
  billingInformations,
}: Props): Function => {
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const { register, errors, handleSubmit, watch } = methods

  const [selectedCountryIsItaly, setSelectedContryIsItaly] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState(false)
  const [selectedCountryName, setSelectedCountryName] = useState(false)
  const [sdiOrPecValue, setSdiOrPecValue] = useState(initialValueSdiPec)
  const [isCompany, setIsCompany] = useState()
  const [isPrivate, setIsPrivate] = useState()

  const { store } = useContext(BillingInformationContext)

  const submitForm = (formData: any) => {
    onSubmit({
      ...formData,
      ...{ isBusiness: !!isCompany },
      businessCountry: selectedCountryCode
    })
  }

  type onCountrySelectionType = {
    name: string,
    code: string,
  }

  const taxCodeRgx = new RegExp(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/)

  function showCompany() {
    setIsCompany(true)
    setIsPrivate(false)
  }

  function showPrivate() {
    setIsCompany(false)
    setIsPrivate(true)
  }

  const initialValueSdiPec = get(billingInformations, 'pecEmail')
    ? get(billingInformations, 'pecEmail')
    : get(billingInformations, 'sdiCode')
    ? get(billingInformations, 'sdiCode')
    : ''

  const sdiOrPec = watch('sdiCode', initialValueSdiPec)

  useEffect(() => {
    setSdiOrPecValue(sdiOrPec ?? initialValueSdiPec)
  }, [watch, initialValueSdiPec])

  function isValidTaxCode(taxCode: string): boolean {
    return taxCodeRgx.test(taxCode)
  }

  const onCountrySelection = ({ name, code }: onCountrySelectionType): void => {
    setSelectedCountryCode(code)
    setSelectedCountryName(name)
    code === 'IT'
      ? setSelectedContryIsItaly(true)
      : setSelectedContryIsItaly(false)
  }

  useEffect(() => {
    setIsCompany(billingInformations.isBusiness)
    setIsPrivate(!billingInformations.isBusiness)
  }, [billingInformations.isBusiness])

  useEffect(() => {
    const code = get(billingInformations, 'country', '')
    const name = get(billingInformations, 'businessCountry', '')
    onCountrySelection({ name, code })
  }, [billingInformations])

  return (
    <>
      <div className='input-container input-container--isBusiness'>
        <div className={`input-container__item`}>
          <div className='input-wrapper'>
            <div className='input-wrapper__radio'>
              <input
                id='private'
                type='radio'
                defaultChecked={isPrivate}
                value={false}
                name='isBusiness'
                ref={register('isBusiness')}
                onClick={showPrivate}
              />

              <label htmlFor='private'>
                {t('components.SignupForm.isBusinessLabelPrivate')}
              </label>
            </div>
            <div className='input-wrapper__radio'>
              <input
                id='business'
                type='radio'
                defaultChecked={isCompany}
                name='isBusiness'
                ref={register('isBusiness')}
                value={true}
                onClick={showCompany}
              />

              <label htmlFor='business'>
                {t('components.SignupForm.isBusinessLabelCompany')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className='input-container'>
            <div
              className={`input-container__item input-container__item--dropdown ${
                errors.businessCountry && 'has-error'
              }`}
            >
              <label>{t('components.BillingInfoForm.countryLabel')}</label>
              <CountrySelect
                onChange={onCountrySelection}
                defaultValue={get(billingInformations, 'country', '')}
              />
              {errors.businessCountry && (
                <small>{errors.businessCountry.message}</small>
              )}
            </div>
            {(
              <div
                className={`input-container__item ${
                  errors.businessName && 'has-error'
                }`}
              >
                <label>
                  {t('components.BillingInfoForm.nameLabel')}
                </label>
                <input
                  type='text'
                  name='businessName'
                  defaultValue={get(billingInformations, 'businessName', '')}
                  placeholder={t(
                    'components.BillingInfoForm.namePlaceholder'
                  )}
                  maxLength="50"
                  ref={register({
                    required: t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 50,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessName && (
                  <small>{errors.businessName.message}</small>
                )}
              </div>
            )}

            {(isCompany || (selectedCountryIsItaly && isPrivate)) && (
              <div
                className={`input-container__item ${
                  errors.businessAddress && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.addressLabel')}</label>
                <input
                  type='text'
                  name='businessAddress'
                  placeholder={t(
                    'components.BillingInfoForm.addressPlaceholder'
                  )}
                  defaultValue={get(billingInformations, 'businessAddress', '')}
                  maxLength="100"
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 100,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessAddress && (
                  <small>{errors.businessAddress.message}</small>
                )}
              </div>
            )}
          </div>

          {(isCompany || (selectedCountryIsItaly && isPrivate)) && (
            <div className='input-container input-container--multiple-fields'>
              <div
                className={`input-container__item ${
                  errors.businessCity && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.cityLabel')}</label>
                <input
                  type='text'
                  name='businessCity'
                  placeholder={t('components.BillingInfoForm.cityPlaceholder')}
                  defaultValue={get(billingInformations, 'businessCity', '')}
                  maxLength="50"
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('globals.errors.fieldRequired'),
                    maxLength: {
                      value: 50,
                      message: t('globals.errors.fieldRequired'),
                    },
                    minLength: {
                      value: 3,
                      message: t('globals.errors.fieldRequired'),
                    },
                  })}
                />
                {errors.businessCity && (
                  <small>{errors.businessCity.message}</small>
                )}
              </div>
              <div
                className={`input-container__item ${
                  errors.businessZipCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.zipCodeLabel')}</label>
                <input
                  type='text'
                  name='businessZipCode'
                  placeholder={t(
                    'components.BillingInfoForm.zipCodePlaceholder'
                  )}
                  defaultValue={get(billingInformations, 'businessZipCode', '')}
                  ref={register({
                    required:
                      (isCompany || selectedCountryIsItaly) &&
                      t('components.BillingInfoForm.zipCodeMessageError'),
                    maxLength: {
                      value: 30,
                      message: t(
                        'components.BillingInfoForm.zipCodeMessageError'
                      ),
                    },
                    minLength: {
                      value: 3,
                      message: t(
                        'components.BillingInfoForm.zipCodeMessageError'
                      ),
                    },
                  })}
                />
                {errors.businessZipCode && (
                  <small>{errors.businessZipCode.message}</small>
                )}
              </div>
            </div>
          )}
          <div className='input-container'>
            {(isCompany === true || isCompany === 1) && (
              <div
                className={`input-container__item ${
                  errors.vatId && 'has-error'
                }`}
              >
                <label>{`${t('components.BillingInfoForm.vatNumberLabel')} ${
                  !selectedCountryIsItaly
                    ? t('components.BillingInfoForm.ifRequired')
                    : ''
                }`}</label>
                <input
                  type='text'
                  name='vatId'
                  placeholder={t(
                    'components.BillingInfoForm.vatNumberPlaceholder'
                  )}
                  defaultValue={get(billingInformations, 'vatId', '')}
                  ref={register({
                    required:
                      isCompany && selectedCountryIsItaly
                        ? t('globals.errors.fieldRequired')
                        : false,
                    minLength: {
                      value: 5,
                      message: t(
                        'components.BillingInfoForm.vatNumberMessageError'
                      ),
                    },
                  })}
                />
                {errors.vatId && <small>{errors.vatId.message}</small>}
              </div>
            )}
          </div>
          {selectedCountryIsItaly && (isCompany === true || isCompany === 1) && (
            <div className='input-container'>
              <div
                className={`input-container__item ${
                  errors.sdiCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.sdiLabel')}</label>
                <input
                  type='text'
                  name='sdiCode'
                  defaultValue={
                    get(billingInformations, 'sdiCode')
                      ? get(billingInformations, 'sdiCode')
                      : get(billingInformations, 'pecEmail')
                      ? get(billingInformations, 'pecEmail')
                      : ''
                  }
                  placeholder={t('components.BillingInfoForm.sdiPlaceholder')}
                  ref={register({
                    maxLength: {
                      value: sdiOrPecValue.includes('@') ? 320 : 7,
                      message:
                        !sdiOrPecValue.includes('@') &&
                        t('components.BillingInfoForm.sdiMessageError'),
                    },
                    minLength: {
                      value: sdiOrPecValue.includes('@') ? 3 : 7,
                      message:
                        !sdiOrPecValue.includes('@') &&
                        t('components.BillingInfoForm.sdiMessageError'),
                    },
                    validate: {
                      value: (value) => {
                        if (sdiOrPecValue.includes('@')) {
                          return (
                            isEmailValid(sdiOrPecValue) ||
                            t('components.SignupForm.emailError')
                          )
                        }
                      },
                    },
                  })}
                />
                {errors.sdiCode && <small>{errors.sdiCode.message}</small>}
              </div>
            </div>
          )}
          {selectedCountryIsItaly && (isPrivate === true || isPrivate === 1) && (
            <div className='input-container'>
              <div
                className={`input-container__item ${
                  errors.taxCode && 'has-error'
                }`}
              >
                <label>{t('components.BillingInfoForm.taxCodeLabel')}</label>

                <input
                  type='text'
                  name='taxCode'
                  placeholder={t(
                    'components.BillingInfoForm.taxCodePlaceholder'
                  )}
                  defaultValue={get(billingInformations, 'taxCode', '') ?? ''}
                  ref={register({
                    required:
                      isPrivate && selectedCountryIsItaly
                        ? t('globals.errors.fieldRequired')
                        : false,

                    validate: {
                      value: (value) => {
                        return (
                          isValidTaxCode(value.toUpperCase()) ||
                          t('components.BillingInfoForm.taxCodeMessageError')
                        )
                      },
                    },
                  })}
                />
                {errors.taxCode && <small>{errors.taxCode.message}</small>}
              </div>
            </div>
          )}

          <div className='input-container input-container--buttons u-margin-top-spacer-small'>
            <div className='input-container__item'>
              <button
                className={`button button--primary is-purple ${
                  store.pending ? 'is-loading' : ''
                }`}
              >
                {store.pending ? (
                  <>
                    <span/>
                    <p>
                      {t(
                        'pages.DashboardBillingView.billingInfo.buttonLabel'
                      )}
                    </p>
                  </>
                ) : (
                  t('pages.DashboardBillingView.billingInfo.buttonLabel')
                )}
              </button>
              <a
                onClick={onCancel}
                className={'button button--tertiary is-purple '}
              >
                {t(
                  'pages.DashboardBillingView.billingInfo.buttonLabelCancel'
                )}
              </a>
            </div>
          </div>
        </form>
        {store.error && (
          <div className='u-margin-top-spacer-base'>
            <MessageBox>{store.errorMessage}</MessageBox>
          </div>
        )}
      </FormContext>
    </>
  )
}

BillingInfoForm.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  billingInformations: {},
}

BillingInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  billingInformations: PropTypes.object,
}
export default BillingInfoForm
