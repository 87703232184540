//@flow
import {
  UPDATE_ACCESS_TOKEN_REQUEST_FETCH,
  UPDATE_ACCESS_TOKEN_REQUEST_SUCCESS,
  UPDATE_ACCESS_TOKEN_REQUEST_FAIL,
} from '@Actions/UpdateAccessToken'

export const updateAccessTokenReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_ACCESS_TOKEN_REQUEST_FETCH:
      return { ...state, pending: true, error: false, billingPeriods: [] }
    case UPDATE_ACCESS_TOKEN_REQUEST_SUCCESS:
      return { ...state, pending: false, error: false }
    case UPDATE_ACCESS_TOKEN_REQUEST_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
