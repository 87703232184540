// @flow
import jwt_decode from 'jwt-decode'

import { getPercent, mapInputToOpacity } from './util'
import { capitalize, get, isEmpty, maxBy, minBy } from 'lodash'
import type {
  UserData,
  UserLoginRequestType,
  UserLoginResponseType,
  UserPaymentMethods,
  UserSignupRequestType,
  UserTokenResponseType,
} from '@Types/User'
import * as moment from 'moment'
import type { BillingPeriodsType } from '@Types/BillingPeriods'
import type { BillingInformationsType } from '@Types/BillingInformations'
import type { InvoicePdfUrlType, InvoicesType } from '@Types/Invoices'
import type { InvoiceType } from '@Types/Invoice'
import { getUserTokenOnLocalstorage } from './localstorage'
import type { MemoryType } from '@Types/Translator'

export function normalizeUserSignupDataRequest(
  data: any
): UserSignupRequestType {
  let email
  Object.keys(data).forEach((key) => {
    if (data[key] === '') {
      delete data[key]
    }
    if (data.taxCode) {
      data.taxCode = data.taxCode.toUpperCase()
    }
    if (data['sdiCode'] && data['sdiCode'].includes('@')) {
      email = data['sdiCode']
      data['pecEmail'] = email
    }
  })

  return Object.keys(data).reduce((acc, key) => {
    let key2
    if (email) {
      delete data['sdiCode']
    }
    switch (key) {
      case 'name':
        key2 = 'name'
        break
      case 'email':
        key2 = 'email'
        break
      case 'password':
        key2 = 'password'
        break
      case 'stripePaymentMethod':
        key2 = 'stripe_payment_method'
        break
      case 'planType':
        key2 = 'billing_plan'
        break
      case 'businessCountry':
        key2 = 'country'
        break
      case 'isBusiness':
        key2 = 'is_business'
        break
      case 'businessName':
        key2 = 'business_name'
        break
      case 'businessAddress':
        key2 = 'business_address'
        break
      case 'businessZipCode':
        key2 = 'business_zipcode'
        break
      case 'businessCity':
        key2 = 'business_city'
        break
      case 'taxCode':
        key2 = 'tax_code'
        break
      case 'vatId':
        key2 = 'vat_id'
        break
      case 'sdiCode':
        key2 = 'sdi_code'
        break
      case 'pecEmail':
        key2 = 'business_pec'
        break
      default:
        return ''
    }

    return {
      ...acc,
      ...{ [key2]: data[key] },
    }
  }, {})
}

export function normalizeUserTokenResponse(
  response: Object
): UserTokenResponseType {
  const jwt = response.data.data.token
  const decodedJwt = jwt_decode(jwt)
  return {
    activationKey: decodedJwt.token,
    token: jwt,
  }
}

export function normalizeUserPaymentMethods(data: Object): UserPaymentMethods {
  const month = get(data, 'expMonth', 0)
  const expMonth = month < 10 ? `0${month}` : month.toString()
  return {
    paymentMethodId: get(data, 'id', ''),
    brand: capitalize(get(data, 'brand', '')),
    lastDigits: get(data, 'last4', ''),
    expMonth,
    expYear: get(data, 'expYear', '').toString().substr(-2),
  }
}

export function normalizeGetUserResponse(response: Object): UserData {
  const { data } = response.data
  const jwtToken = getUserTokenOnLocalstorage()
  const { token: activationKey } = jwt_decode(jwtToken)
  const creditCardDate = get(data, 'card', {})

  return {
    id: data.id,
    name: data.name,
    email: data.email,
    registrationDate: moment.utc(data.registrationDate).toDate(),
    country: data.country,
    activationKey: activationKey,
    card: isEmpty(creditCardDate)
      ? null
      : normalizeUserPaymentMethods(creditCardDate),
    status: data.status,
    billingPeriod: {
      start: moment.utc(data.billingPeriod.begin).toDate(),
      end: moment.utc(data.billingPeriod.end).toDate(),
      amount: get(data, 'billingPeriod.amount', 0),
      charsCount: get(data, 'billingPeriod.chars', 0),
      currencySymbol: get(data, 'billingPeriod.currencySymbol', ''),
      planType: get(data, 'billingPeriod.plan', ''),
      planDescription: get(data, 'billingPeriod.planDescription', ''),
      planPrice: get(data, 'billingPeriod.planPrice', 0),
      currency: get(data, 'billingPeriod.currency', ''),
    },
    billingInformations: data.isBusiness
      ? normalizeBillingInformationsFromRaw(data)
      : normalizeBillingInformationsPrivateFromRaw(data),
  }
}

export function normalizeUserLoginDataRequest({
  email,
  password,
}: UserLoginRequestType): UserLoginRequestType {
  return {
    email,
    password,
  }
}

export function normalizeUserLoginDataResponse(
  response: Object
): UserLoginResponseType {
  const { data } = response.data
  return {
    token: data.token,
  }
}

export function normalizeBillingPeriods(response: Object): BillingPeriodsType {
  const { data } = response.data
  return data.map((d) => {
    return {
      date: d.begin,
      chars: d.chars,
      amount: 0,
    }
  })
}

export function normalizeBillingInformationsFromRaw(
  data: Object
): BillingInformationsType {
  return {
    vatId: get(data, 'vatId', ''),
    country: get(data, 'country', ''),
    businessName: get(data, 'businessName', ''),
    businessAddress: get(data, 'businessAddress', ''),
    businessZipCode: get(data, 'businessZipcode', ''),
    businessCity: get(data, 'businessCity', ''),
    businessCountry: get(data, 'businessCountry', ''),
    sdiCode: get(data, 'sdiCode', ''),
    pecEmail: get(data, 'businessPec', ''),
    isBusiness: get(data, 'isBusiness'),
  }
}

export function normalizeBillingInformationsPrivateFromRaw(
  data: Object
): BillingInformationsType {
  return {
    country: get(data, 'country', ''),
    businessName: get(data, 'businessName', ''),
    businessAddress: get(data, 'businessAddress', ''),
    businessZipCode: get(data, 'businessZipcode', ''),
    businessCity: get(data, 'businessCity', ''),
    businessCountry: get(data, 'businessCountry', ''),
    isBusiness: get(data, 'isBusiness'),
    taxCode: get(data, 'taxCode', ''),
  }
}

export function normalizeInvoicePdfUrlFromRaw(
  response: any
): InvoicePdfUrlType {
  const { data } = response.data
  return {
    url: get(data, 'url', ''),
  }
}

export function normalizeBillingInformationsToRaw(billingInfo: Object): any {
  let email
  Object.keys(billingInfo).forEach((key) => {
    if (billingInfo[key] === '') {
      delete billingInfo[key]
    }
    if (billingInfo.taxCode) {
      billingInfo.taxCode = billingInfo.taxCode.toUpperCase()
    }
    if (billingInfo['sdiCode'] && billingInfo['sdiCode'].includes('@')) {
      email = billingInfo['sdiCode']
      billingInfo['pecEmail'] = email
    }
  })

  return Object.keys(billingInfo).reduce((acc, key) => {
    let key2
    if (email) {
      delete billingInfo['sdiCode']
    }
    switch (key) {
      case 'name':
        key2 = 'name'
        break
      case 'email':
        key2 = 'email'
        break
      case 'password':
        key2 = 'password'
        break
      case 'stripePaymentMethod':
        key2 = 'stripe_payment_method'
        break
      case 'planType':
        key2 = 'billing_plan'
        break
      case 'businessCountry':
        key2 = 'country'
        break
      case 'isBusiness':
        key2 = 'is_business'
        break
      case 'businessName':
        key2 = 'business_name'
        break
      case 'businessAddress':
        key2 = 'business_address'
        break
      case 'businessZipCode':
        key2 = 'business_zipcode'
        break
      case 'businessCity':
        key2 = 'business_city'
        break
      case 'taxCode':
        key2 = 'tax_code'
        break
      case 'vatId':
        key2 = 'vat_id'
        break
      case 'sdiCode':
        key2 = 'sdi_code'
        break
      case 'pecEmail':
        key2 = 'business_pec'
        break
      default:
        return ''
    }

    return {
      ...acc,
      ...{ [key2]: billingInfo[key] },
    }
  }, {})
}

export function normalizeUserInformationsToRaw(
  updatedUserInformations: Object
): any {
  const oldPassword = get(updatedUserInformations, 'oldPassword', '')
  const newPassword = get(updatedUserInformations, 'newPassword', '')
  if (oldPassword.length && newPassword.length) {
    return {
      name: get(updatedUserInformations, 'name', ''),
      old_password: get(updatedUserInformations, 'oldPassword', ''),
      password: get(updatedUserInformations, 'newPassword', ''),
    }
  } else {
    return {
      name: get(updatedUserInformations, 'name', ''),
    }
  }
}

export function normalizeUserInformationsFromRaw(
  updatedUserInformations: Object
): any {
  return {
    name: get(updatedUserInformations, 'name', ''),
  }
}

export function normalizeInvoicesFromRaw(response: any): Array<InvoicesType> {
  const { data } = response.data
  return data
    .map((invoice) => {
      const status = get(invoice, 'status', '')
      const id = get(invoice, 'id', '').toString()
      const stripePayment = get(invoice, 'stripePayment', null)
      return {
        id,
        date: get(invoice, 'timestamp', ''),
        currencySymbol: get(invoice, 'currencySymbol', ''),
        amount: get(invoice, 'totalAmount', ''),
        chars: get(invoice, 'billingPeriod.chars', 0),
        isReversed: status === 'REVERSED',
        isExternal: status === 'EXTERNAL',
        isPaid: status === 'PAID',
        stripePayment: stripePayment,
        url: status !== 'PAID' ? `/invoices/${id}` : null,
      }
    })
    .reverse()
}

export function normalizeInvoiceFromRaw(reponse: any): InvoiceType {
  const { data } = reponse.data
  const status = get(data, 'status', '')
  const invoiceNo = get(data, 'no', '').toString()
  const stripePayment = get(data, 'stripePayment.id', null)
  return {
    id: get(data, 'id', '').toString(),
    date: get(data, 'timestamp', ''),
    currencySymbol: get(data, 'currencySymbol', ''),
    invoiceNumber: invoiceNo ? invoiceNo + '/' + get(data, 'year', '').toString() : null,
    recipientName: get(data, 'recipientName', ''),
    vatRate: Math.round(Number(get(data, 'vatRate', 0))).toString(),
    amountBeforeTax: Math.round(get(data, 'totalBeforeTax', 0)).toString(),
    amount: Math.round(get(data, 'totalAmount', 0)).toString(),
    stripePayment: stripePayment,
    clientSecret: get(data, 'stripePayment.clientSecret', null),
    isReversed: status === 'REVERSED',
    isExternal: status === 'EXTERNAL',
    isPaid: status === 'PAID',
    item: get(data, 'costItem', ''),
  }
}

export const normalizeMonthsUsageDataForChart = (
  billingPeriods: BillingPeriodsType[],
  year: string
): any => {
  const currentMonth: number = moment.utc().month()
  const isCurrentYear = Number(year) === moment.utc().year()

  // label = month's name
  const monthLabels: string[] = ['January', 'February', 'March', 'April', 'May','June',
                                  'July', 'August', 'September', 'October', 'November', 'December']
  const pastMonthLabels = isCurrentYear
    ? monthLabels.splice(0, currentMonth)
    : monthLabels

  let uiMonths

  // disable all months
  if (!billingPeriods.length)
    uiMonths = monthLabels
      .map((label) => {
        return { label, isDisabled: true }
      })
      .reverse()
  else {
    const heighestChars = getHeighestChars(billingPeriods)
    const lowestChars = getLowestChars(billingPeriods)

    const reducer = (acc, label, index) => {
      // search month's billing period
      const data = billingPeriods.filter(
        (el) => moment.utc(el.date).month() === index
      )[0]

      // disable month (no billing period)
      if (!data) acc.push({ label, isDisabled: true })
      else {
        // write month data
        acc.push({
          label,
          isDisabled: false,
          data: {
            chars: data.chars,
            amount: data.amount,
            percent: getPercent(data.chars, heighestChars * 1.15),
            opacity: mapInputToOpacity(
              lowestChars,
              heighestChars,
              0.25,
              1,
              data.chars
            ),
          },
        })
      }

      return acc
    }

    uiMonths = pastMonthLabels.reduce(reducer, []).reverse()
  }

  return uiMonths
}

const getHeighestChars = (billingPeriods: BillingPeriodsType[]): number => {
  const maxValue = maxBy(billingPeriods, (m) => m.chars)
  return Math.round(maxValue.chars)
}

const getLowestChars = (billingPeriods: BillingPeriodsType[]): number => {
  const minValue = minBy(billingPeriods, (m) => m.chars)
  return Math.round(minValue.chars)
}

export function normalizeTranslatorMemoryFromRaw(data: any): MemoryType {
  return {
    id: String(get(data, 'id', '')),
    key: get(data, 'key', ''),
    name: get(data, 'name', ''),
    description: get(data, 'description', ''),
    creationDate: get(data, 'creationDate', ''),
  }
}

export function normalizeTranslatorMemoriesListFromRaw(
  response: any
): Array<MemoryType> {
  const { data } = response.data
  return data.map(normalizeTranslatorMemoryFromRaw)
}

export function normalizeUploadedTmxFileFromRaw(response: any): string {
  const { data } = response.data
  return data.id
}

export function normalizeImportJobFromRaw(response: any): string {
  const { data } = response.data
  return data.progress
}
