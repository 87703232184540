import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
type ButtonType = 'primary' | 'secondary' | 'tertiary'

type Props = {
  children: Array<any>,
  title: string,
  description: string,
  buttonType: ButtonType,
  buttonLabel: string,
  buttonShouldBeSmall: boolean,
  onButtonPress: Function,
}

const TranslatorList = ({
  children,
  title,
  buttonLabel,
  onButtonPress,
  buttonType,
  description,
  buttonShouldBeSmall,
}: Props) => {
  return (
    <div className="translator-list">
      <div className="translator-list__wrap">
        <div className="translator-list__item translator-list__item--header">
          <div className="translator-list-header">
            <div className="translator-list-header__item">
              <h4>{title}</h4>
              {description.length > 0 && <p>{description}</p>}
            </div>
            <div className="translator-list-header__item">
              <a
                onClick={onButtonPress}
                className={`button button--${buttonType} is-purple ${buttonShouldBeSmall ? 'is-small' : ''}`}
              >
                {buttonLabel}
              </a>
            </div>
          </div>
        </div>
        {children.length > 0 && (
          <div className="translator-list__item translator-list__item--body">
            <div className="translator-list-body">{children}</div>
          </div>
        )}
      </div>
    </div>
  )
}

TranslatorList.defaultProps = {
  children: [],
  onButtonPress: noop,
  title: '',
  description: '',
  buttonType: 'secondary',
  buttonLabel: '',
  buttonShouldBeSmall: false,
}

TranslatorList.propTypes = {
  children: PropTypes.array,
  onButtonPress: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonShouldBeSmall: PropTypes.bool,
}

export default TranslatorList
