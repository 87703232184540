// @flow
const dashboardBaseUrl = '/dashboard'
const signupBaseUrl = '/license/buy'
const loginBaseUrl = '/login'
const invoiceBaseUrl = '/invoices'
const translatorBaseUrl = '/translate'

const ROUTES_PATHS = {
  signupBaseUrl,
  signupBill: signupBaseUrl + '/bill',
  signupThankYou: signupBaseUrl + '/thank-you',

  dashboardBaseUrl,
  dashboardBillingUrl: dashboardBaseUrl + '/billing',
  dashboardAccountSettingsUrl: dashboardBaseUrl + '/account-settings',

  loginBaseUrl,
  loginResetPasswordUrl: loginBaseUrl + '/reset-password',
  loginNewPasswordUrl: loginBaseUrl + '/new-password',

  invoiceBaseUrl,
  invoiceUrl: invoiceBaseUrl + '/:id',

  translatorBaseUrl,
}

export default ROUTES_PATHS
