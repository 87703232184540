// flow
import React from 'react'
import Chip from '@Components/Chip'
import PropTypes from 'prop-types'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import { noop } from '@Services/util'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

type Props = {
  id: string,
  date: string,
  chars: number,
  currencySymbol: string,
  amount: string,
  isReversed: boolean,
  isExternal: boolean,
  isPaid: boolean,
  onGetInvoicePdf: Function,
  stripePayment: ?string,
  url: string,
}

const InvoiceTableRow = ({
  id,
  date,
  chars,
  currencySymbol,
  onGetInvoicePdf,
  amount,
  isReversed,
  isExternal,
  isPaid,
  url,
  stripePayment,
}: Props): Function => {
  const { t } = useTranslation()

  const getInvoicePdf = (): void => {
    onGetInvoicePdf(id).then(({ url }) => window.open(url, '_blank', 'noopener,noreferrer'))
  }

  return (
    <div className="invoice-table-row">
      <div className="u-is-desktop">
        <div className="invoice-table-row__wrap ">
          <div className="invoice-table-row__item">
            <p>{moment.utc(date).format('MMM, DD YYYY')}</p>
          </div>
          <div className="invoice-table-row__item">
            <p>{chars.toLocaleString()}</p>
          </div>
          <div className="invoice-table-row__item">
            <p>
              {currencySymbol}
              {amount}
            </p>
          </div>
          <div className="invoice-table-row__item">
            {!isExternal && (isPaid ? (
              <Chip type="empty">{t('components.InvoicesTable.InvoicesTableRow.paidLabel')}</Chip>
            ) : isReversed ? (
                <Chip type="empty">{t('components.InvoicesTable.InvoicesTableRow.revLabel')}</Chip>
            ) : (
              <Chip type="full">{t('components.InvoicesTable.InvoicesTableRow.pendingLabel')}</Chip>
            ))}
          </div>
          <div className="invoice-table-row__item">
            {!isPaid && stripePayment && (
                <a className="is-purple" href={url} target="_blank">
                  {t('components.InvoicesTable.InvoicesTableRow.payLabel')}
                </a>
            )}
            &nbsp;
            {!isExternal && !isReversed && (
              <a className="is-grey" onClick={getInvoicePdf}>
                {t('components.InvoicesTable.InvoicesTableRow.viewLabel')}
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="u-is-mobile">
        <div className="invoice-table-row__wrap">
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {[0].map((faq, key) => (
              <AccordionItem key={key}>
                <AccordionItemState>
                  {({ expanded }) => (
                    <>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className={`accordion-button ${expanded ? 'is-expanded' : ''}`}>
                            <p>{moment.utc(date).format('MMMM, DD YYYY')}</p>
                            <i className="icon icon-chevron-down"/>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-content accordion-content--invoices">
                          <div className="invoice-table-row__item">
                            <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnCharacters')}</strong>
                            <p>{chars}</p>
                          </div>
                          <div className="invoice-table-row__item">
                            <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnAmount')}</strong>
                            <p>
                              {currencySymbol}
                              {amount}
                            </p>
                          </div>
                          <div className="invoice-table-row__item">
                            <strong>{t('components.InvoicesTable.InvoicesTableHeader.columnStatus')}</strong>
                            {!isExternal && (isPaid ? (
                              <Chip type="empty">{t('components.InvoicesTable.InvoicesTableRow.paidLabel')}</Chip>
                            ) : isReversed ? (
                                <Chip type="empty">{t('components.InvoicesTable.InvoicesTableRow.revLabel')}</Chip>
                            ) : (
                              <Chip type="full">{t('components.InvoicesTable.InvoicesTableRow.pendingLabel')}</Chip>
                            ))}
                          </div>
                          <div className="invoice-table-row__item">
                            <strong/>
                            {!isExternal && !isReversed && (!isPaid && stripePayment ? (
                                <a className="is-purple" href={url} target="_blank">
                                  {t('components.InvoicesTable.InvoicesTableRow.payLabel')}
                                </a>
                            ) : (
                                <a className="is-grey" onClick={getInvoicePdf}>
                                  {t('components.InvoicesTable.InvoicesTableRow.viewLabel')}
                                </a>
                            ))}
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </>
                  )}
                </AccordionItemState>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

InvoiceTableRow.defaultProps = {
  isEmpty: false,
  date: '',
  id: '',
  chars: '0',
  currencySymbol: '',
  onGetInvoicePdf: noop,
  amount: '',
  isReversed: false,
  isExternal: false,
  isPaid: false,
  url: '',
}

InvoiceTableRow.propTypes = {
  isEmpty: PropTypes.bool,
  date: PropTypes.string,
  id: PropTypes.string,
  chars: PropTypes.number,
  currencySymbol: PropTypes.string,
  onGetInvoicePdf: PropTypes.func,
  amount: PropTypes.string,
  isReversed: PropTypes.bool,
  isExternal: PropTypes.bool,
  isPaid: PropTypes.bool,
  url: PropTypes.string,
}

export default InvoiceTableRow
