//@flow
import {
  INVOICE_FETCH,
  INVOICE_SUCCESS,
  INVOICE_FAIL,
  INVOICE_PAYING_FETCH,
  INVOICE_PAYING_SUCCESS,
  INVOICE_PAYING_FAIL,
} from '@Actions/Invoice'

export const invoiceReducer = (state: any, action: any) => {
  switch (action.type) {
    case INVOICE_FETCH:
      return { ...state, pending: true, error: false }
    case INVOICE_SUCCESS:
      return { ...state, pending: false, error: false, invoice: action.invoice, alreadyFetched: true }
    case INVOICE_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}

export const invoicePayingReducer = (state: any, action: any) => {
  switch (action.type) {
    case INVOICE_PAYING_FETCH:
      return { ...state, pending: true, error: false }
    case INVOICE_PAYING_SUCCESS:
      return { ...state, pending: false, error: false }
    case INVOICE_PAYING_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
