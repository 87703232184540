// @flow
import createContext from './createContext'
import i18n from 'i18next'
import { userSignupReducer } from '@Reducers/UserSignup'
import { noop } from '@Services/util'
import {
  USER_SIGNUP_REQUEST_FETCH,
  USER_SIGNUP_REQUEST_SUCCESS,
  USER_SIGNUP_REQUEST_FAIL,
} from '@Actions/UserSignup'
import { userSignup, requestStripeClientSecret } from '@Services/api'
import { setUserTokenOnLocalstorage } from '@Services/localstorage'
import { get } from 'lodash'

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
  activationKey: string,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
  activationKey: '',
}

const onUserSignup =
  (dispatch) =>
  (
    userData: any,
    stripePayment: Function,
    onComplete: Function = noop,
    onError: Function = noop
  ): Promise<any> => {
    dispatch({ type: USER_SIGNUP_REQUEST_FETCH })
    return requestStripeClientSecret()
      .then((response) => {
        const userDataWithClientSecret = {
          name: userData.name,
          email: userData.email,
          clientSecret: response,
        }
        return stripePayment(userDataWithClientSecret).then((response) => {
          return { ...userData, stripePaymentMethod: response }
        })
      })
      .then((response) => {
        return userSignup(response).then(({ activationKey, token }) => {
          dispatch({ type: USER_SIGNUP_REQUEST_SUCCESS, activationKey })
          setUserTokenOnLocalstorage(token)
          return onComplete(token)
        })
      })
      .catch((error) => {
        const errorMessage = get(
          error,
          'response.data.error.message',
          i18n.t('globals.errors.generalError')
        )
        dispatch({ type: USER_SIGNUP_REQUEST_FAIL, errorMessage })
        onError()
      })
  }

export const { Context, Provider } = createContext(
  userSignupReducer,
  {
    onUserSignup,
  },
  defaultStore
)
