//@flow
import {
  BILLING_INFORMATION_UPDATE_FETCH,
  BILLING_INFORMATION_UPDATE_SUCCESS,
  BILLING_INFORMATION_UPDATE_FAIL,
} from '@Actions/BillingInformation'

export const billingInformationsReducer = (state: any, action: any) => {
  switch (action.type) {
    case BILLING_INFORMATION_UPDATE_FETCH:
      return { ...state, pending: true, error: false }
    case BILLING_INFORMATION_UPDATE_SUCCESS:
      return { ...state, pending: false, error: false }
    case BILLING_INFORMATION_UPDATE_FAIL:
      return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
    default:
      return state
  }
}
