// @flow

export function ObjectIsNotEmpty(object: Object): boolean {
  return Object.keys(object).length > 0
}

import * as EmailValidator from 'email-validator'

export function isFilled(value: string): boolean {
  return value.length > 0
}

export function valueIsTooLong(value: string): boolean {
  return value.length > 50
}

export function valueIsTooShort(value: string): boolean {
  return value.length < 3
}

export function isPasswordValid(password: string): boolean {
  return password.length >= 6
}

export function isEmailValid(email: string = ''): boolean {
  return EmailValidator.validate(email)
}
export function isEmailEmpty(email: string = ''): boolean {
  return email.length === 0
}
