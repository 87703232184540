// @flow
import {
    LICENSE_PAUSE_REQUEST_FETCH,
    LICENSE_PAUSE_REQUEST_SUCCESS,
    LICENSE_PAUSE_REQUEST_FAIL,
} from '@Actions/LicensePause'

export const licensePauseReducer = (state: any, action: any) => {
    switch (action.type) {
        case LICENSE_PAUSE_REQUEST_FETCH:
            return { ...state, pending: true, error: false }
        case LICENSE_PAUSE_REQUEST_SUCCESS:
            return { ...state, pending: false, error: false }
        case LICENSE_PAUSE_REQUEST_FAIL:
            return { ...state, pending: false, error: true, errorMessage: action.errorMessage }
        default:
            return state
    }
}
