// @flow
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ROUTES_PATHS from '../routes_paths'
import useForm from 'react-hook-form'
import { noop } from '@Services/util'
import { isEmailValid } from '@Services/validations'
import { useTranslation } from 'react-i18next'
import MessageBox from '@Components/MessageBox'
import { UserLoginContext } from '@Contexts/UserLogin'

type Props = {
  onSubmit: Function,
}

const LoginForm = ({ onSubmit }: Props): Function => {
  const { register, errors, handleSubmit } = useForm()
  const { t } = useTranslation()
  const { userLoginStore: store } = useContext(UserLoginContext)

  const onSubmitForm = function (formData: Object): void {
    onSubmit(formData)
  }

  const shouldShowMessageBox = function (): boolean {
    const { pending, error, errorMessage } = store
    return !pending && error && errorMessage.length > 0
  }

  return (
    <>
      {shouldShowMessageBox() && (
        <div className='u-margin-top-spacer-mid u-margin-bottom-spacer-mid'>
          <MessageBox>
            <p>{store.errorMessage}</p>
          </MessageBox>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className='input-container'>
          <div
            className={`input-container__item ${errors.email && 'has-error'}`}
          >
            <label>{t('components.LoginForm.emailLabel')}</label>
            <input
              type='email'
              placeholder={t('components.LoginForm.emailPlaceholder')}
              name='email'
              autoComplete='username'
              ref={register({
                required: t('components.LoginForm.emailEmpty'),
                validate: {
                  value: (value) =>
                    isEmailValid(value) || t('components.LoginForm.emailError'),
                },
              })}
            />
            {errors.email && <small>{errors.email.message}</small>}
          </div>
        </div>
        <div className='input-container'>
          <div
            className={`input-container__item input-container__item--forgot ${
              errors.password && 'has-error'
            }`}
          >
            <label>{t('components.LoginForm.passwordLabel')}</label>
            <input
              type='password'
              autoComplete='current-password'
              placeholder={t('components.LoginForm.passwordPlaceholder')}
              name='password'
              ref={register({
                required: t('components.LoginForm.passwordEmpty'),
                minLength: {
                  value: 8,
                  message: t('components.LoginForm.passwordError'),
                },
              })}
            />
            <span>
              <Link to={ROUTES_PATHS.loginResetPasswordUrl}>
                {t('components.LoginForm.forgotPassword')}
              </Link>
            </span>
            {errors.password && <small>{errors.password.message}</small>}
          </div>
        </div>
        <div className='input-container'>
          <div className='input-container__item'>
            <button
              className={`button button--primary is-purple ${
                store.pending ? 'is-loading' : ''
              }`}
            >
              {store.pending ? (
                <>
                  <span/>
                  <p>{t('components.LoginForm.buttonLabel')}</p>
                </>
              ) : (
                t('components.LoginForm.buttonLabel')
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

LoginForm.defaultProps = {
  onSubmit: noop,
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginForm
