// @flow
import React, { createContext, useReducer, useContext } from 'react'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import { billingInformationsReducer } from '@Reducers/BillingInformation'
import { updateBillingInformations } from '@Services/api'
import { noop } from '@Services/util'
import { get } from 'lodash'
import { UserContext } from '@Contexts/User'
import {
  BILLING_INFORMATION_UPDATE_FETCH,
  BILLING_INFORMATION_UPDATE_SUCCESS,
  BILLING_INFORMATION_UPDATE_FAIL,
} from '@Actions/BillingInformation'

type Context = {
  onUpdateBillingInformation: Function,
  store: Store,
}

const BillingInformationContext: Object = createContext<Context>({})

type Store = {
  pending: boolean,
  error: boolean,
  errorMessage: string,
}

const defaultStore: Store = {
  pending: false,
  error: false,
  errorMessage: '',
}
const BillingInformationProvider = ({ children }: any) => {
  const [store, dispatch] = useReducer(billingInformationsReducer, defaultStore)
  const { onUpdateBillingInformationsInUserContext } = useContext(UserContext)

  const onUpdateBillingInformation = (
    billingInformations: Object,
    onComplete: Function = noop,
    onError: Function = noop
  ) => {
    dispatch({ type: BILLING_INFORMATION_UPDATE_FETCH })
    updateBillingInformations(billingInformations)
      .then(response => {
        dispatch({ type: BILLING_INFORMATION_UPDATE_SUCCESS })
        onUpdateBillingInformationsInUserContext(response)
        onComplete()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.message', i18n.t('globals.errors.generalError'))
        dispatch({ type: BILLING_INFORMATION_UPDATE_FAIL, errorMessage })
        onError()
      })
  }
  return (
    <BillingInformationContext.Provider value={{ store, onUpdateBillingInformation }}>
      {children}
    </BillingInformationContext.Provider>
  )
}

BillingInformationProvider.defaultProps = {
  children: {},
}

BillingInformationProvider.propTypes = {
  children: PropTypes.object,
}

export { BillingInformationContext, BillingInformationProvider }
