// @flow
import axios from 'axios'
import * as moment from 'moment'
import md5 from 'crypto-js/md5'
import { delay } from '@Services/util'
import {
  normalizeUserSignupDataRequest,
  normalizeUserTokenResponse,
  normalizeGetUserResponse,
  normalizeUserLoginDataRequest,
  normalizeUserLoginDataResponse,
  normalizeUserPaymentMethods,
  normalizeBillingInformationsFromRaw,
  normalizeBillingInformationsPrivateFromRaw,
  normalizeBillingPeriods,
  normalizeUserInformationsToRaw,
  normalizeBillingInformationsToRaw,
  normalizeUserInformationsFromRaw,
  normalizeInvoicesFromRaw,
  normalizeInvoiceFromRaw,
  normalizeInvoicePdfUrlFromRaw,
  normalizeTranslatorMemoriesListFromRaw,
  normalizeTranslatorMemoryFromRaw,
  normalizeUploadedTmxFileFromRaw,
  normalizeImportJobFromRaw,
} from './normalize'
import {
  getUserIsLoggedIn,
  getLicenseKeyOnLocalstorage,
} from '@Services/localstorage'
import type { BillingInformationsType } from '@Types/BillingInformations'
import type {
  UserTokenResponseType,
  UserData,
  UserLoginResponseType,
  UserLoginRequestType,
} from '@Types/User'
import type { MemoryType, TranslationType } from '@Types/Translator'

export const webApiModernMT = axios.create({
  baseURL: process.env.WEB_API_URL,
  timeout: 60000,
})

export const apiModernMt = axios.create({
  baseURL: process.env.API_MODERNMT_TRANSLATOR_URL,
  timeout: 60000,
})

const CancelToken = axios.CancelToken
let cancelUploadReq
let cancelProcessReq = {
  source: CancelToken.source(),
}

export const updateUserToken = (accessToken: string) => {
  //$FlowFixMe
  webApiModernMT.defaults.headers.common['X-Auth-Token'] = accessToken
}

const getTranslatorBaseUrl = () => {
  updateMMTApiKey()
  return getUserIsLoggedIn() ? apiModernMt : webApiModernMT
}

const updateMMTApiKey = (): void => {
  apiModernMt.defaults.headers['MMT-ApiKey'] = getLicenseKeyOnLocalstorage()
}

//$FlowFixMe
webApiModernMT.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded'

export function userSignup(userData: any): Promise<any> {
  const normalizedUserData = normalizeUserSignupDataRequest(userData)
  return webApiModernMT
    .post('/users', normalizedUserData)
    .then(normalizeUserTokenResponse)
}

export function userRequestResetPassword(email: string): Promise<any> {
  return webApiModernMT
    .get(`/users/me/password?email=${encodeURIComponent(email)}`)
    .then((res) => res)
}

export function userRequestNewPassword(
  token: any,
  password: string
): Promise<any> {
  return webApiModernMT
    .post('/users/me/password/', { token, password })
    .then((res) => res)
}

export function userLogin(
  userData: UserLoginRequestType
): Promise<UserLoginResponseType> {
  const normalizedUserData = normalizeUserLoginDataRequest(userData)
  return webApiModernMT
    .post('/logins', normalizedUserData)
    .then(normalizeUserLoginDataResponse)
}

export function getUser(userToken: string): Promise<UserData> {
  updateUserToken(userToken)
  return webApiModernMT.get('/users/me').then(normalizeGetUserResponse)
}

export function pauseLicense(): Promise<any> {
  return webApiModernMT.post('/users/me/pause').then((res) => res.data)
}

export function unpauseLicense(): Promise<any> {
  return webApiModernMT.delete('/users/me/pause').then((res) => res.data)
}

export function requestStripeClientSecret(): Promise<string> {
  return webApiModernMT
    .get('/users/card-wallet')
    .then(({ data }) => data.data.client_secret)
}

export function requestUpdateUserPaymentMethod(
  stripe_payment_method: string
): Promise<any> {
  return webApiModernMT
    .post('/users/me/credit-cards', { stripe_payment_method })
    .then((response) => {
      const { data } = response.data
      return normalizeUserPaymentMethods(data)
    })
}

export function getBillingPeriodsByDates(
  from: string,
  to: string
): Promise<any> {
  return webApiModernMT
    .get(`/users/me/billing-periods?from=${from}&to=${to}`)
    .then(normalizeBillingPeriods)
}

export function updateBillingInformations(
  billingInfo: any
): Promise<BillingInformationsType> {
  return webApiModernMT
    .post('/users/me/billing', {
      ...normalizeBillingInformationsToRaw(billingInfo),
    })
    .then((response) => {
      const { data } = response.data
      return data.isBusiness
        ? normalizeBillingInformationsFromRaw(data)
        : normalizeBillingInformationsPrivateFromRaw(data)
    })
}

export function updateUserInformations(
  updatedUserInformations: any
): Promise<any> {
  return webApiModernMT
    .post('/users/me', {
      ...normalizeUserInformationsToRaw(updatedUserInformations),
    })
    .then((response) => {
      const { data } = response.data
      return normalizeUserInformationsFromRaw(data)
    })
}

export function updateAccessToken(
  accessToken: string
): Promise<UserTokenResponseType> {
  updateUserToken(accessToken)
  return webApiModernMT
    .post('/users/me/access-tokens')
    .then((res) => delay(1000).then(() => normalizeUserTokenResponse(res)))
}

export function getInvoicesByDates(from: string, to: string) {
  return webApiModernMT
    .get(`/users/me/invoices?from=${from}&to=${to}`)
    .then(normalizeInvoicesFromRaw)
}

export function getInvoiceById(id: string = ''): any {
  return webApiModernMT
    .get(`/invoices/${id}?payment_info=true`)
    .then(normalizeInvoiceFromRaw)
}

export function getInvoicePdf(id: string = ''): any {
  return webApiModernMT
    .get(`/invoices/${id}/pdf`)
    .then(normalizeInvoicePdfUrlFromRaw)
}

export function updateInvoiceById(
  id: string = '',
  paymentIntent: string = ''
): any {
  return webApiModernMT
    .post(`/invoices/${id}/payment`, { id: paymentIntent })
    .then((res) => res)
}

export function translate(
  sourceText: string,
  sourceLanguage: string,
  targetLanguage: string,
  activeMemories: string
): Promise<TranslationType> {
  const ts = moment.utc().valueOf()
  const data = {
    multiline: true,
    source: sourceLanguage,
    target: targetLanguage,
    q: sourceText,
    hints: activeMemories,
    ts: ts,
    verify: md5(process.env.CLIENT_PRIVATE_KEY + '#' + ts + '#' + sourceText).toString(),
  }

  return getTranslatorBaseUrl()
    .post('/translate', data, { headers: { 'X-HTTP-Method-Override': 'GET' } })
    .then((res) => {
      const { data } = res.data
      return {
        translation: data.translation,
        detectedLanguage: data.detectedLanguage,
      }
    })
}

export function onGetMemories(): Promise<Array<MemoryType>> {
  return getTranslatorBaseUrl()
    .get('/memories')
    .then(normalizeTranslatorMemoriesListFromRaw)
}

export function onCreateMemories(name: string): Promise<MemoryType> {
  return getTranslatorBaseUrl()
    .post('/memories', { name })
    .then((response) => {
      const { data } = response.data
      return normalizeTranslatorMemoryFromRaw(data)
    })
}

export function onDeleteMemories(id: string): Promise<MemoryType> {
  return getTranslatorBaseUrl()
    .post(`/memories/${id}`, null, {
      headers: {
        'X-HTTP-Method-Override': 'DELETE',
      },
    })
    .then((response) => {
      const { data } = response.data
      return normalizeTranslatorMemoryFromRaw(data)
    })
}

export function onUpdateMemories(
  id: string,
  name: string
): Promise<MemoryType> {
  return getTranslatorBaseUrl()
    .post(
      `/memories/${id}`,
      { name },
      {
        headers: {
          'X-HTTP-Method-Override': 'PUT',
        },
      }
    )
    .then((response) => {
      const { data } = response.data
      return normalizeTranslatorMemoryFromRaw(data)
    })
}

export function onUploadTmxFile(
  id: string,
  file: any,
  onUploadProgress: Function
): Promise<any> {
  return getTranslatorBaseUrl()
    .post(`memories/${id}/content`, file, {
      timeout: 1000 * 60 * 30, // 30 min
      cancelToken: new CancelToken((cancel) => {
        cancelUploadReq = cancel
      }),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .then(normalizeUploadedTmxFileFromRaw)
}

export function getImportJob(id: string): Promise<any> {
  return getTranslatorBaseUrl()
    .get(`import-jobs/${id}`, {
      cancelToken: cancelProcessReq.source.token,
    })
    .then(normalizeImportJobFromRaw)
}

export function cancelUploadRequest() {
  cancelUploadReq && cancelUploadReq()
}

export function cancelProcessRequest() {
  cancelProcessReq.source.cancel()
  setTimeout(() => {
    cancelProcessReq.source = CancelToken.source()
  }, 2000) // 🐷🍟
}
