import React, { useContext } from 'react'
import AccountSettingForm from '@Components/AccountSettingForm'
import { UpdateUserInformationContext } from '@Contexts/UserUpdate'
import { useTranslation } from 'react-i18next'

const DashboardAccountSettingsView = () => {
  const { t } = useTranslation()
  const { onUpdateUserInformations } = useContext(UpdateUserInformationContext)
  return (
    <>
      <div className='dashbaord-wrapper'>
        <div className='dashbaord-wrapper__wrap'>
          <div className='dashbaord-wrapper__item'>
            <h3>{t('pages.DashboardAccountSettingsView.title')}</h3>
          </div>
          <div className='dashbaord-wrapper__item'>
            <AccountSettingForm onSubmit={onUpdateUserInformations} />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardAccountSettingsView
