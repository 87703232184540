//@flow
import React, { useContext, useState, useEffect } from 'react'
import FlatCard from '@Components/FlatCard'
import { UserContext } from '@Contexts/User'
import { PaymentMethodsContext } from '@Contexts/PaymentMethods'
import { BillingInformationContext } from '@Contexts/BillingInformation'
import { PlanTypeContext } from '@Contexts/PlanType'
import SkeletonLoader from '@Components/SkeletonLoader'
import { CardElement } from 'react-stripe-elements'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useStripe } from 'react-stripe-hooks'
import type { UserSignupStripeRequestType } from '@Types/User'
import { cardElementStyle } from '@Services/util'
import BillingInfoForm from '@Components/BillingInfoForm'
import InvoiceTable from '@Components/InvoiceTable'

const DashboardBillingView = () => {
  const stripe = useStripe()
  const { t } = useTranslation()
  const { onUpdateBillingInformation } = useContext(BillingInformationContext)
  const { store } = useContext(UserContext)
  const { store: paymentMethodsStore, onUpdatePaymentMethods } = useContext(
    PaymentMethodsContext
  )
  const { planType, solutionType, updatePlanType } = useContext(PlanTypeContext)
  const [isInFocusOnStripe, setIsInFocusOnStripe] = useState(false)
  const [card, setCard] = useState(null)
  const [cardIsInvalid, setCardIsInvalid] = useState(false)
  const [showCardError, setShowCardError] = useState(false)
  const [cardInvalidMessage, setCardInvalidMessage] = useState(
    t('components.SignupForm.cardError')
  )
  const [shouldShowChangeCard, setShouldShowChangeCard] = useState(false)
  const [shouldShowUpdateUserForm, setShouldShowUpdateUserForm] =
    useState(false)
  const [billingInformations, setBillingInformation] = useState(true)
  const [cardInformation, setCardInformation] = useState(true)
  const onShowChangeCard = (): void => {
    setShouldShowChangeCard(!shouldShowChangeCard)
    resetAllCardErrors()
  }

  const onShowHideUpdateUserForm = (): void => {
    setShouldShowUpdateUserForm(!shouldShowUpdateUserForm)
  }

  const onSubmitForm = (billingInformations: Object): void => {
    onUpdateBillingInformation(billingInformations, onShowHideUpdateUserForm)
  }

  const resetAllCardErrors = (): void => {
    setCardIsInvalid(false)
    setShowCardError(false)
    setCardInvalidMessage('')
  }
  function onChangeCardComponent(changes: Object): void {
    const error = get(changes, 'error', undefined)
    const empty = get(changes, 'empty', false)
    if (error) {
      setCardIsInvalid(true)
      setShowCardError(true)
      setCardInvalidMessage(error.message)
    } else if (empty) {
      setCardIsInvalid(true)
      setShowCardError(true)
      setCardInvalidMessage(t('components.SignupForm.cardError'))
    } else {
      resetAllCardErrors()
    }
  }
  useEffect(() => {
    if (paymentMethodsStore.error) {
      setCardIsInvalid(true)
      setShowCardError(true)
      setCardInvalidMessage(paymentMethodsStore.errorMessage)
    }
  }, [paymentMethodsStore])

  useEffect(() => {
    const plan = get(store, 'user.billingPeriod.planType', '')
    updatePlanType(plan)
    setBillingInformation(get(store, 'user.billingInformations', false))
    setCardInformation(get(store, 'user.card', false))
  }, [store])

  function onReadyCardComponent(el: any): void {
    setCardIsInvalid(el._empty)
    el.focus()
    setCard(el)
  }

  function onFocusOnStripe(): void {
    setIsInFocusOnStripe(true)
  }

  function onBlurOnStripe(): void {
    setIsInFocusOnStripe(false)
  }

  const onUpdateCreditCard = (): void => {
    !cardIsInvalid &&
      onUpdatePaymentMethods(
        { user: store.user },
        handleStripePayments,
        onShowChangeCard
      )
  }

  function handleStripePayments({
    name,
    clientSecret,
  }: UserSignupStripeRequestType) {
    return stripe
      .handleCardSetup(clientSecret, card, {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          throw {
            response: { data: { error: { message: result.error.message } } },
          }
        } else {
          return result.setupIntent.payment_method
        }
      })
  }
  const shouldRenderCardInformation = () => {
    if (!cardInformation) {
      return (
        <div className="dashbaord-wrapper__item">
          <FlatCard
            isLoading={store.pending || store.fetchNotStarted}
            type="empty"
            firstButton={{
              label: t('pages.DashboardBillingView.payments.buttonLabelEmpty'),
              type: 'primary',
              callback: onShowChangeCard,
            }}
          >
            <p className="is-lead u-margin-bottom-spacer-small">
              {t('pages.DashboardBillingView.payments.paymentsTitleEmpty')}
            </p>
            <p>
              {t(
                'pages.DashboardBillingView.payments.paymentsDescriptionEmpty'
              )}
            </p>
          </FlatCard>
        </div>
      )
    } else {
      return (
        <div className="dashbaord-wrapper__item">
          <FlatCard
            isLoading={store.pending || store.fetchNotStarted}
            firstButton={{
              label: t('pages.DashboardBillingView.payments.buttonLabelChange'),
              type: 'secondary',
              callback: onShowChangeCard,
            }}
          >
            {store.pending || store.fetchNotStarted ? (
              <SkeletonLoader fullHeight>
                <h5>{t('pages.DashboardBillingView.payments.title')}</h5>
              </SkeletonLoader>
            ) : (
              <h5>{store.user.name}</h5>
            )}
            {store.pending || store.fetchNotStarted ? (
              <SkeletonLoader>
                <p>**** **** **** ****</p>
              </SkeletonLoader>
            ) : (
              <p>**** **** **** {get(store, 'user.card.lastDigits', '')}</p>
            )}
            {store.pending || store.fetchNotStarted ? (
              <SkeletonLoader>
                <p>{t('pages.DashboardBillingView.payments.title')}</p>
              </SkeletonLoader>
            ) : (
              <p>
                {get(store, 'user.card.brand', '')} •{' '}
                {t('pages.DashboardBillingView.payments.validThru')}{' '}
                {get(store, 'user.card.expMonth', '')}/
                {get(store, 'user.card.expYear', '')}
              </p>
            )}
          </FlatCard>
        </div>
      )
    }
  }
  const shouldRenderBillingInformation = () => {
    if (!billingInformations) {
      return (
        <FlatCard
          isLoading={store.pending || store.fetchNotStarted}
          type="empty"
          firstButton={{
            label: t('pages.DashboardBillingView.billingInfo.buttonLabelEmpty'),
            type: 'primary',
            callback: onShowHideUpdateUserForm,
          }}
        >
          <p className="is-lead u-margin-bottom-spacer-small">
            {t('pages.DashboardBillingView.billingInfo.billingInfoTitleEmpty')}
          </p>
          <p>
            {t(
              'pages.DashboardBillingView.billingInfo.billingInfoDescriptionEmpty'
            )}
          </p>
        </FlatCard>
      )
    } else {
      return (
        <FlatCard
          isLoading={store.pending || store.fetchNotStarted}
          firstButton={{
            label: t('pages.DashboardBillingView.billingInfo.buttonLabel'),
            type: 'secondary',
            callback: onShowHideUpdateUserForm,
          }}
        >
          {store.pending || store.fetchNotStarted ? (
            <SkeletonLoader fullHeight>
              <p>{t('pages.DashboardBillingView.title')}</p>
            </SkeletonLoader>
          ) : (
            <h5 className="is-lead u-margin-bottom-spacer-small">
              {get(billingInformations, 'businessName', '')}
            </h5>
          )}
          {store.pending || store.fetchNotStarted ? (
            <>
              <SkeletonLoader>
                <p>{t('pages.DashboardBillingView.title')}</p>
              </SkeletonLoader>
              <SkeletonLoader>
                <p>{t('pages.DashboardBillingView.title')}</p>
              </SkeletonLoader>
              <SkeletonLoader>
                <p>{t('pages.DashboardBillingView.title')}</p>
              </SkeletonLoader>
            </>
          ) : (
            <>
              <>
                <p>
                  <strong>
                    {get(billingInformations, 'isBusiness')
                      ? t('pages.DashboardBillingView.billingInfo.companyLabel')
                      : t(
                          'pages.DashboardBillingView.billingInfo.privateLabel'
                        )}
                  </strong>
                </p>
                <p>
                  {get(billingInformations, 'businessAddress')
                    ? t('pages.DashboardBillingView.billingInfo.addressLabel') +
                      ' ' +
                      get(billingInformations, 'businessAddress')
                    : ''}
                </p>
                <p>
                  {}{' '}
                  {get(billingInformations, 'businessCity')
                    ? t('pages.DashboardBillingView.billingInfo.cityLabel') +
                      ' ' +
                      get(billingInformations, 'businessCity')
                    : ''}
                  {}
                  {get(billingInformations, 'businessZipCode', '')
                    ? ', ' + get(billingInformations, 'businessZipCode', '')
                    : ''}
                </p>

                <p>
                  {t('pages.DashboardBillingView.billingInfo.countryLabel')}{' '}
                  {get(billingInformations, 'country')
                    ? get(billingInformations, 'country')
                    : ''}
                </p>
              </>

              {billingInformations.taxCode && (
                <p>
                  {t(
                    'pages.DashboardBillingView.billingInfo.billingTaxCodeLabel'
                  )}{' '}
                  {get(billingInformations, 'taxCode', '')}
                </p>
              )}
              {(billingInformations.isBusiness === true ||
                billingInformations.isBusiness === 1) &&
                billingInformations.vatId && (
                  <p>
                    {t(
                      'pages.DashboardBillingView.billingInfo.billingVatLabel'
                    )}{' '}
                    {get(billingInformations, 'vatId', '')}
                  </p>
                )}
              {(billingInformations.isBusiness === true ||
                billingInformations.isBusiness === 1) &&
                billingInformations.sdiCode && (
                  <p>
                    {t('pages.DashboardBillingView.billingInfo.sdiCodeLabel')}{' '}
                    {get(billingInformations, 'sdiCode', '')}
                  </p>
                )}
              {(billingInformations.isBusiness === true ||
                billingInformations.isBusiness === 1) &&
                billingInformations.pecEmail && (
                  <p>
                    {t('pages.DashboardBillingView.billingInfo.pecLabel')}{' '}
                    {get(billingInformations, 'pecEmail', '')}
                  </p>
                )}
            </>
          )}
        </FlatCard>
      )
    }
  }

  return (
    <div className="dashbaord-wrapper">
      <div className="dashbaord-wrapper__wrap">
        <div className="dashbaord-wrapper__item">
          <h3>{t('pages.DashboardBillingView.title')}</h3>
        </div>
        <div className="dashbaord-wrapper__item">
          <h5>{t('pages.DashboardBillingView.yourPlan.title')}</h5>
          <FlatCard
            isLoading={store.pending || store.fetchNotStarted}
            firstButton={{
              label: t('pages.DashboardBillingView.yourPlan.buttonLabel'),
              type: 'primary',
              callback: () => {
                window.location.href = 'mailto:support@modernmt.com'
              },
            }}
          >
            {store.pending || store.fetchNotStarted ? (
              <SkeletonLoader fullHeight>
                <h5>{t('pages.DashboardBillingView.title')}</h5>
              </SkeletonLoader>
            ) : (
              <h5>{store.user.billingPeriod.planDescription}</h5>
            )}
            {store.pending || store.fetchNotStarted ? (
              <SkeletonLoader>
                <p>{t('pages.DashboardBillingView.title')}</p>
              </SkeletonLoader>
            ) : (
              planType !== 'default' && (
                <p>
                  {new Intl.NumberFormat('en',{style:'currency',currency:store.user.billingPeriod.currency})
                    .format(store.user.billingPeriod.planPrice)}{' '}
                  <span>
                    {t(`components.SignupSidebar.totalValueLabel.${planType}`)}
                  </span>
                </p>
              )
            )}
          </FlatCard>
        </div>
        <div className="dashbaord-wrapper__item">
          <h5>{t('pages.DashboardBillingView.payments.title')}</h5>
          {shouldShowChangeCard ? (
            <FlatCard
              type="full"
              firstButton={{
                label: t(
                  'pages.DashboardBillingView.payments.buttonLabelUpdate'
                ),
                type: 'primary',
                isLoading: paymentMethodsStore.pending || store.fetchNotStarted,
                callback: onUpdateCreditCard,
              }}
              secondButton={{
                label: t(
                  'pages.DashboardBillingView.payments.buttonLabelCancel'
                ),
                type: 'tertiary',
                callback: onShowChangeCard,
              }}
            >
              <div className={'input-container input-container--stripe'}>
                <div className="input-container__item ">
                  <label>{t('components.SignupForm.creditCard')}</label>
                  <div className="u-is-desktop">
                    <span>
                      <i className="icon icon-lock" />
                      {t('components.SignupForm.stripeLabel')}
                    </span>
                  </div>
                  <div
                    className={`input-wrapper ${
                      isInFocusOnStripe ? 'is-focus' : ''
                    }`}
                  >
                    <CardElement
                      onFocus={onFocusOnStripe}
                      onBlur={onBlurOnStripe}
                      onReady={onReadyCardComponent}
                      onChange={onChangeCardComponent}
                      style={{
                        base: cardElementStyle,
                      }}
                    />
                  </div>
                  <div className="u-is-mobile u-margin-top-spacer-small">
                    <span>
                      <i className="icon icon-lock" />
                      {t('components.SignupForm.stripeLabel')}
                    </span>
                  </div>
                  {cardIsInvalid && showCardError && (
                    <small className="u-width-100">{cardInvalidMessage}</small>
                  )}
                </div>
              </div>
            </FlatCard>
          ) : (
            shouldRenderCardInformation()
          )}
        </div>

        <div className="dashbaord-wrapper__item">
          <h5>{t('pages.DashboardBillingView.billingInfo.title')}</h5>
          {shouldShowUpdateUserForm ? (
            <FlatCard
              isLoading={store.pending || store.fetchNotStarted}
              type="full"
            >
              <BillingInfoForm
                onCancel={onShowHideUpdateUserForm}
                onSubmit={onSubmitForm}
                billingInformations={billingInformations}
              />
            </FlatCard>
          ) : (
            shouldRenderBillingInformation()
          )}
        </div>

        <div className="dashbaord-wrapper__item">
          <h5>{t('pages.DashboardBillingView.invoices.title')}</h5>
          <InvoiceTable />
        </div>
      </div>
    </div>
  )
}

export default DashboardBillingView
