// @flow
export const TRANSLATOR_MEMORIES_FETCH = 'TRANSLATOR_MEMORIES_FETCH'
export const TRANSLATOR_MEMORIES_SUCCESS = 'TRANSLATOR_MEMORIES_SUCCESS'
export const TRANSLATOR_MEMORIES_FAIL = 'TRANSLATOR_MEMORIES_FAIL'

export const TRANSLATOR_MEMORIES_UPLOAD_START = 'TRANSLATOR_MEMORIES_UPLOAD_START'
export const TRANSLATOR_MEMORIES_UPLOAD_UPDATING = 'TRANSLATOR_MEMORIES_UPLOAD_UPDATING'
export const TRANSLATOR_MEMORIES_UPLOAD_END = 'TRANSLATOR_MEMORIES_UPLOAD_END'
export const TRANSLATOR_MEMORIES_UPLOAD_FAIL = 'TRANSLATOR_MEMORIES_UPLOAD_FAIL'

export const TRANSLATOR_MEMORIES_PROCESS_UPDATING = 'TRANSLATOR_MEMORIES_PROCESS_UPDATING'

export const TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES = 'TRANSLATOR_MEMORIES_UPDATE_ACTIVE_MEMORIES'

export const TRANSLATOR_MEMORIES_UPLOAD_CANCEL = 'TRANSLATOR_MEMORIES_UPLOAD_CANCEL'
