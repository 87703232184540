// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@Services/util'
import MessageBox from '@Components/MessageBox'
import { useTranslation } from 'react-i18next'
import type { Element } from 'react'

type Props = {
  onSubmit: Function,
  onCancel: Function,
  licensePausing: Boolean,
  onLicenseUnpause: Function,
  licensePaused: Boolean,
}
const ModalPauseLicense = ({
  onSubmit,
  onCancel,
  onLicenseUnpause,
  licensePaused,
}: Props): Element<'div'> => {
  const { t } = useTranslation()

  return (
    <div className='modal-wrap'>
      <div className='modal-wrap__wrap'>
        {!licensePaused ? (
          <>
            <div className='modal-wrap__item modal-wrap__item--header'>
              <h5>{t(`components.ModalPauseLicense.title`)}</h5>
              <i onClick={onCancel} className='icon icon-close' />
            </div>

            <div className='modal-wrap__item modal-wrap__item--body'>
              <p>{t('components.ModalPauseLicense.subTitle')}</p>
              <MessageBox>
                {t(`components.ModalPauseLicense.messageBox`)}
              </MessageBox>
            </div>
          </>
        ) : (
          <>
            <div className='modal-wrap__item modal-wrap__item--header'>
              <h5>{t(`components.ModalUnpauseLicense.title`)}</h5>
              <i onClick={onCancel} className='icon icon-close' />
            </div>
            <div className='modal-wrap__item modal-wrap__item--body'>
              <p>{t('components.ModalUnpauseLicense.subTitle')}</p>
            </div>
          </>
        )}

        <div className='modal-wrap__item modal-wrap__item--footer'>
          <a
            onClick={licensePaused ? onLicenseUnpause : onSubmit}
            className='button button--primary is-purple'
          >
            {!licensePaused
              ? t('components.ModalPauseLicense.buttonPrimaryLabel')
              : t('components.ModalUnpauseLicense.buttonPrimaryLabel')}
          </a>
          <a onClick={onCancel} className='button button--tertiary is-purple'>
            {t('components.ModalPauseLicense.buttonSecondaryLabel')}
          </a>
        </div>
      </div>
    </div>
  )
}

ModalPauseLicense.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
}

ModalPauseLicense.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ModalPauseLicense
