//@flow
import { UPDATE_PLAN_TYPE, UPDATE_SOLUTION_TYPE } from '@Actions/PlanType'

export const planTypeReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_PLAN_TYPE:
      return { ...state, planType: action.planType }
    case UPDATE_SOLUTION_TYPE:
      return { ...state, solutionType: action.solutionType }
    default:
      return state
  }
}
