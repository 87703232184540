import React from 'react';

const LogoWhite = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 2208.7 453.7">
      <g>
        <g>
          <path
            fill="white"
            d="M590.8,290.5v30.6c5.5-7.2,12.5-10.2,20.5-10.2c17.1,0,27.6,13.7,27.6,31.5c0,17.9-10.6,31.6-27.6,31.6
     c-7.9,0-15-3-20.5-10.2v8.9h-12.7v-82.1L590.8,290.5L590.8,290.5z M608.4,362.6c11.5,0,17-9,17-20.1c0-11-5.5-20.2-17-20.2
     s-17.6,9.2-17.6,20.2C590.8,353.7,596.9,362.6,608.4,362.6z"
          />
          <path
            fill="white"
            d="M680.6,375c-4.7,12.4-10,18.7-21.5,18.7c-5.3,0-9.8-1.4-13.7-4.1l5.2-9c2.1,1.5,4.5,2.4,7.1,2.4
     c4.9,0,7.4-3.5,9.5-7.9l1.6-3.3l-25.2-59.6h14.6l17.3,43.6l15.4-43.6h13.7L680.6,375z"
          />
          <path
            fill="white"
            d="M782.5,369.5c-3.8,2.9-8.5,4.5-14.4,4.5c-7.4,0-13-2.4-16.3-7.7c-2.1-3.1-2.9-6.8-2.9-12.7V322h-10.1v-9.8
     h10.1V296h12.8v16.2h18.6v9.8h-18.6v31.7c0,6.1,2.9,9,8.2,9c3.3,0,5.9-1.3,7.6-2.6L782.5,369.5z"
          />
          <path
            fill="white"
            d="M826.5,325.5c-2-1-4.1-1.8-6.9-1.8c-7.7,0-14.5,6.7-14.5,18.2v30.7h-12.7v-60.4h12.7v9.8
     c4-6.3,10-10.9,17-10.9c3.3,0,6.3,0.9,8.1,2.1L826.5,325.5z"
          />
          <path
            fill="white"
            d="M887.7,373.5c-5.6,0-10.8-2.6-12.1-9c-4.9,7.1-13,9.4-20.1,9.4c-12.4,0-20.7-6.8-20.7-18.6
     c0-15,13.2-17.3,23.6-18.4c8.7-0.9,15.3-1.7,15.3-7.6c0-6-6.7-7.7-12.3-7.7c-6.2,0-13.2,2.1-18.9,6.1l-5.1-9.5
     c7.5-4.8,15.4-7.4,24.8-7.4c14.3,0,24.3,5.8,24.3,20.2v27.5c0,3.9,1.3,5.5,4.3,5.5c1.1,0,2.5-0.2,3.3-0.8l1.4,8.7
     C893.5,373,890,373.5,887.7,373.5z M873.8,340.5c-3.5,4.4-8.6,4.4-15.1,5.4s-11.2,2.9-11.2,9.2s4.8,9,11,9
     c7.9,0,15.2-4.3,15.2-14.4L873.8,340.5L873.8,340.5z"
          />
          <path
            fill="white"
            d="M905.3,372.6v-60.4H918v8.9c4.9-6.6,11.6-10.2,19.8-10.2c13.2,0,20.8,8.9,20.8,22.4v39.3h-12.7v-36.5
     c0-6.4-2.1-13.7-12-13.7c-9,0-16,6.3-16,16.3v33.8h-12.6V372.6z"
          />
          <path
            fill="white"
            d="M997.4,374c-12.3,0-22.3-4.7-28.1-13.6l9-6.3c4.6,6.4,12,9.5,19.8,9.5c7.5,0,12.5-2.8,12.5-8.1
     c0-5.5-5.5-6.7-15.3-8.3c-12.7-2.2-22.8-5.8-22.8-18.2c0-12.5,11.2-18.3,23.7-18.3c12.2,0,21,5.3,26,10.6l-8.1,7.4
     c-4-4.6-10.5-7.6-18.1-7.6c-5.8,0-11.3,2.1-11.3,6.8c0,5.3,6.4,6.4,14.4,7.8c12.9,2.3,23.9,5.6,23.9,18.4
     C1023.2,366,1013.5,374,997.4,374z"
          />
          <path fill="white" d="M1036.2,372.6v-82.1h12.7v82.1H1036.2z" />
          <path
            fill="white"
            d="M1114.3,373.5c-5.6,0-10.8-2.6-12.1-9c-4.9,7.1-13,9.4-20.1,9.4c-12.4,0-20.7-6.8-20.7-18.6
     c0-15,13.2-17.3,23.6-18.4c8.7-0.9,15.3-1.7,15.3-7.6c0-6-6.7-7.7-12.3-7.7c-6.2,0-13.2,2.1-18.9,6.1l-5.1-9.5
     c7.5-4.8,15.4-7.4,24.8-7.4c14.3,0,24.3,5.8,24.3,20.2v27.5c0,3.9,1.3,5.5,4.3,5.5c1.1,0,2.5-0.2,3.3-0.8l1.4,8.7
     C1120,373,1116.5,373.5,1114.3,373.5z M1100.3,340.5c-3.5,4.4-8.6,4.4-15.1,5.4s-11.2,2.9-11.2,9.2s4.8,9,11,9
     c7.9,0,15.2-4.3,15.2-14.4L1100.3,340.5L1100.3,340.5z"
          />
          <path
            fill="white"
            d="M1168.5,369.5c-3.8,2.9-8.5,4.5-14.4,4.5c-7.4,0-13-2.4-16.3-7.7c-2.1-3.1-2.9-6.8-2.9-12.7V322h-10.1v-9.8
     h10.1V296h12.8v16.2h18.6v9.8h-18.6v31.7c0,6.1,2.9,9,8.2,9c3.3,0,5.9-1.3,7.6-2.6L1168.5,369.5z"
          />
          <path
            fill="white"
            d="M1234.3,345.5h-46c0.6,11,7.8,17.1,18.5,17.1c7.1,0,13-2.8,17.5-7.6l8.3,7.9c-6.3,6.7-14.7,10.9-26.2,10.9
     c-20.4,0-31.6-13.5-31.6-31.3c0-17.9,11.4-31.9,30.4-31.9c18.3,0,29.4,13,29.4,30.4C1234.5,343,1234.4,344.5,1234.3,345.5z
      M1221.8,336.3c-0.9-10.1-7.9-15.3-16.6-15.3c-9.5,0-16,6.3-16.7,15.3H1221.8z"
          />
          <path
            fill="white"
            d="M1291.5,372.6v-8.9c-5.4,7.2-12.5,10.2-20.4,10.2c-17.1,0-27.7-13.7-27.7-31.5c0-17.9,10.6-31.6,27.7-31.6
     c7.9,0,15,3,20.4,10.2v-30.6h12.8v82.1L1291.5,372.6L1291.5,372.6z M1273.9,322.2c-11.5,0-17,9.1-17,20.2c0,11.2,5.5,20.1,17,20.1
     c11.6,0,17.6-9,17.6-20.1C1291.5,331.2,1285.4,322.2,1273.9,322.2z"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                fill="white"
                d="M240.6,184.5v147.8h-37.8V184.5H132v-32.9h108.6V184.5L240.6,184.5z"
              />
            </g>
            <circle fill="white" cx="298" cy="172.3" r="21.3" />
            <g>
              <path
                fill="white"
                d="M226.8,32c107.6,0,194.8,87.2,194.8,194.8s-87.2,194.8-194.8,194.8S32,334.5,32,226.9l0,0
         C32.1,119.3,119.3,32.1,226.8,32 M226.8,0C101.6,0,0,101.6,0,226.9s101.6,226.8,226.8,226.8s226.9-101.6,226.9-226.9
         S352.1,0,226.8,0z"
              />
            </g>
          </g>
          <g>
            <path
              fill="white"
              d="M576.5,241.8V72.3H612v24.1c13.7-17.9,31-28,52.8-28c24.8,0,42.1,11.7,49.2,32.3c15-20.9,35.5-32.3,59-32.3
       c37.2,0,56.7,24.4,56.7,61.9v111.5h-35.5v-103c0-18.6-5.2-37.8-31.9-37.8c-23.5,0-41.7,17.3-41.7,45.3v95.5h-35.5v-103
       c0-18.6-5.2-37.8-31.9-37.8c-23.8,0-41.7,17.3-41.7,45.3v95.5L576.5,241.8L576.5,241.8z"
            />
            <path
              fill="white"
              d="M943.9,245.4c-52.5,0-85.7-36.8-85.7-88.3c0-51.8,33.2-88.7,85.7-88.7s85.7,36.8,85.7,88.7
       C1030,208.9,996.7,245.4,943.9,245.4z M943.9,99.4c-30.6,0-48.2,24.8-48.2,57.7c0,32.6,17.3,57.4,48.2,57.4
       c30.6,0,47.9-24.8,47.9-57.4C991.8,124.2,974.6,99.4,943.9,99.4z"
            />
            <path
              fill="white"
              d="M1187.4,241.8V217c-15.3,20.2-35.2,28.7-57,28.7c-48.2,0-77.9-38.5-77.9-88.3c0-50.2,29.7-88.7,77.9-88.7
       c22.2,0,42.1,8.5,57,28.7V11.7h35.9v230.5h-35.9V241.8z M1137.9,100.4c-32.3,0-47.9,25.4-47.9,56.7s15.6,56.4,47.9,56.4
       c32.6,0,49.2-25.1,49.2-56.4C1187.4,125.5,1170.1,100.4,1137.9,100.4z"
            />
            <path
              fill="white"
              d="M1420.5,165.9h-129.1c1.6,31,21.8,48.2,51.8,48.2c19.9,0,36.5-7.8,48.9-21.2l23.1,22.2
       c-17.6,18.6-41.4,30.6-73.7,30.6c-57,0-88.7-37.8-88.7-87.7c0-50.2,31.9-89.3,85.1-89.3c51.2,0,82.5,36.5,82.5,85.1
       C1421.1,158.7,1420.8,163,1420.5,165.9z M1385.3,140.1c-2.6-28.4-22.2-43-46.6-43c-26.7,0-45,17.6-46.9,43H1385.3z"
            />
            <path
              fill="white"
              d="M1545.7,109.8c-5.5-2.9-11.7-5.2-19.2-5.2c-21.5,0-40.7,18.6-40.7,50.9v86.1h-35.5V72h35.5v27.4
       c11.4-17.6,28-30.6,47.9-30.6c9.5,0,17.6,2.6,22.5,5.9L1545.7,109.8z"
            />
            <path
              fill="white"
              d="M1581.2,241.8V72.3h35.5v24.8c14-18.3,32.6-28.7,55.4-28.7c37.2,0,58.3,24.8,58.3,62.9v110.5h-35.5V139.5
       c0-17.9-5.9-38.5-33.6-38.5c-25.1,0-45,17.6-45,46v94.9L1581.2,241.8L1581.2,241.8z"
            />
            <path
              fill="white"
              d="M1963.6,241.8V66.5l-63.9,175.4h-31.6l-63.6-175v175h-36.8V27h55.4l61.3,167.9L1945.6,27h54.4v214.8H1963.6z
       "
            />
            <path
              fill="white"
              d="M2138,59.6v181.9h-37.8V59.6h-70.7V26.7h179.3v32.9C2208.8,59.6,2138,59.6,2138,59.6z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoWhite;
